import { API_URL, axiosInstancePrivate } from "shared/definitions/api";
import { TCompanyDocType, TCompanyDocument } from "store/user/types";

export async function organizationCreate(fullName: string) {
  return axiosInstancePrivate.post(`${API_URL}/api/v1/account/organization`, {
    full_name: fullName,
  });
}

export async function organizationConfirm() {
  return axiosInstancePrivate.post(`${API_URL}/api/v1/account/organization/documents/confirm`);
}

export async function uploadOrganizationDocs(docType: TCompanyDocType, files: File[]) {
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append("file", files[i]);
  }
  return axiosInstancePrivate.post(`${API_URL}/api/v1/account/organization/documents/${docType}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function deleteOrganizationDoc(docType: TCompanyDocType, path: string) {
  return axiosInstancePrivate.delete(`${API_URL}/api/v1/account/organization/documents/${docType}`, {
    data: { file_path: path },
  });
}

export async function fetchCompanyDocs() {
  return axiosInstancePrivate.get<{ items: TCompanyDocument[] }>(`${API_URL}/api/v1/account/organization/documents`);
}