import { observer } from "mobx-react";
import CommonSettings from "./sections/common";
// import NotificationsSettings from "./sections/notifications";
import SecuritySettings from "./sections/security";
import styles from "./Settings.module.scss";
import { Spacer } from "../../components/ui/universal/Spacer/Spacer";

export const Settings = observer(() => {
  return (
    <div className={styles.root}>
      <CommonSettings />
      {/* <Spacer size={6} />
      <NotificationsSettings /> */}
      <Spacer size={6} />
      <SecuritySettings />
    </div>
  );
});
