import { Center, Title, Text, Divider } from "@mantine/core";
import classNames from "classnames";
import { Exchange } from "store/exchange";
import styles from "./ProcessScreen.module.scss";
import { ReactComponent as CloseIcon } from "../../../../shared/static/icons/adapted/close.svg";

import { CurrencyIcon } from "../../../../components/common/CurrencyIcon";
import { ProcessingCentralSection } from "./ProcessingCentralSection";
import { ProcessBottomSection } from "./ProcessBottomSection";

type TProps = {
  store: Exchange;
};

function getTitle(store: Exchange) {
  switch (store.step) {
    case "Processing":
      return "Обрабатываем операцию";
    case "Error":
      return "Операция не выполнена";
    case "Liveness":
      return "Подтвердите операцию";
    case "Rejected":
      return "Операция отклонена";
    case "Finished":
      return "Операция выполнена";
    default:
      return "Обрабатываем операцию";
  }
}

function getTextContent(store: Exchange) {
  switch (store.step) {
    case "Processing":
      return (
        <>
          Пока мы выполняем операцию вы можете продолжать пользоваться платформой.
          <br />
          Отслеживайте статус операции в разделе История.
        </>
      );
    case "Error":
      return "Что-то пошло не так. Попробуйте еще раз или напишите нам в тех. поддержку.";
    case "Rejected":
      return "Попробуйте еще раз или напишите нам в тех. поддержку";
    case "Liveness":
      return "Пройдите проверку личности для подтверждения операции";
    case "Finished":
      return "";
    default:
      return "Обрабатываем операцию";
  }
}

export const ProcessScreen = ({ store }: TProps) => {
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <Center>
          <div>
            <Title className={styles.title} align="center" order={2}>
              {getTitle(store)}
            </Title>
            <Text className={styles.textContent} align="center">
              {getTextContent(store)}
            </Text>
            <div className={styles.chainContainer}>
              {store.step !== "Finished" && (
                <>
                  <div
                    className={classNames(styles.currencyIcon, {
                      [styles.currencyIconDefault]: true,
                      [styles.currencyIconError]: store.step === "Error",
                    })}>
                    <CurrencyIcon className={styles.coinIcon} icon={store.fromInfo.currency} />
                  </div>
                  <Divider
                    className={styles.chainDivider}
                    label={
                      store.step === "Error" ? (
                        <div className={styles.dividerIcon}>
                          <CloseIcon />
                        </div>
                      ) : null
                    }
                    labelPosition="center"
                  />
                  <div
                    className={classNames(styles.currencyIcon, {
                      [styles.currencyIconDefault]: true,
                      [styles.currencyIconError]: store.step === "Error",
                    })}>
                    <CurrencyIcon className={styles.coinIcon} icon={store.toInfo.currency} />
                  </div>
                </>
              )}
            </div>
            <div>
              <Center mt={30} mb={12}>
                <ProcessingCentralSection store={store} />
              </Center>
            </div>
          </div>
        </Center>
      </div>
      <div>
        <ProcessBottomSection store={store} />
      </div>
    </div>
  );
};
