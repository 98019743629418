import { Alert, Button, TextInput, Title } from "@mantine/core";
import { observer } from "mobx-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { confirmTransaction } from "shared/utils/api/confirmTransaction";
import { useStores } from "store/root/provider";
import { useTimer } from "react-timer-hook";
import { WarningModal } from "../WarningModal";
import styles from "./CodeConfirmation.module.scss";

type TProps = {
  transactionId: string;
  onClose: () => void;
  onConfirmed: () => void;
};

const RESEND_TIMEOUT = 300; // sec
const ONE_SECOND_IN_MILLISECONDS = 1000;

function hideEmail(email: string) {
  try {
    const splitted = email.split("@");
    const middle = Math.floor(splitted[0].length / 2);
    const hiddenFirstPart = splitted[0].slice(0, middle) + splitted[0].slice(middle).replace(/./g, "*");
    return `${hiddenFirstPart}@${splitted[1]}`;
  } catch (e) {
    return email;
  }
}

export const CodeConfirmation = observer((props: TProps) => {
  const { user } = useStores();
  const [onExpireTimer, setOnExpireTimer] = useState(false);
  const { seconds, minutes, isRunning, start } = useTimer({
    expiryTimestamp: new Date(+new Date() + RESEND_TIMEOUT * ONE_SECOND_IN_MILLISECONDS),
    onExpire: () => setOnExpireTimer(true),
  });

  const [code, setCode] = useState("");
  const [incorrectCode, setIncorrectCode] = useState(false);

  const confirm = useCallback(() => {
    setIncorrectCode(false);
    confirmTransaction(props.transactionId, code).then((res) => {
      if (res.status === 200) {
        props.onConfirmed();
      } else {
        setIncorrectCode(true);
      }
    });
  }, [code, props]);

  const hiddenEmail = useMemo(() => {
    return hideEmail(user.userDetails?.email || "");
  }, [user.userDetails?.email]);

  useEffect(() => {
    if (!isRunning) start();
  }, [isRunning, start]);

  if (onExpireTimer) {
    return (
      <WarningModal
        mode="warn"
        modalClassName={styles.modalRoot}
        open
        onClose={props.onClose}
        header={
          <>
            <Title order={4}>Ошибка подтверждения</Title>
            <p>Вы не успели подтвердить операцию.</p>
          </>
        }
        footerActions={
          <Button onClick={props.onClose} variant="outline" color="gray.9">
            Отмена
          </Button>
        }>
        <Alert color="red">
          <div>
            <p>Код, отправленный вам на эл. почту, не был подтверждён</p>
            <p>Попробуйте ещё раз.</p>
          </div>
        </Alert>
      </WarningModal>
    );
  }

  return (
    <WarningModal
      mode="warn"
      modalClassName={styles.modalRoot}
      open
      onClose={props.onClose}
      header={
        <>
          <Title order={4}>Подтверждение операции</Title>
          <p>Введите код, отправленный вам на эл. почту.</p>
        </>
      }
      footerActions={
        <>
          <Button onClick={props.onClose} variant="outline" color="gray.9">
            Отмена
          </Button>
          <Button disabled={!code} onClick={confirm}>
            Подтвердить
          </Button>
        </>
      }>
      <section className={styles.inputContainer}>
        <TextInput
          autoComplete="off"
          classNames={{ label: styles.label, rightSection: styles.rightSection }}
          label="Код подтверждения"
          type="number"
          value={code}
          rightSection={
            isRunning ? (
              <div>
                <span className={styles.noColor}>Истекает через</span> 0{minutes}:
                {seconds.toString() === "0" ? "00" : seconds.toString().length === 1 ? `0${seconds}` : seconds}
              </div>
            ) : undefined
          }
          error={incorrectCode ? "Неверный код подтверждения" : undefined}
          onChange={(e) => {
            setCode(e.target.value);
          }}
        />
        <div className={styles.comment}>
          Введите код, который пришел на эл.почту <strong className={styles.commentEmail}>{hiddenEmail}</strong>
        </div>
      </section>
    </WarningModal>
  );
});
