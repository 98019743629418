export type DefaultType = {
  defaultType: any;
};

export type TCurrency = {
  id: number;
  name: string;
  network?: string;
  ticker: string;
};

export type TTransactionStatus = "new" | "hold" | "pending" | "user_approving" | "sending" | "completed" | "rejected";

export type TTransaction = {
  addressFrom: string;
  addressTo: string;
  amountFrom: number;
  amountTo: number;
  currencyFrom: string;
  currencyTo: string;
  amount?: number;
  transactionHash?: string;
  createdAt: string;
  fee: number;
  id: string;
  network: string;
  status: TTransactionStatus;
  tickerFee: string;
  tickerFrom: string;
  tickerTo: string;
  type: string;
  userId: string;
};

export interface ILogOutResponse {
  logoutUrl: string;
}

export interface ILogInResponse {
  loginPageUrl: string;
}

export interface IResetPasswordResponse {
  resetPasswordUrl: string;
}

export type TTransferBankDetails = {
  bankName: string;
  bankAddress: string;
  bic: string;
  destination: string;
  destinationAddress: string;
  unp: string;
  accountNumber: string;
};

export type TTransferDetails = {
  sum: string;
  currency: string;
  purpose: string;
};

export type TDepositRequisites = TTransferBankDetails & TTransferDetails;

export type TAddress = {
  addressType: "livingaddress" | "registrationaddress";
  buildingName: string;
  buildingNumber: string;
  country: string;
  endDate: string;
  flatNumber: string;
  postCode: string;
  startDate: string;
  state: string;
  stateEn: string;
  street: string;
  streetEn: string;
  subStreet: string;
  subStreetEn: string;
  town: string;
  townEn: string;
};

export type TBankRequisitesResponse = {
  accountNumber: string;
  bankName: string;
  bicOrSwift: string;
  payerTaxId: string;
  recipient: string;
  recipientAddresses: TAddress;
  bankAddress: TAddress;
};

/**
 * 0 - transfer(перевод), 1 - cash_register(касса), 2 - for crypto
 * Numbers are important for backend
 */
export enum TFlowType {
  transfer = 0,
  cash_register = 1,
  crypto = 2,
}

export type TUserType = "individual" | "company";

export type TResponsibleUser = {
  id: string;
  fullName: string;
  isFinancialOperationsAllowed: boolean;
  position: string;
  publicId: number;
}

export enum TTickerOperationType {
  exchange = 0,
  deposit = 1,
  withdraw = 2,
}
export type TTickerOperation = {
  flowType?: TFlowType;
  tickerTo?: string;
  tickerFrom?: string;
  network?: string;
  allowed: boolean;
  id: number;
  transactionType: TTickerOperationType;
}
