import { Burger as BurgerMantine } from "@mantine/core";
import styles from "./Burger.module.scss";

type TProps = {
  className?: string;
  onClick?: () => void;
  opened: boolean;
};

export function Burger(props: TProps) {
  return (
    <BurgerMantine
      onClick={props.onClick}
      classNames={{ burger: styles.burger }}
      className={props.className}
      opened={props.opened}
    />
  );
}
