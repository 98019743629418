import { useMemo } from "react";
import { Button, Space, Title } from "@mantine/core";
import { Route } from "shared/definitions";
import classNames from "classnames";
import styles from "./VerificationStateCard.module.scss";
import router from "../../../router";
import { Card } from "./Card";

type TProps = {
  verificationState: "novice" | "pro";
};

export const VerificationStateCard = (props: TProps) => {
  const levelContent = useMemo(() => {
    switch (props.verificationState) {
      case "novice":
        return {
          labelClass: styles.titleRed,
          buttonColor: "red",
          label: "Базовый",
          text: "Чтобы получить полный доступ к платформе, пожалуйста, пройдите верификацию",
          showButton: true,
        };
      case "pro":
      default:
        return {
          labelClass: styles.titleGreen,
          label: "Уровень 2",
          text: "У вас максимальный уровень верификации.",
          showButton: false,
        };
    }
  }, [props.verificationState]);

  return (
    <Card
      title="Верификация"
      afterTitleContent={
        <Title order={3} className={classNames(styles.verificationLevel, levelContent?.labelClass)}>
          {levelContent?.label}
        </Title>
      }>
      <div className={styles.cardContent}>
        <div>{levelContent?.text}</div>

        {levelContent?.showButton && (
          <>
            <Space h={4} />
            <Button color={levelContent?.buttonColor} radius={8} onClick={() => router.navigate(Route.VERIFICATION)}>
              Начать
            </Button>
          </>
        )}
      </div>
    </Card>
  );
};
