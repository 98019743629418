import { createStyles } from "@mantine/core";

export const useStyles = createStyles((theme, _params, getRef) => {
  const icon = getRef("icon");
  return {
    navbar: {
      backgroundColor: theme.colors.gray[9],
      height: "100vh",
      borderRight: "0",
    },

    header: {
      paddingBottom: theme.spacing.md,
      marginBottom: theme.spacing.md * 1.5,
      padding: "5px 10px 0",
    },

    footer: {
      paddingTop: theme.spacing.md,
      marginTop: theme.spacing.md,
    },

    link: {
      ...theme.fn.focusStyles(),
      "display": "flex",
      "alignItems": "center",
      "textDecoration": "none",
      "fontSize": theme.fontSizes.sm,
      "color": theme.colors.gray[5],
      "padding": `${theme.spacing.xs}px ${theme.spacing.sm}px`,
      "borderRadius": theme.radius.sm,
      "fontWeight": 500,

      "cursor": "pointer",
      "userSelect": "none",

      "marginBottom": "8px",

      "&:hover": {
        backgroundColor: theme.colors.gray[8],
        color: theme.colors.gray[2],
        [`& .${icon}`]: {
          color: theme.colors.gray[2],
        },
      },
    },

    support: {
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      fontSize: theme.fontSizes.sm,
      color: theme.colors.gray[5],
      padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
    },

    mailToLink: {
      color: theme.colors.violet[5],
    },

    linkIcon: {
      width: 20,
      ref: icon,
      color: theme.colors.gray[5],
      marginRight: theme.spacing.sm,
    },

    linkActive: {
      "&, &:hover": {
        color: theme.white,
        backgroundColor: theme.fn.lighten(
          theme.fn.variant({ variant: "filled", color: theme.white }).background!,
          0.15,
        ),
        [`& .${icon}, svg path`]: {
          color: "white",
          stroke: "white",
        },
      },
    },
  };
});
