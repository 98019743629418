import { AUTH_API_URL, axiosInstancePrivate, axiosInstancePublic } from "shared/definitions/api";
import { ILogOutResponse, IResetPasswordResponse } from "shared/definitions";
import { memoWithTimer } from "../memoWithTimer";
import { sentryCaptureError } from "../sentry";

const REFRESH_TOKEN_MEMO_TIMEOUT = 10000;

/**
 * @returns current access_token
 */
export const getAccessToken = () => {
  return window.localStorage.getItem("access_token");
};

export const saveAccessToken = (token: string) => {
  window.localStorage.setItem("access_token", token);
};

export const removeAccessToken = () => {
  return window.localStorage.removeItem("access_token");
};

export const auth = async () => {
  try {
    const { protocol, host, pathname } = window.location;
    const urlWithoutParams = encodeURIComponent(protocol + "//" + host + pathname);
    const loginUrlResponse = await axiosInstancePublic.get<{ loginPageUrl: string }>(
      `${AUTH_API_URL}/api/v1/auth/login-page-url?redirect_uri=${urlWithoutParams}`,
    );
    if (loginUrlResponse.status === 200) {
      const loginUrl = loginUrlResponse.data.loginPageUrl;
      window.location.href = `${loginUrl}`;
    } else {
      sentryCaptureError("Couldn't get login page url", { extra: { responseStatus: loginUrlResponse.statusText } });
    }
  } catch (e) {
    sentryCaptureError(e, { extra: { error: "Couldn't get login page url" } });
  }
};

/**
 *
 * @param secret get it from url after redirect from keycloak
 * @param redirectUrl must be the same as in /auth/login-page-url?redirect_url=...
 */
export const requestAccessToken = async (secret: string) => {
  try {
    const { protocol, host, pathname } = window.location;
    const urlWithoutParams = protocol + "//" + host + pathname;
    const fetchRes = await axiosInstancePublic.post<{ accessToken: string }>(`${AUTH_API_URL}/api/v1/auth`, {
      code: secret,
      redirectUri: urlWithoutParams,
    });
    if (fetchRes.status === 200) {
      return fetchRes.data;
    }
    sentryCaptureError("Couldn't get access token", { extra: { responseStatus: fetchRes.statusText } });
    return { accessToken: "" };
  } catch (e) {
    sentryCaptureError(e, { extra: { error: "Couldn't get access token" } });
    return { accessToken: "" };
  }
};

export const refreshToken = memoWithTimer(async () => {
  const token = getAccessToken();
  if (!token) {
    return null;
  }
  return axiosInstancePublic
    .post<{ accessToken: string }>(`${AUTH_API_URL}/api/v1/auth/refresh-access-token`, { accessToken: token })
    .then((res) => {
      saveAccessToken(res.data.accessToken);
      return res.data.accessToken;
    })
    .catch(() => {
      removeAccessToken();
      return null;
    });
}, REFRESH_TOKEN_MEMO_TIMEOUT);

/**
 * Invalidated your token and return url to logout from keycloak
 * @returns
 */
export const logout = async () => {
  const { protocol, host } = window.location;
  const redirectUrl = protocol + "//" + host;
  const { data } = await axiosInstancePrivate.post<ILogOutResponse>(`${AUTH_API_URL}/api/v1/auth/logout`, {
    redirectUrl,
  });
  const logoutUrl = data.logoutUrl;
  window.localStorage.clear();
  window.location.href = logoutUrl;
};

export const getUpdatePasswordUrl = async () => {
  const { protocol, host, pathname } = window.location;
  const urlWithoutParams = `${protocol}//${host}${pathname}`;
  const resp = await axiosInstancePublic.get<{ passwordChangeUrl: string }>(
    `${AUTH_API_URL}/api/v1/auth/password-change-url`,
    {
      params: {
        redirect_uri: urlWithoutParams,
      },
    },
  );
  if (resp.status === 200) {
    return `${resp.data.passwordChangeUrl}`;
  }
  sentryCaptureError("Couldn't get password update url", { extra: { responseStatus: resp.statusText } });
  return "";
};

export const getResetPasswordUrl = async () => {
  const { data } = await axiosInstancePrivate.get<IResetPasswordResponse>(
    `${AUTH_API_URL}/api/v1/auth/reset-password-url`,
  );
  return data.resetPasswordUrl;
};

export function isTokenExpired(token: string) {
  try {
    if (token) {
      const tokenInfo = JSON.parse(window.atob(token.split(".")[1]));
      return tokenInfo.exp <= Date.now() / 1000;
    }
    return true;
  } catch (e) {
    return true;
  }
}
