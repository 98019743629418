import { TCurrency } from "shared/definitions";
import { API_URL, axiosInstancePrivate } from "shared/definitions/api";
import { TBalance, TWallet, TWalletRaw } from "./types";

export async function fetchBalances() {
  const promiseAll = Promise.all([
    axiosInstancePrivate.get<{ items: TWalletRaw[] }>(`${API_URL}/api/v1/wallets`),
    axiosInstancePrivate.get<{ items: TCurrency[] }>(`${API_URL}/api/v1/currencies`),
  ]);
  return promiseAll.then(([wallets, currencies]) => {
    const rawWalletsData = wallets.data.items;
    const rawCurrenciesData = currencies.data.items.map((el) => {
      return { ...el, enableDeposit: true, enableExchange: true, enableWithdraw: true };
    });
    const walletsWithCurrency: TWallet[] = [];
    rawWalletsData.forEach((el) => {
      const currency = rawCurrenciesData.find((currencyEl) => el.currencyId === currencyEl.id);
      if (currency) {
        walletsWithCurrency.push({
          ...el,
          currency,
          amount: Number(el.amount),
        });
      }
    });
    const balancesByCurrencies = walletsWithCurrency.reduce((prev, el) => {
      const ticker = el.currency.ticker;

      if (!prev[ticker]) {
        prev[ticker] = {
          ticker: el.currency.ticker,
          balance: el.amount,
          name: el.currency.name,
          isFiat: !el.currency.network,
          currency: el.currency,
        };
      }
      return prev;
    }, {} as Record<string, TBalance>);
    const currencyBalances = Object.values(balancesByCurrencies);
    return {
      currencyBalances,
      walletsWithCurrency,
    };
  });
}
