import classNames from "classnames";
import styles from "../../Main.module.scss";

type TProps = {
  blockedCurrencies: string[];
};

export function BlockedCurrencies(props: TProps) {
  return <div className={classNames(styles.card, styles.warn, styles.verifyContainer)}>Операции по {props.blockedCurrencies.join(", ")} временно приостановлены по техническим причинам. </div>;
}
