import classNames from "classnames";
import { RoundCurrencyIcon } from "components/common/RoundCurrencyIcon";
import { formatCurrency } from "shared/utils/formatCurrency";
import { useStores } from "store/root/provider";
import { TBalance } from "store/wallets/types";
import { WalletActions } from "./WalletActions";
import styles from "./Wallets.module.scss";

type TProps = {
  el: TBalance;
  isFiat: boolean;
};

export function CurrencyRowMobile({ el, isFiat }: TProps) {
  const { estimate } = useStores();
  const defaultCurrencyInfo = estimate.defaultCurrency;

  return (
    <div className={classNames(styles.card, styles.currencyContainer)} key={el.ticker}>
      <div className={styles.currencyFirstBlock}>
        <div className={styles.currencyInfoContainer}>
          <RoundCurrencyIcon className={styles.icon} icon={el.ticker} />
          <div className={styles.currencyDescriptionContainer}>
            <div className={styles.currencyTicker}>{el.ticker.toUpperCase()}</div>
            <div className={styles.currencyName}>{el.name}</div>
          </div>
        </div>
        <div className={styles.balanceCell}>
          <div>{formatCurrency(el.balance, { ticker: el.ticker })}</div>
          <div>
            {defaultCurrencyInfo
              ? "≈ " +
                formatCurrency(estimate.estimateToDefault(el.ticker, el.balance), {
                  ticker: defaultCurrencyInfo!.ticker,
                  displayTicker: true,
                })
              : "–"}
          </div>
        </div>
      </div>
      <WalletActions ticker={el.ticker} isFiat={isFiat} />
    </div>
  );
}
