import { Button, Title } from "@mantine/core";
import { WarningModal } from "components/common/WarningModal";
import { observer } from "mobx-react";
import { ReactNode } from "react";
import { DialogStore } from "store/dialog";

type TProps = {
  store: DialogStore;
  title: string;
  children: ReactNode;
  approveText?: string;
};

export const Dialog = observer((props: TProps) => {
  return (
    <WarningModal
      header={<Title order={4}>{props.title}</Title>}
      onClose={() => {
        props.store.resolve(false);
      }}
      mode="warn"
      open={props.store.open}
      footerActions={
        <>
          <Button
            variant="default"
            onClick={() => {
              props.store.resolve(false);
            }}>
            Закрыть
          </Button>
          <Button
            onClick={() => {
              props.store.resolve(true);
            }}>
            {props.approveText || "Подтвердить"}
          </Button>
        </>
      }>
      {props.children}
    </WarningModal>
  );
});
