import { Anchor, Button, Checkbox, NumberInput, Select, Title } from "@mantine/core";
import { RoundCurrencyIcon } from "components/common/RoundCurrencyIcon";
import { computed } from "mobx";
import { useStores } from "store/root/provider";
import { observer } from "mobx-react";
import { RouteState } from "react-router5/dist/types";
import { Card } from "components/common/Card";
import { formatFiat } from "shared/utils/formatCurrency";
import { Estimate, SystemLimitsCheckResult } from "store/estimate";
import classNames from "classnames";
import { useState } from "react";
import { DepositFiat } from "store/depositFiat";
import { InfoPopover } from "components/common/InfoPopover";
import router from "router";
import { TFlowType } from "shared/definitions";
import { SUPPORT_EMAIL_LINK } from "shared/definitions/constants";
import { logOwnershipCheckbox } from "shared/utils/api/bancAccCheckbox";
import styles from "./Form.module.scss";
import { AccountDetails } from "./AccountDetails";

type TProps = {
  routeState: RouteState;
  fiatFlow: TFlowType.cash_register | TFlowType.transfer;
};

function getErrorText(limitStatus: SystemLimitsCheckResult, ticker: string, estimate: Estimate, fiatFlow: TFlowType) {
  const { min, max } = estimate.getLimitsWithType(ticker, "deposit", fiatFlow);
  if (limitStatus === SystemLimitsCheckResult.MaxExceeded) {
    return (
      <>
        Сумма пополнения не должна превышать {formatFiat(max, { ticker, displayTicker: true })}
        &nbsp;
        <InfoPopover message="Мы не сможем зачислить сумму более указанного лимита" />
      </>
    );
  }
  if (limitStatus === SystemLimitsCheckResult.MinExceeded) {
    return (
      <>
        Сумма пополнения должна превышать {formatFiat(min, { ticker, displayTicker: true })}
        &nbsp;
        <InfoPopover message="Мы не сможем зачислить сумму менее указанного лимита" />
      </>
    );
  }
  return "";
}

export const Form = observer((props: TProps) => {
  const { currencies, estimate, modals } = useStores();
  const fiatOptions = computed(() =>
    currencies.fiatCurrencies.map((el) => ({
      value: el.ticker,
      label: el.name,
    })),
  ).get();
  const [store] = useState(() => {
    return new DepositFiat(
      props.routeState.route.params.currency || fiatOptions[0].value || null,
      props.fiatFlow,
      estimate,
      currencies,
    );
  });
  const estimated = computed(() => {
    return store.currency ? estimate.estimateToDefault(store.currency, store.amount) : "";
  }).get();

  return (
    <div className={classNames(styles.root)}>
      <Title order={6}>Сумма</Title>
      <div className={styles.combinedInputs}>
        <Select
          data={fiatOptions}
          error={store.amount > 0 && store.systemLimitStatus !== SystemLimitsCheckResult.OK}
          classNames={{
            invalid: styles.invalid,
            input: styles.upperInput,
            icon: styles.selectIcon,
            rightSection: styles.inputRightSectionUpper,
          }}
          value={store.currency}
          icon={
            store.currency ? (
              <>
                <RoundCurrencyIcon icon={store.currency} />
                <span className={styles.selectTicker}>{store.currency}</span>
              </>
            ) : undefined
          }
          placeholder="Выберете валюту"
          onChange={(value) => {
            store.setCurrency(value);
            if (value) {
              router.navigate(router.getState().name, { currency: value?.toUpperCase() });
            }
          }}
        />
        <NumberInput
          type="number"
          inputMode="decimal"
          autoComplete="off"
          error={
            store.amount > 0 &&
            store.currency &&
            store.systemLimitStatus !== SystemLimitsCheckResult.OK &&
            getErrorText(store.systemLimitStatus, store.currency, estimate, props.fiatFlow)
          }
          precision={2}
          step={0.01}
          hideControls
          onWheel={(e) => {
            e.currentTarget.blur();
          }}
          min={0}
          value={store.amount || undefined}
          placeholder="0"
          onChange={(value) => store.setAmount(value || 0)}
          classNames={{
            input: styles.lowerInput,
            rightSection: styles.inputRightSectionLower,
            invalid: styles.invalid,
          }}
          rightSection={
            estimated
              ? formatFiat(estimated, { ticker: estimate.defaultCurrency?.ticker, displayTicker: true })
              : undefined
          }
        />
      </div>
      {store.depositLimitExceeded && (
        <Card className={styles.warning} mode="danger">
          Сумма превышает доступный месячный лимит.{" "}
          <Anchor tabIndex={-1} href={SUPPORT_EMAIL_LINK} target="_blank">
            <span>Отправьте в тех. поддержку документ</span>
          </Anchor>
          , подтверждающий источник происхождения средств, после совершения операции.
        </Card>
      )}
      {props.fiatFlow === TFlowType.transfer && (
        <div className={styles.isOwnAccountContainer}>
          <Checkbox
            checked={store.isOwnAccount}
            className={styles.checkbox}
            classNames={{ label: styles.checkboxLabel }}
            onChange={() => {
              store.setIsOwnAccount(!store.isOwnAccount);
              logOwnershipCheckbox();
            }}
            label={
              <>
                Я подтверждаю, что банковский счет принадлежит мне и используется мной&nbsp;
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}>
                  <InfoPopover
                    iconClassName={styles.ownAccountInfoIcon}
                    message="Мы не сможем зачислить средства, если счет принадлежит другому лицу"
                  />
                </span>
              </>
            }
          />
        </div>
      )}
      <div className={styles.footer}>
        {store.currency && store.showDetails && (
          <AccountDetails
            fiatFlow={props.fiatFlow}
            currency={store.currency}
            amount={store.amount}
            onClose={() => store.setShowDetails(false)}
          />
        )}
        <Button
          disabled={
            (props.fiatFlow === TFlowType.transfer && !store.isOwnAccount) ||
            store.systemLimitStatus !== SystemLimitsCheckResult.OK ||
            store.amount <= 0
          }
          onClick={async () => {
            const isOperationAllowed = await store.checkIfOperationAvailable();
            if (isOperationAllowed) {
              store.setShowDetails(true);
            } else {
              modals.setDisabledOperationModalOpen(true);
            }
          }}
          className={styles.submitButton}>
          Получить счет
        </Button>
        <span>
          {store.currency &&
            `Комиссия ${formatFiat(store.estimatedFee, { ticker: store.currency, displayTicker: true })}`}
          &nbsp;
          <InfoPopover
            message={
              <>
                Комиссия TopSwap, не включая банковские комиссии. <Anchor>Подробнее о комиссиях</Anchor>
              </>
            }
          />
        </span>
      </div>
    </div>
  );
});
