import { makeAutoObservable } from "mobx";
import { sentryCaptureError } from "shared/utils/sentry";
import { User } from "store/user/user";
import { TCompanyDocType, VerificationLevel, VerificationStateCompany } from "store/user/types";
import {
  deleteOrganizationDoc,
  fetchCompanyDocs,
  organizationConfirm,
  uploadOrganizationDocs,
} from "shared/utils/api/uploadOrganizationDocs";

const DOC_PAGE_COUNT = 6;
type TFilesMap = {
  [key in TCompanyDocType]?: string[];
};

export class VerificationStore {
  private user: User;
  private _organizationExists: boolean = false;
  private _fullName: string = "";
  private _loading: boolean = false;
  private _page: number = 0;
  private _docPageCount = DOC_PAGE_COUNT;
  private _filesWithTypes: TFilesMap = {};
  private _requiredFields: TCompanyDocType[] = [];

  public get docPageCount() {
    return this._docPageCount;
  }
  public get page(): number {
    return this._page;
  }
  public set page(value: number) {
    this._page = value;
  }
  public get loading(): boolean {
    return this._loading;
  }
  public get fullName(): string {
    return this._fullName;
  }
  public set fullName(value: string) {
    this._fullName = value;
  }

  constructor(user: User) {
    this.user = user;
    if (this.user.user?.name) {
      this.fullName = this.user.user.name;
      this._organizationExists = true;
    }
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public async init() {
    this._loading = true;
    await this.fetchFiles();
    this._loading = false;
  }

  public get verificationState(): VerificationStateCompany {
    const details = this.user.userDetails;
    if (details.type !== "company") {
      sentryCaptureError("User type is not company", { extra: { details } });
      return VerificationStateCompany.TypeSelection;
    }
    if (!details.type) {
      return VerificationStateCompany.TypeSelection;
    }
    if (details.verificationLevel === VerificationLevel.Base) {
      if (details.organizationIsDocumentUploaded) {
        return VerificationStateCompany.ManualApprove;
      }
      return VerificationStateCompany.UploadDocs;
    }

    if (details.verificationLevel === VerificationLevel.Level2) {
      return VerificationStateCompany.FullVerified;
    }

    sentryCaptureError("Couldn't determine verification state", { extra: { details } });
    return VerificationStateCompany.TypeSelection;
  }

  public get levelContent() {
    if (this.verificationState === VerificationStateCompany.TypeSelection) {
      return 0;
    }
    if (this.verificationState === VerificationStateCompany.UploadDocs) {
      return 1;
    }
    if (this.verificationState === VerificationStateCompany.ManualApprove) {
      return 2;
    }
    if (this.verificationState === VerificationStateCompany.FullVerified) {
      return 3;
    }
    return 0;
  }

  public get files() {
    return this._filesWithTypes;
  }

  public async fetchFiles() {
    return fetchCompanyDocs()
      .then((res) => {
        this._filesWithTypes = res.data.items.reduce((prev, cur) => {
          if (!prev[cur.type]) {
            prev[cur.type] = [];
          }
          prev[cur.type]?.push(...cur.fileLinks);
          return prev;
        }, {} as TFilesMap);
      })
      .catch((e) => {
        sentryCaptureError(e, { extra: { error: "Couldn't fetch company docs" } });
        return {};
      });
  }

  public async uploadWithType(docType: TCompanyDocType, files: File[]) {
    try {
      this._loading = true;
      await uploadOrganizationDocs(docType, files);
      await this.fetchFiles();
    } catch (e) {
      sentryCaptureError(e, { extra: { error: "Couldn't upload organization docs", docType, files } });
    } finally {
      this._loading = false;
    }
  }

  public async removeWithType(docType: TCompanyDocType, path: string) {
    try {
      this._loading = true;
      await deleteOrganizationDoc(docType, path);
      await this.fetchFiles();
    } catch (e) {
      sentryCaptureError(e, { extra: { error: "Couldn't delete organization docs", docType, path } });
    } finally {
      this._loading = false;
    }
  }

  public confirmDocUploaded() {
    organizationConfirm()
      .then(() => {
        this.user.fetchUserData();
      })
      .catch((e) => {
        sentryCaptureError(e, { extra: { error: "Couldn't confirm organization docs" } });
      });
  }

  public curryUploadWithType(docType: TCompanyDocType) {
    return (files: File[]) => {
      this.uploadWithType(docType, files);
    };
  }

  public curryRemoveWithType(docType: TCompanyDocType) {
    return (path: string) => {
      this.removeWithType(docType, path);
    };
  }

  public setRequiredFields(fields: TCompanyDocType[]) {
    this._requiredFields = fields;
  }

  public get organizationExists(): boolean {
    return this._organizationExists;
  }

  public get missingFields(): TCompanyDocType[] {
    const res: TCompanyDocType[] = [];
    this._requiredFields.forEach((el) => {
      if (!this._filesWithTypes[el]?.length) {
        res.push(el);
      }
    });
    return res;
  }
}
