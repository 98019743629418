import { HTMLAttributes, ReactNode } from "react";
import classNames from "classnames";
import styles from "./LinkButton.module.scss";

type TProps = {
  children: ReactNode;
} & HTMLAttributes<HTMLSpanElement>;

export function LinkButton(props: TProps) {
  return (
    <span {...props} className={classNames(styles.root, props.className)}>
      {props.children}
    </span>
  );
}
