import { useRoute } from "react-router5";
import { DepositCrypto } from "pages/DepositCrypto";
import { WithdrawCrypto } from "pages/WithdrawCrypto";
import { Route } from "shared/definitions";
import NotWrapper from "./pages/wrappers/NotWrapper";
import { NotFound } from "./pages/NotFound";
import { Main, PageLayout } from "./pages";
import { WithdrawFiat } from "./pages/WithdrawFiat";
import { DepositFiat } from "./pages/DepositFiat";
import { Exchange } from "./pages/Exchange";
import { Account } from "./pages/Account";
import { History } from "./pages/History";
import { Verification } from "./pages/Verification";
import { Settings } from "./pages/Settings";

export default function Routes() {
  const routeState = useRoute();
  let Component: any = NotFound;
  let Wrapper: any = PageLayout;

  switch (routeState.route.name) {
    case Route.MAIN_SCREEN:
      Wrapper = PageLayout;
      Component = Main;
      break;
    case Route.DEPOSIT_FIAT:
      Wrapper = PageLayout;
      Component = DepositFiat;
      break;
    case Route.WITHDRAW_FIAT:
      Wrapper = PageLayout;
      Component = WithdrawFiat;
      break;
    case Route.DEPOSIT_CRYPTO:
      Wrapper = PageLayout;
      Component = DepositCrypto;
      break;
    case Route.WITHDRAW_CRYPTO:
      Wrapper = PageLayout;
      Component = WithdrawCrypto;
      break;
    case Route.EXCHANGE:
      Wrapper = PageLayout;
      Component = Exchange;
      break;
    case Route.ACCOUNT:
      Wrapper = PageLayout;
      Component = Account;
      break;
    case Route.SETTINGS:
      Wrapper = PageLayout;
      Component = Settings;
      break;
    case Route.HISTORY:
      Wrapper = PageLayout;
      Component = History;
      break;
    case Route.VERIFICATION:
      Wrapper = PageLayout;
      Component = Verification;
      break;
    default:
      Wrapper = NotWrapper;
      Component = NotFound;
      break;
  }

  return (
    <Wrapper routeState={routeState}>
      <Component routeState={routeState} />
    </Wrapper>
  );
}
