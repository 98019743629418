import { TCompanyDocType } from "store/user/types";
import { Space } from "@mantine/core";
import { LinkButton } from "components/ui/LinkButton";
import { TFileInputCardProps } from "../FileInputCard";

export const FileCardConfigs: Record<TCompanyDocType, Pick<TFileInputCardProps, "title" | "description">> = {
  questionnaire: {
    title: "Анкета юридического лица",
    description: (
      <>
        <p>Анкета подписывается руководителем организации и заверяется печатью при наличии</p>
        <Space h="xs" />
        <p>
          <LinkButton>
            <a href="/">Скачать документ</a>
          </LinkButton>
        </p>
      </>
    ),
  },
  registration_certificate: {
    title: "Свидетельство о регистрации",
  },
  charter: {
    title: "Устав",
    description:
      "Актуальная версия устава на русском, белорусском или английском языках. При предоставлении устава на другом языке, необходимо приложить нотариально заверенный перевод на любом из перечисленных выше языков",
  },
  excerpt_from_egr: {
    title: "Выписка из ЕГР",
    description:
      "Для нерезидента Республики Беларусь предоставляется выписка из торгового реестра, содержащая сведения о конечных бенефициарных владельцах, выданная не позднее 6 месяцев с даты предоставления",
  },
  office_lease_agreement: {
    title: "Договор аренды офисного помещения",
    description:
      "В случае несовпадения фактического место нахождения компании с местом регистрации (юридическим адресом)",
  },
  Tax_declaration: {
    title: "Налоговая декларация",
    description:
      "Налоговая декларация по налогу на прибыль за последний отчетный период с отметкой о принятии налоговой (допускается вариант, подписанный ЭЦП). Для УСН  —  налоговая декларация по налогу при упрощенной системе налогообложения",
  },
  Balance_sheet: {
    title: (
      <>
        Бухгалтерский баланс и<br />
        Отчет о прибылях и убытках
      </>
    ),
    description: "Предоставляется за последний отчетный период с подписью ответственных лиц",
  },
  Audit_report: { title: "Аудиторское заключение годовой бухгалтерской отчетности (при наличии)" },
  // page2
  ceo_identity_proof: {
    title: "Документы, удостоверяющие личность",
    description: "Страницы, содержащие личные данные и отметку о месте жительства.",
  },
  ceo_appointment_protocol: {
    title: "Решение/протокол о назначении на должность",
    description:
      "С указанием срока полномочий, если не указан срок, то иной документ, удостоверяющий срок действия полномочий руководителя. ",
  },
  ceo_contract: {
    title: "Копия договора",
    description: "с организацией или ИП и копия документа, подтверждающего полномочия директора данной организации",
  },
  // page3
  accountant_identity_proof: {
    title: "Документы, удостоверяющие личность",
    description: "Страницы, содержащие личные данные и отметку о месте жительства.",
  },
  accountant_appointment_protocol: {
    title: "Приказ о назначении на должность",
    description: "с указанием срока полномочий",
  },
  accountant_contract: {
    title: "Копия договора",
    description: "с организацией или ИП и копия документа, подтверждающего полномочия директора данной организации",
  },
  // page4
  founder_identity_proof: {
    title: "Документы, удостоверяющие личность",
    description:
      "Страницы, содержащие личные данные и отметку о месте жительства. Сведения предоставляются для каждого конечного физического лица, которое владеет не менее чем 10% акций (долей в уставном фонде, паев)",
  },
  founder_charter: {
    title: "Уставы каждой организации",
    description:
      "Сведения предоставляются для каждого конечного юридическое лица, которое владеет не менее чем 10% акций (долей в уставном фонде, паев)",
  },
  founder_org_structure: {
    title: "Организационная структура",
    description:
      "Документ должен содержать должности и ФИО руководителей, главного бухгалтера и иных уполномоченных лиц для каждой организации",
  },
  founder_beneficial_ownership: {
    title: "Данные о конечных бенефициарных владельцах",
    description:
      "физических лиц, которые владеют не менее чем 10% акций (долей в уставном фонде, паев). Указывается ФИО и доля в обществе. Для акционерных обществ — выписка из реестра акционеров",
  },
  // page 5
  representative_identity_proof: {
    title: "Документы, удостоверяющие личность",
    description: "Страницы, содержащие личные данные и отметку о месте жительства.",
  },
  representative_appointment_protocol: {
    title: "Приказ о назначении на должность",
    description: "С указанием срока полномочий",
  },
  appointment_proxy: {
    title: "Доверенность",
    description: (
      <>
        <p>на управление аккаунтом</p>
        <Space h="xs" />
        <p>
          <LinkButton>
            <a href="/">Скачать документ</a>
          </LinkButton>
        </p>
      </>
    ),
  },
};

export const Page1Fields: TCompanyDocType[] = [
  "questionnaire",
  "registration_certificate",
  "charter",
  "excerpt_from_egr",
  "office_lease_agreement",
  "Tax_declaration",
  "Balance_sheet",
  "Audit_report",
];
export const Page2Fields: { personal: TCompanyDocType[]; company: TCompanyDocType[] } = {
  personal: ["ceo_identity_proof", "ceo_appointment_protocol"],
  company: ["ceo_contract"],
};
export const Page3Fields: { personal: TCompanyDocType[]; company: TCompanyDocType[] } = {
  personal: ["accountant_identity_proof", "accountant_appointment_protocol"],
  company: ["accountant_contract"],
};
export const Page4Fields: { personal: TCompanyDocType[]; company: TCompanyDocType[] } = {
  personal: ["founder_identity_proof"],
  company: ["founder_charter", "founder_org_structure", "founder_beneficial_ownership"],
};
export const Page5Fields: TCompanyDocType[] = [
  "representative_identity_proof",
  "representative_appointment_protocol",
  "appointment_proxy",
];
