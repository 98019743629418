import { API_URL, axiosInstancePrivate } from "shared/definitions/api";
import { TQuestion } from "./types";

export async function fetchQuestions() {
  return axiosInstancePrivate.get<{ items: TQuestion[] }>(`${API_URL}/api/v1/account/test`);
}

export async function sendResults(answers: number[]) {
  return axiosInstancePrivate.post<{ answers: boolean[] }>(`${API_URL}/api/v1/account/test/answers`, {
    answers,
  });
}
