import { TFlowType } from "shared/definitions";
import { TURNOVER_CURRENCY_TICKER } from "shared/definitions/constants";
import { sentryCaptureError } from "shared/utils/sentry";
import { Currencies } from "store/currencies/currencies";
import { TLimit } from "store/currencies/types";
import { User } from "store/user/user";

export enum SystemLimitsCheckResult {
  OK,
  MaxExceeded,
  MinExceeded,
}

type Operation = "deposit" | "exchange" | "withdraw";

function checkMinMax(min: number, max: number, amount: number) {
  if (amount < min) {
    return SystemLimitsCheckResult.MinExceeded;
  }
  if (max && amount > max) {
    return SystemLimitsCheckResult.MaxExceeded;
  }
  return SystemLimitsCheckResult.OK;
}

export class Estimate {
  user: User;
  currencies: Currencies;
  constructor(user: User, currencies: Currencies) {
    this.user = user;
    this.currencies = currencies;
  }

  get defaultCurrency() {
    const userDetails = this.user.userDetails;
    if (userDetails) {
      const id = userDetails.defaultCurrencyId;
      const defaultCurrency = this.currencies.currencyById(id);
      return defaultCurrency;
    }
    return null;
  }

  public getRateObjectByTickers(from: string, to: string) {
    from = from.toUpperCase();
    to = to.toUpperCase();
    const res = this.currencies.rates.find((el) => {
      return (el.tickerBase === from && el.tickerQuoted === to) || (el.tickerBase === to && el.tickerQuoted === from);
    });
    return res || null;
  }

  public rateByTickers(from: string, to: string) {
    if (from === to) {
      return 1;
    }
    const res = this.getRateObjectByTickers(from, to);
    if (res) {
      if (res.tickerBase === from) {
        return Number(res.buy);
      }
      return 1 / Number(res.sell);
    }
    sentryCaptureError(`Couldn't find rate for ${from}-${to}`);
    return 1;
  }

  public estimate(fromTicker: string, toTicker: string, amount: number) {
    return amount * this.rateByTickers(fromTicker, toTicker);
  }

  public estimateToDefault(fromTicker: string, amount: number) {
    const defaultCurrency = this.defaultCurrency;
    if (!defaultCurrency) {
      sentryCaptureError("Default currency not set");
      return amount;
    }
    return this.estimate(fromTicker, defaultCurrency.ticker, amount);
  }

  /**
   * @returns true if exceeded
   */
  public checkExceedEstimate(ticker: string, amount: number) {
    if (this.defaultCurrency && this.user.userTurnover) {
      const converted = amount * this.rateByTickers(ticker, TURNOVER_CURRENCY_TICKER); // Using BYN because user turnover is now in BYN's
      const exceeded = this.user.userTurnover.max <= this.user.userTurnover.amount + converted;
      return exceeded;
    }
    return false;
  }

  public getLimitsWithType(
    ticker: string,
    operation: Operation,
    fiatFlowType?: TFlowType,
  ): { max: number; min: number } {
    const limit = this.currencies.limits.find((el) => el.ticker === ticker);
    if (!limit) {
      sentryCaptureError(`No limits found for ${ticker}`);
      return { max: 0, min: 0 };
    }
    if (operation === "deposit") {
      if (fiatFlowType === TFlowType.transfer) {
        return { min: limit.depositTransferMin, max: limit.depositTransferMax };
      }
      return { min: limit.depositMin, max: limit.depositMax };
    }
    if (operation === "withdraw") {
      if (fiatFlowType === TFlowType.transfer) {
        return {
          min: limit.withdrawTransferMin,
          max: limit.withdrawTransferMax,
        };
      }
      return { min: limit.withdrawMin, max: limit.withdrawMax };
    }
    return { min: limit.exchangeMin, max: limit.exchangeMax };
  }

  public checkSystemLimits(ticker: string, amount: number, operation: Operation, fiatFlowType?: TFlowType) {
    const limit = this.getLimitsWithType(ticker, operation, fiatFlowType);
    if (limit) {
      return checkMinMax(limit.min, limit.max, amount);
    }
    sentryCaptureError(`No limits found for ticker ${ticker}`);
    return SystemLimitsCheckResult.OK;
  }
}
