// import { API_URL, axiosInstancePrivate } from "shared/definitions/api";

import { TBankRequisitesResponse } from "shared/definitions";
import { API_URL, axiosInstancePrivate } from "shared/definitions/api";
import { sentryCaptureError } from "../sentry";

export async function getTransferAccountDetails(currencyId: number): Promise<{ data: TBankRequisitesResponse } | null> {
  try {
    const res = await axiosInstancePrivate.get<TBankRequisitesResponse[]>(`${API_URL}/api/v1/transactions/deposit/requisites/${currencyId}`);
    if (res.status === 200 && res.data[0]) {
      return { data: res.data[0] };
    }
    return null;
  } catch (e) {
    sentryCaptureError(e);
    return null;
  }
}