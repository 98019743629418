import { Title, Text, Space } from "@mantine/core";
import { CryptoWithdraw } from "store/cryptoWithdraw/cryptoWithdraw";
import { observer } from "mobx-react";
import styles from "./Processing.module.scss";

type TProps = {
  store: CryptoWithdraw;
};

export const ProcessingTopSection = observer((props: TProps) => {
  switch (props.store.step) {
    case "Processing":
      return (
        <>
          <Title order={3}>Обрабатываем операцию</Title>
          <Space h="md" />
          <Text className={styles.text}>
            Пока мы выполняем операцию вы можете продолжать пользоваться нашей платформой. Отслеживайте статус операции
            в разделе История.
          </Text>
        </>
      );
    case "Finished":
      return (
        <>
          <Title order={3}>Операция выполнена</Title>
          <Space h="md" />
          <Text className={styles.text}>
            Пока мы выполняем операцию вы можете продолжать пользоваться нашей платформой. Отслеживайте статус операции
            в разделе История.
          </Text>
        </>
      );
    case "Liveness":
      return (
        <>
          <Title order={3}>Подтвердите операцию</Title>
          <Space h="md" />
          <Text className={styles.text}>
            Пройдите проверку личности для подтверждения операции.
          </Text>
        </>
      );
    default:
      return null;
  }
});
