import { Title } from "@mantine/core";
import type { THistoryItem } from "store/history/types";
import { historyFormatDate } from "shared/utils";
import styles from "../History.module.scss";
import HistoryItem from "./HistoryItem";

const HistoryGroupItem = ({ el: [, group] }: { el: [string, THistoryItem[]] }) => {
  return (
    <div className={styles.historyGroupContainer}>
      <div>
        <Title order={5} className={styles.dayTitle}>
          {historyFormatDate(group?.[0].createdAt)}
        </Title>
        <ol className={styles.dayListContainer}>
          {group.map((item, itemKey) => {
            return (
              <li key={`ThListContainerItem${itemKey}`} className={styles.historyItem}>
                <HistoryItem item={item} />
              </li>
            );
          })}
        </ol>
      </div>
    </div>
  );
};

export default HistoryGroupItem;
