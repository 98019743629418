import axios, { AxiosError } from "axios";
import { auth, isTokenExpired, refreshToken } from "shared/utils/auth";
import { copyFieldsCamelCase } from "shared/utils/mappings/snakeCase";
import { APP_ENV } from "../../app-env";

export const refetchDelayOnError = 5_000;
const AXIOS_NETWORK_ERROR = "ERR_NETWORK";

type TNetworkErrorHandler = () => void
type TNetworkErrorHandlers = TNetworkErrorHandler[];

const networkErrorHandlers: TNetworkErrorHandlers = [];
export const axiosInstancePrivate = axios.create();
export const axiosInstancePublic = axios.create();

function handleNetworkError(error: AxiosError) {
  if (error.code === AXIOS_NETWORK_ERROR) {
    networkErrorHandlers.forEach(el => el());
  }
}

axiosInstancePrivate.interceptors.request.use(async (config) => {
  let accessToken = localStorage.getItem("access_token");
  config.headers = config.headers ?? {};
  config.headers["Content-Type"] = config.headers["Content-Type"] ?? "application/json";

  if (accessToken && isTokenExpired(accessToken)) {
    accessToken = await refreshToken();
  }
  if (accessToken) {
    const authString = `Bearer ${accessToken}`;
    config.headers.Authorization = authString;
  } else {
    auth();
  }
  return config;
});

axiosInstancePrivate.interceptors.response.use(
  (response) => {
    if (response.data && response.headers["content-type"] === "application/json") {
      response.data = copyFieldsCamelCase(response.data);
    }
    return response;
  },
  async (error) => {
    handleNetworkError(error);
    const originalRequest = error.config;
    if (error.response?.status === 401) {
      if (!originalRequest.retryRequest) {
        originalRequest.retryRequest = true;
        const accessToken = await refreshToken();
        if (!accessToken) {
          return auth();
        }
        originalRequest.headers.Authorization = `Bearer ${accessToken}`;
        return axiosInstancePrivate(originalRequest);
      }
      return auth();
    }
    if (error.response?.status > 200 || !error.response?.status) {
      throw error;
    }
    return error;
  },
);

axiosInstancePublic.interceptors.request.use((config) => {
  config.headers = config.headers ?? {};
  return config;
});

axiosInstancePublic.interceptors.response.use(
  (response) => response,
  async (error) => {
    handleNetworkError(error);
    return error;
  },
);

export function SubscribeOnNetworkError(callback: () => void): () => void {
  networkErrorHandlers.push(callback);
  return () => {
    const index = networkErrorHandlers.indexOf(callback);
    networkErrorHandlers.splice(index, 1);
  };
}

export const API_URL = `${APP_ENV.REACT_APP_API_WALLET_URL}/swap`;
export const AUTH_API_URL = APP_ENV.REACT_APP_API_WALLET_URL;
