import { ReactComponent as Usdc } from "shared/static/icons/adapted/coins/USDC.svg";
import { ReactComponent as Usdt } from "shared/static/icons/adapted/coins/USDT.svg";
import { ReactComponent as Byn } from "shared/static/icons/adapted/coins/BYN.svg";
import { ReactComponent as Rub } from "shared/static/icons/adapted/coins/RUB.svg";
import { ReactComponent as Usd } from "shared/static/icons/adapted/coins/USD.svg";
import { ReactComponent as Btc } from "shared/static/icons/adapted/coins/BTC.svg";

type TProps = {
  icon: string;
  className?: string;
}; 

export function getComponent(icon: string) {
  switch (icon.toLowerCase()) {
    case "usdt":
      return Usdt;
    case "usdc":
      return Usdc;
    case "byn":
      return Byn;
    case "rub":
      return Rub;
    case "usd":
      return Usd;
    case "btc":
      return Btc;
    default:
      return Usd;
  }
}

export const CurrencyIcon = (props: TProps) => {
  const Component = getComponent(props.icon);
  return <Component className={props.className} />;
};
