import { TFlowType } from "shared/definitions";
import { API_URL, axiosInstancePrivate } from "shared/definitions/api";
import { sentryCaptureError } from "../sentry";

export type TEstimateFeeRequestParams = {
  amount: string;
  tickerTo?: string;
  tickerFrom?: string;
  fiatFlowType?: TFlowType;
};

export type TEstimateFeeResponse = {
  amountFrom: string;
  amountTo: string;
  fee: string;
  feeTicker: string;
};

export type TEstimateFeeData = {
  amountFrom: number;
  amountTo: number;
  fee: number;
  feeTicker: string;
};

/**
 *
 * @param config tickerTo for deposit, tickerFrom for withdraw, both for exchange
 * @returns
 */
export async function estimateFee(config: TEstimateFeeRequestParams): Promise<TEstimateFeeData | null> {
  const res = await axiosInstancePrivate.post<TEstimateFeeResponse>(
    `${API_URL}/api/v1/transactions/calculate-amounts-fee`,
    config,
  );
  if (res.status === 200) {
    const data = {
      amountFrom: Number.parseFloat(res.data.amountFrom),
      amountTo: Number.parseFloat(res.data.amountTo),
      fee: Number.parseFloat(res.data.fee),
      feeTicker: res.data.feeTicker,
    };
    if (Number.isNaN(data.amountFrom) || Number.isNaN(data.amountTo) || Number.isNaN(data.fee)) {
      sentryCaptureError("Couldn't parse estimated fee response", { extra: { data: res.data } });
    }
    return data;
  }
  return null;
}
