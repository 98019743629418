import { useStores } from "store/root/provider";
import { Button, Title, Text } from "@mantine/core";
import { useCallback } from "react";
import { observer } from "mobx-react";
import { WarningModal } from "../WarningModal";

export const OperationDisabled = observer(() => {
  const { modals } = useStores();
  const closeHandler = useCallback(() => {
    modals.setDisabledOperationModalOpen(false);
  }, [modals]);
  return (
    <WarningModal
      mode="warn"
      open={modals.isDisabledOperationModalOpen}
      onClose={closeHandler}
      header={
        <>
          <Title order={4}>Операция недоступна</Title>
          <Text>Операция с выбранной валютой временно недоступна по техническим причинам</Text>
        </>
      }
      footerActions={<Button onClick={closeHandler}>Закрыть</Button>}
    />
  );
});
