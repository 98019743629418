import { Title, Space, Center, SegmentedControl, Flex, Overlay } from "@mantine/core";
import { observer } from "mobx-react";
import { VerificationStore } from "store/verificationCompany";
import { useEffect, useMemo, useState } from "react";
import { FileInputCard } from "../FileInputCard";
import { DocRequirements } from "./DocRequirements";
import { getFileCardProps } from "../utils";
import { FileCardConfigs, Page4Fields } from "./FileCardConfigs";

type TProps = {
  store: VerificationStore;
};

type TPersonType = "personal" | "company";

export const Page4 = observer((props: TProps) => {
  const [personType, setPersonType] = useState<TPersonType>("personal");
  const fields = useMemo(() => {
    return Page4Fields[personType];
  }, [personType]);

  useEffect(() => {
    props.store.setRequiredFields(fields);
    return () => {
      props.store.setRequiredFields([]);
    };
  }, [fields, props.store]);

  return (
    <>
      <Title order={4}>Учредители/бенефециарные владельцы</Title>
      <Space h="md" />
      <DocRequirements />
      <Space h="md" />
      <SegmentedControl
        value={personType}
        color="violet"
        onChange={(value) => setPersonType(value as TPersonType)}
        data={[
          {
            value: "personal",
            label: <Center>Физическое лицо</Center>,
          },
          {
            value: "company",
            label: <Center>Юридическое лицо или ИП</Center>,
          },
        ]}
      />
      <Space h="sm" />
      <Flex pos="relative" direction="column" gap="sm">
        { props.store.loading && <Overlay /> }
        {fields.map((field) => {
          return <FileInputCard key={field} {...FileCardConfigs[field]} {...getFileCardProps(props.store, field)} />;
        })}
      </Flex>
    </>
  );
});
