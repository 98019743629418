import { Button, Title } from "@mantine/core";
import { WarningModal } from "components/common/WarningModal";
import { CopyContent } from "components/common/CopyContent";
import styles from "./Modals.module.scss";

type TWithdrawData = {
  currency: string;
  address: string;
  amount: string;
  commission: string;
};

type TProps = {
  open: boolean;
  data?: TWithdrawData;
  onClose: () => void;
  onConfirm: () => void;
};

export function Confirm(props: TProps) {
  return (
    <WarningModal
      mode="warn"
      modalClassName={styles.modal}
      open={props.open}
      onClose={props.onClose}
      header={
        <>
          <Title className={styles.header} order={4}>
            Вывести крипту
          </Title>
          <p>Пожалуйста, проверьте детали операции</p>
        </>
      }
      footerActions={
        <>
          <Button onClick={props.onClose} color="gray.9" variant="outline">
            Отмена
          </Button>
          <Button onClick={props.onConfirm}>Подтвердить</Button>
        </>
      }>
      <section className={styles.infoContainer}>
        <div className={styles.infoRow}>
          <Title className={styles.infoHeader} order={6}>
            Валюта
          </Title>
          <p>{props.data?.currency}</p>
        </div>
        <div className={styles.infoRow}>
          <Title className={styles.infoHeader} order={6}>
            Адрес
          </Title>
          <p>
            <CopyContent content={props.data?.address || ""}/>
          </p>
        </div>
        <div className={styles.infoRow}>
          <Title className={styles.infoHeader} order={6}>
            Сумма
          </Title>
          <p>{props.data?.amount}</p>
        </div>
        <div className={styles.infoRow}>
          <Title className={styles.infoHeader} order={6}>
            Комиссия
          </Title>
          <p>{props.data?.commission}</p>
        </div>
      </section>
    </WarningModal>
  );
}
