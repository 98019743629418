import { CryptoWithdraw } from "store/cryptoWithdraw/cryptoWithdraw";
import { observer } from "mobx-react";
import { ReactComponent as HistoryIcon } from "shared/static/icons/history.svg";
import { Button, Center } from "@mantine/core";
import router from "router";
import { Route } from "shared/definitions/routes";
import styles from "./Processing.module.scss";

type TProps = {
  store: CryptoWithdraw;
};

export const ProcessingFooterSection = observer((props: TProps) => {
  switch (props.store.step) {
    case "Processing":
    case "Finished":
      return (
        <Center>
          <Button
            className={styles.buttonHistory}
            variant="outline"
            size="sm"
            radius={6}
            mb={12}
            onClick={(e: { preventDefault: () => void }) => {
              e.preventDefault();
              router.navigate(Route.HISTORY);
            }}>
            <HistoryIcon className={styles.historyIcon} /> Посмотреть Историю
          </Button>
        </Center>
      );
    default:
      return null;
  }
});
