import { Button, Title } from "@mantine/core";
import { WarningModal } from "components/common/WarningModal";
import { observer } from "mobx-react";
import { useStores } from "store/root/provider";
import styles from "./Modals.module.scss";

type TProps = {
  open: boolean;
  onClose: () => void;
  onStartTest: () => void;
  onVerify: () => void;
};

export const VerifyDialog = observer((props: TProps) => {
  const { user: userStore } = useStores();

  return (
    <WarningModal
      mode="warn"
      modalClassName={styles.modal}
      open={props.open}
      onClose={props.onClose}
      header={
        <>
          <Title order={4}>Доступ ограничен</Title>
          <p className={styles.subHeader}>
            Чтобы получить полный доступ к платформе, пожалуйста, пройдите тестирование и верификацию.
          </p>
        </>
      }
      footerActions={
        <>
          {userStore.isVerified && userStore.needTest && !userStore.isTestPassed && (
            <Button className={styles.button} onClick={props.onStartTest}>
              Пройти тест
            </Button>
          )}

          {!userStore.isVerified && (
            <Button className={styles.button} onClick={props.onVerify}>
              Пройти верификацию
            </Button>
          )}
        </>
      }
    />
  );
});
