import { ReactComponent as TrashIcon } from "shared/static/icons/trash.svg";
import styles from "./FileLink.module.scss";

type TProps = {
  name: string;
  onDelete: () => void;
};

export function FileLink(props: TProps) {
  return (
    <span className={styles.root}>
      {props.name}
      <span className={styles.removeButton} onClick={props.onDelete}>
        <TrashIcon className={styles.icon} />
      </span>
    </span>
  );
}
