import { Title, Select, TextInput, Button, NumberInput, Notification, Popover, Checkbox } from "@mantine/core";
import classNames from "classnames";
import { RoundCurrencyIcon } from "components/common/RoundCurrencyIcon";
import { action, computed } from "mobx";
import { observer } from "mobx-react";
import { useState } from "react";
import { ReactComponent as InfoIcon } from "shared/static/icons/hint.svg";
import { CryptoWithdraw } from "store/cryptoWithdraw/cryptoWithdraw";
import { useStores } from "store/root/provider";
import { ReactComponent as Warning } from "shared/static/icons/warning.svg";
import { formatCurrency } from "shared/utils/formatCurrency";
import { chainToName } from "shared/utils/mappings/chainToName";
import { Estimate, SystemLimitsCheckResult } from "store/estimate";
import { InfoPopover } from "components/common/InfoPopover";
import { SelectItem } from "components/common/SelectItem";
import styles from "./Edit.module.scss";

type TProps = {
  store: CryptoWithdraw;
  onSubmit: () => void;
};

const ErrorNotificationShowDuration = 8_000;

function getErrorText(store: CryptoWithdraw, estimate: Estimate) {
  if (store.currency && store.amount) {
    const { min, max } = estimate.getLimitsWithType(store.currency, "withdraw");
    if (store.error.limits === SystemLimitsCheckResult.MaxExceeded) {
      return (
        <>
          Сумма вывода не должна превышать {formatCurrency(max, { ticker: store.currency, displayTicker: true })}
          &nbsp;
          <InfoPopover message="Мы не сможем вывести сумму более указанного лимита" />
        </>
      );
    }
    if (store.error.limits === SystemLimitsCheckResult.MinExceeded) {
      return (
        <>
          Сумма вывода должна превышать {formatCurrency(min, { ticker: store.currency, displayTicker: true })}
          &nbsp;
          <InfoPopover message="Мы не сможем вывести сумму менее указанного лимита" />
        </>
      );
    }
    if (store.error.balance) {
      return "Недостаточно средств на балансе";
    }
  }
  return "";
}

export const Edit = observer((props: TProps) => {
  const { currencies, estimate } = useStores();

  const [isErrorNotificationOpen, setErrorNotificationOpen] = useState(false);

  const selectedWalletInfo = props.store.selectedWallet;
  const selectItems = computed(() => {
    return currencies.cryptoCurrencies
      .map((el) => ({ label: el.name, value: el.ticker }));
  }).get();

  const chainsSelectData = computed(() =>
    props.store.chains.map((el) => ({ label: chainToName(el.toUpperCase()), value: el })),
  ).get();

  return (
    <div className={classNames(styles.card, styles.withdrawCard)}>
      {isErrorNotificationOpen && (
        <Notification
          className={styles.notification}
          icon={<Warning color="red" />}
          color="red"
          onClose={() => setErrorNotificationOpen(false)}>
          Пожалуйста, предоставьте доступ к буферу обмена в настройках браузера
        </Notification>
      )}

      <div className={styles.mainContent}>
        <Title className={styles.cardTitle} order={3}>
          Вывести крипту
        </Title>
        <section className={styles.fieldsContainer}>
          <Select
            value={props.store.currency}
            classNames={{ label: styles.label }}
            label="Выбрать валюту"
            data={selectItems}
            icon={props.store.currency ? <RoundCurrencyIcon icon={props.store.currency} /> : null}
            itemComponent={SelectItem}
            onChange={(value) => {
              props.store.setCurrency(value || "");
            }}
          />
          <Select
            disabled={chainsSelectData.length < 1}
            classNames={{ label: styles.label }}
            label="Выбрать сеть"
            value={props.store.chain}
            data={chainsSelectData}
            onChange={(value) => {
              props.store.setChain(value || "");
            }}
          />

          <TextInput
            autoComplete="off"
            className={styles.address}
            classNames={{ input: styles.addressInput, label: styles.label, rightSection: styles.addressRightSection }}
            label="Адрес"
            error={props.store.error.address}
            value={props.store.address}
            rightSection={
              <span
                onClick={() => {
                  navigator.clipboard
                    .readText()
                    .then((data) => {
                      props.store.address = data;
                    })
                    .catch(() => {
                      setErrorNotificationOpen(true);
                      setTimeout(() => setErrorNotificationOpen(false), ErrorNotificationShowDuration);
                    });
                }}>
                Вставить
              </span>
            }
            onChange={action((e) => {
              props.store.address = e.target.value;
            })}
          />
          <Checkbox
            checked={props.store.isOwnAccount}
            className={styles.checkbox}
            classNames={{ label: styles.checkboxLabel }}
            onChange={() => {
              props.store.isOwnAccount = !props.store.isOwnAccount;
            }}
            label="Я подтверждаю, что криптовалютный кошелек принадлежит мне"
          />
          <div className={styles.exchangeBlock}>
            <div className={styles.infoBlock}>
              <div>
                Сумма
                <span className={styles.actions}>
                  <span
                    className={styles.action}
                    onClick={() => {
                      props.store.setMax();
                    }}>
                    Все
                  </span>
                  <span
                    className={styles.action}
                    onClick={() => {
                      props.store.setMin();
                    }}>
                    Мин
                  </span>
                </span>
              </div>
              <div>
                Доступно:{" "}
                {!selectedWalletInfo
                  ? "–"
                  : formatCurrency(selectedWalletInfo.amount, {
                    ticker: selectedWalletInfo?.currency.ticker,
                    displayTicker: true,
                  })}
              </div>
            </div>
            <div className={styles.combinedInputs}>
              <NumberInput
                classNames={{ input: styles.upperInput, rightSection: styles.inputRightSectionUpper }}
                value={props.store.amount || undefined}
                autoComplete="off"
                type="number"
                error={props.store.error.limits !== SystemLimitsCheckResult.OK || props.store.error.balance}
                placeholder="0"
                inputMode="decimal"
                removeTrailingZeros
                precision={10}
                min={0}
                onWheel={(e) => {
                  e.currentTarget.blur();
                }}
                hideControls
                rightSection={props.store.currency?.toUpperCase() || ""}
                onChange={(value) => {
                  props.store.setAmount(typeof value === "number" ? value : null);
                }}
              />
              <NumberInput
                disabled
                error={!props.store.valid && getErrorText(props.store, estimate)}
                autoComplete="off"
                type="text"
                inputMode="decimal"
                precision={2}
                value={props.store.estimatedAmountDefaultCurrency}
                classNames={{ input: styles.lowerInput, rightSection: styles.inputRightSectionLower }}
                rightSection={props.store.defaultCurrency?.ticker}
              />
            </div>
          </div>
        </section>
      </div>
      <div>
        <div className={styles.footer}>
          <Button
            disabled={!props.store.valid || props.store.amount === null || props.store.amount <= 0}
            className={styles.submitButton}
            onClick={() => {
              props.onSubmit();
            }}>
            Вывести
          </Button>
          <span className={styles.commission}>
            Комиссия:{" "}
            {formatCurrency(props.store.fee?.amount ?? 0, { ticker: props.store.fee?.ticker, displayTicker: true })}{" "}
            <Popover width={200} position="bottom" withArrow shadow="md">
              <Popover.Target>
                <InfoIcon className={styles.commissionIcon} />
              </Popover.Target>
              <Popover.Dropdown className={styles.popover}>Общая комиссия, включая комиссию сети</Popover.Dropdown>
            </Popover>
          </span>
        </div>
      </div>
    </div>
  );
});
