import { useEffect, useMemo, useState } from "react";
import { RouteState } from "react-router5/dist/types";
import { observer } from "mobx-react";
import { useHistoryMode } from "pages/History/utils";
import { CryptoWithdraw } from "store/cryptoWithdraw/cryptoWithdraw";
import { useStores } from "store/root/provider";
import { formatCurrency } from "shared/utils/formatCurrency";
import { ToApprove } from "components/common/ToApprove";
import { History } from "../../components/common/History";
import styles from "./WithdrawCrypto.module.scss";
import { Confirm } from "./modals/Confirm";
import { Edit } from "./steps/Edit";
import { Processing } from "./steps/Processing";
import { ErrorPage } from "./steps/ErrorPage";

type TProps = {
  routeState: RouteState;
};

export const WithdrawCrypto = observer((props: TProps) => {
  useHistoryMode("withdraw", "Crypto");

  const { wallets, user, currencies, modals, estimate, history } = useStores();

  const [showConfirm, setShowConfirm] = useState(false);
  const [store] = useState(() => {
    return new CryptoWithdraw(wallets, currencies, user, modals, estimate);
  });

  const params = useMemo(() => {
    return props.routeState.route.params;
  }, [props.routeState.route]);

  useEffect(() => {
    const preSelectCurrency = params.currency;
    if (preSelectCurrency) {
      store.setCurrency(preSelectCurrency);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      store.destroy();
    };
  }, []);

  let mainContent;
  if (store.step === "Finished" || store.step === "Processing" || store.step === "Liveness") {
    mainContent = <Processing store={store} />;
  } else if (store.step === "Error") {
    mainContent = <ErrorPage store={store} />;
  } else {
    mainContent = (
      <Edit
        store={store}
        onSubmit={async () => {
          const operationAllowed = await store.checkIfOperationAvailable();
          if (operationAllowed) {
            setShowConfirm(true);
          } else {
            modals.setDisabledOperationModalOpen(true);
          }
        }}
      />
    );
  }

  return (
    <div className={styles.root}>
      {history.hasPendingApprove && <ToApprove />}
      <div className={styles.mainContainer}>
        <Confirm
          open={showConfirm}
          data={{
            currency: `${store.currency?.toUpperCase()} ${store.chain?.toUpperCase()}`,
            address: store.address,
            amount: `${store.estimatedAmount} ${store.currency?.toUpperCase()}`,
            commission: `${
              store.fee &&
            formatCurrency(store.fee?.amount, {
              ticker: store.fee.ticker,
              displayTicker: true,
            })
            }`,
          }}
          onClose={() => {
            setShowConfirm(false);
          }}
          onConfirm={async () => {
            setShowConfirm(false);
            store.createTransaction();
          }}
        />
        {mainContent}
        <History />
      </div>
    </div>
  );
});
