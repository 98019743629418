import { Button, Title } from "@mantine/core";
import { observer } from "mobx-react";
import { ReactComponent as Success } from "shared/static/icons/success.svg";
import { ReactComponent as Failed } from "shared/static/icons/fail.svg";
import { FinanceTest } from "../../../../../store/financeTest";
import styles from "./ModalContent.module.scss";

type TProps = {
  store: FinanceTest;
  onClose: () => void;
};

export const FinishScreen = observer((props: TProps) => {
  const { allCorrect } = props.store;
  if (allCorrect) {
    return (
      <>
        <Success className={styles.success} />
        <Title className={styles.finishScreenTitle}>Тест пройден!</Title>
        <p className={styles.finishScreenText}>Вы успешно прошли тестирование.</p>
        <Button onClick={props.onClose} className={styles.finishScreenButton}>
          К платформе
        </Button>
      </>
    );
  }
  return (
    <>
      <Failed className={styles.fail} />
      <Title className={styles.title}>Тест не сдан</Title>
      <p className={styles.finishScreenText}>Вы можете пересдать тест прямо сейчас или вернуться к нему позднее.</p>
      <div className={styles.buttonsContainer}>
        <span onClick={props.onClose} className={styles.laterButton}>
          Пройти позже
        </span>
        <Button onClick={() => props.store.restartWithResults()} className={styles.button}>
          Пройти еще раз
        </Button>
      </div>
    </>
  );
});
