export enum Route {
  AUTH_SCREEN = "auth",
  MAIN_SCREEN = "/",
  DEPOSIT_CRYPTO = "deposit_crypto",
  DEPOSIT_FIAT = "deposit_fiat",
  WITHDRAW_CRYPTO = "withdraw_crypto",
  WITHDRAW_FIAT = "withdraw_fiat",
  EXCHANGE = "exchange",
  ACCOUNT = "account",
  SETTINGS = "settings",
  HISTORY = "history",
  VERIFICATION = "verification",
  NOT_FOUND = "not_found",
}
