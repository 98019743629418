import { Button } from "@mantine/core";
import router from "router";
import { Route } from "shared/definitions";
import { Card } from "../Card";
import styles from "./ToApprove.module.scss";

export function ToApprove() {
  return (
    <Card mode="warning" className={styles.root}>
      <span>У вас есть неподтвержденные операции</span>
      <Button onClick={() => {
        router.navigate(Route.HISTORY);
      }}>Открыть историю</Button>
    </Card>
  );
}
