import { Button, Title } from "@mantine/core";
import { observer } from "mobx-react";
import { WarningModal } from "components/common/WarningModal";
import { logout } from "shared/utils/auth";
import styles from "./Modals.module.scss";

type TProps = {
  open: boolean;
  onClose: () => void;
};

export const Blocked = observer(({ open, onClose }: TProps) => {
  return (
    <WarningModal
      mode="warn"
      modalClassName={styles.modal}
      open={open}
      onClose={onClose}
      header={
        <>
          <Title order={4}>Доступ ограничен</Title>
          <p>
            Пожалуйста, свяжитесь с нашим менеджером для разблокировки:{" "}
            <a
              className={styles.anchor}
              tabIndex={-1}
              href="mailto:manager@topswap.io"
              target="_blank"
              rel="noreferrer">
              <span>manager@topswap.com</span>
            </a>
          </p>
        </>
      }
      footerActions={
        <>
          <Button variant="default" onClick={() => logout()}>
            Выйти
          </Button>
          <a href={"mailto:manager@topswap.com"}>
            <Button className={styles.button}>Связаться</Button>
          </a>
        </>
      }
    />
  );
});

// COMMENT TO RUN PIPELINE
