export function chainToName(chain: string) {
  switch (chain) {
    case "ETH":
      return "ERC-20";
    case "TRC20":
      return "TRC-20";
    case "BEP20":
      return "BEP-20";
    default:
      return chain;
  }
}
