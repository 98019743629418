import { TResponsibleUser } from "shared/definitions";
import { API_URL, axiosInstancePrivate } from "shared/definitions/api";

export async function fetchResponsible() {
  return axiosInstancePrivate.get<{ items: TResponsibleUser[] }>(
    `${API_URL}/api/v1/account/organization/responsible-persons`
  );
}

export function getSumSubToken(txId: string, userId: string) {
  return axiosInstancePrivate.get<{ token: string }>(
    `${API_URL}/api/v1/account/sumsub-livens-token?transaction_id=${txId}&user_id=${userId}`,
  );
}
