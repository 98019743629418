import { makeAutoObservable } from "mobx";
import { TResponsibleUser } from "shared/definitions";
import { sentryCaptureError } from "shared/utils/sentry";
import { Modals } from "store/modals/modals";
import { User } from "store/user/user";
import { getTransactionInfo } from "shared/utils/api/getTransactionInfo";
import { fetchResponsible, getSumSubToken } from "./utils";

type TStepType = "select" | "liveness";
type TTimeoutCallback = () => void;
const TX_STATUS_CHECK_INTERVAL = 5000;

export class LivenessCheck {
  private txId: string;
  private _responsibleUsers: TResponsibleUser[] | null = null;
  private _selectedUser: string = "";
  private _sumsubToken: string | null = null;
  private user: User;
  private _isCompany: boolean;
  private modals: Modals;
  private _step: "select" | "liveness";
  private autocheckInterval: number | null = null;
  private timeoutCallback: TTimeoutCallback;
  private _checkCompleted: boolean = false;

  constructor(txid: string, timeoutCallback: TTimeoutCallback, modals: Modals, user: User) {
    makeAutoObservable(this, undefined, { autoBind: true });
    this.txId = txid;
    this.modals = modals;
    this.user = user;
    this.timeoutCallback = timeoutCallback;
    this._isCompany = this.user.userDetails?.type === "company";
    if (this.isCompany) {
      this._step = "select";
    } else {
      if (this.user.user?.id) {
        this.selectedUser = this.user.user.id;
      } else {
        sentryCaptureError("Trying to initialize liveness store before initializing user store");
      }
      this._step = "liveness";
    }
    this.startTxStatusAutocheck();
  }
  public get responsibleUsers(): TResponsibleUser[] | null {
    return this._responsibleUsers;
  }
  public get step(): TStepType {
    return this._step;
  }
  public get selectedUser(): string {
    return this._selectedUser;
  }
  public set selectedUser(value: string) {
    this._selectedUser = value;
  }
  public onUserSelected() {
    if (this.selectedUser) {
      this._step = "liveness";
    }
  }
  public get checkCompleted() {
    return this._checkCompleted;
  }
  public set checkCompleted(value: boolean) {
    this._checkCompleted = value;
  }
  public get sumsubToken(): string | null {
    return this._sumsubToken;
  }
  public get isCompany(): boolean {
    return this._isCompany;
  }
  public async initResponsible() {
    return fetchResponsible()
      .then((res) => {
        this._responsibleUsers = res.data.items;
        return this.responsibleUsers;
      })
      .catch((e) => {
        sentryCaptureError("Couldn't get list of responsibles", { extra: { error: e } });
        return null;
      });
  }
  public async initSumsub() {
    const id = this.isCompany ? this.selectedUser : this.user.user?.id;
    if (id) {
      return getSumSubToken(this.txId, id)
        .then((res) => {
          this._sumsubToken = res.data.token;
          return this.sumsubToken;
        })
        .catch((e) => {
          sentryCaptureError("Couldn't get sumsub url for liveness checl", {
            extra: {
              error: e,
            },
          });
          return "";
        });
    }
    sentryCaptureError("Couldn't get sumsub url for liveness check: user id wasn't provided");
    return "";
  }
  private startTxStatusAutocheck() {
    if (this.txId) {
      this.autocheckInterval = window.setInterval(() => {
        getTransactionInfo(this.txId)
          .then((res) => {
            if (res.data.status === "rejected") {
              this.timeoutCallback();
            }
          })
          .catch(sentryCaptureError);
      }, TX_STATUS_CHECK_INTERVAL);
    } else {
      sentryCaptureError("Couldn't initialize liveness check, tx id wasn't provided");
    }
  }

  private stopTxStatusAutocheck() {
    if (this.autocheckInterval) {
      window.clearInterval(this.autocheckInterval);
    }
  }
  public destroy() {
    this.stopTxStatusAutocheck();
  }
}
