import { action, runInAction } from "mobx";
import { useEffect } from "react";
import { THistoryOperationType, THistoryCurrencyType } from "store/history/types";
import { useStores } from "store/root/provider";

export function useHistoryMode(operation: THistoryOperationType | null, currency: THistoryCurrencyType | null = null) {
  const { history: store } = useStores();
  useEffect(
    () => {
      runInAction(() => {
        store.currencyMode = currency;
        store.operationMode = operation;
      });

      return action(() => {
        runInAction(() => {
          store.currencyMode = null;
          store.operationMode = null;
        });
      });
    },
    [currency, operation, store],
  );
}
