import { Title } from "@mantine/core";
import styles from "./EditButton.module.scss";

type TProps = {
  onClick: () => void;
}

export const EditButton = ({ onClick }: TProps) => (
  <Title order={3} className={styles.accountCardRightButton} onClick={onClick}>
    <img width={24} height={24} src={"/icons/edit.svg"} title="Редактировать" alt={"Редактировать"} />
  </Title>
);
