import { action, makeAutoObservable } from "mobx";
import { sentryCaptureError } from "shared/utils/sentry";
import { TBalance, TWallet } from "./types";
import { fetchBalances } from "./utils";

const AUTOUPDATE_INTERVAL = 15000; // ms
export class Wallets {
  private balances: TBalance[] = [];
  private walletsRawData: TWallet[] = [];
  private autoupdateIntervalId: number | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  public async init() {
    return this.reload();
  }

  public async reload() {
    return fetchBalances()
      .then(
        action((res) => {
          this.balances = res.currencyBalances;
          this.walletsRawData = res.walletsWithCurrency;
        }),
      )
      .catch(sentryCaptureError);
  }

  public cryptoWalletByCoin(ticker: string, network: string) {
    return this.cryptoWallets.find((el) => {
      return el.currency.ticker === ticker && el.currency.network === network;
    });
  }

  get fiatBalances() {
    return this.balances.filter((el) => el.isFiat);
  }

  get cryptoBalances() {
    return this.balances.filter((el) => !el.isFiat);
  }

  get allBalances() {
    return this.balances;
  }

  get fiatWallets() {
    return this.walletsRawData.filter((el) => !el.currency.network);
  }

  get cryptoWallets() {
    return this.walletsRawData.filter((el) => el.currency.network);
  }

  public startAutoUpdate() {
    if (this.autoupdateIntervalId !== null) {
      sentryCaptureError("Trying to create more than one update interval in wallets");
      this.stopAutoUpdate();
    }
    this.autoupdateIntervalId = window.setInterval(() => {
      this.reload();
    }, AUTOUPDATE_INTERVAL);
  }

  public stopAutoUpdate() {
    if (this.autoupdateIntervalId) {
      window.clearInterval(this.autoupdateIntervalId);
      this.autoupdateIntervalId = null;
    }
  }

  public balanceByTicker(ticker: string) {
    return (
      this.walletsRawData.find((el) => {
        return el.currency.ticker === ticker;
      })?.amount || 0
    );
  }
}
