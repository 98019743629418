import { ReactNode } from "react";
import { observer } from "mobx-react";
import styles from "./Field.module.scss";

type TProps = {
  title: string;
  info: ReactNode;
}

export const Field = observer(({ title, info }: TProps) => {
  return (
    <div className={styles.root}>
      <div className={styles.accountFieldTitle}>
        <span>{title}</span>
      </div>
      <div className={styles.accountFieldInfo}>
        <span>{info}</span>
      </div>
    </div>
  );
});
