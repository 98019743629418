import { ReactNode } from "react";

const DEFAULT_CASE = "default";

export const switchMatch = (key: string, node: Record<string, string | ReactNode | number>) => {
  switch (typeof node) {
    case "object": {
      switch (typeof key) {
        case "boolean":
          return node[key];
        default: {
          if (key in node) {
            return node[key];
          }
          if (DEFAULT_CASE in node) {
            return node[DEFAULT_CASE];
          }
          return key;
        }
      }
    }
    default:
      break;
  }
  return false;
};
