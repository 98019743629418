import type React from "react";
import { makeAutoObservable, reaction } from "mobx";
import { RootStore } from "store/root/root";
import { TConfirmOptions, TLivenessOptions } from "./types";

export class Modals {
  public isTestModalOpen: boolean = false;
  public isVerifyModalOpen: boolean = false;
  public isStartTestModalOpen: boolean = false;
  public isShowBlockedModalOpen: boolean = false;
  public isCodeConfirmModalOpen: boolean = false;
  public isDisabledOperationModalOpen: boolean = false;
  public isLivenessTimeoutModalOpen: boolean = false;
  public codeConfirmOptions: TConfirmOptions | null = null;
  public livenessOptions: TLivenessOptions | null = null;

  private rootStore: Record<"user", RootStore["user"]>;
  constructor(rootStore: Record<"user", RootStore["user"]>) {
    makeAutoObservable<Modals, "rootStore">(this, { rootStore: false });
    this.rootStore = rootStore;

    reaction(
      () => this.rootStore.user?.user?.isBlocked,
      () => {
        if (this.rootStore.user?.user?.isBlocked === true) {
          this.setShowBlockedModalOpen(true);
        }
      },
    );
  }

  public setTestModalOpen = (value: boolean) => {
    this.isTestModalOpen = value;
  };
  public setVerifyModalOpen = (value: boolean) => {
    this.isVerifyModalOpen = value;
  };
  public setStartTestModalOpen = (value: boolean) => {
    this.isStartTestModalOpen = value;
  };
  public setShowBlockedModalOpen = (value: boolean) => {
    this.isShowBlockedModalOpen = value;
  };
  public setDisabledOperationModalOpen = (value: boolean) => {
    this.isDisabledOperationModalOpen = value;
  };
  public setLivenessTimeoutModalOpen = (value: boolean) => {
    this.isLivenessTimeoutModalOpen = value;
  };

  public withVerificationCheck = <T>(f: (v: T) => void, preventDefault?: boolean): ((v: T) => void) => {
    return (e) => {
      if (preventDefault && !!e) {
        (e as React.MouseEvent | undefined)?.preventDefault();
      }

      const user = this.rootStore.user;

      if (user?.user?.isBlocked === true) {
        this.setShowBlockedModalOpen(true);
      } else if (!user.canAccessPlatform) {
        this.setVerifyModalOpen(true);
      } else {
        f(e);
      }
    };
  };

  public async confirmTransactionWithCode(transactionId: string) {
    const promiseRes = new Promise<boolean>((res) => {
      this.codeConfirmOptions = {
        onClose: () => {
          this.codeConfirmOptions = null;
          res(false);
        },
        onConfirm: () => {
          this.codeConfirmOptions = null;
          res(true);
        },
        transactionId,
      };
    });
    return promiseRes;
  }

  public livenessCheck(txId: string) {
    const promiseRes = new Promise<boolean>((res) => {
      this.livenessOptions = {
        txId,
        onClose: (byTimeout?: boolean) => {
          if (byTimeout) {
            this.setLivenessTimeoutModalOpen(true);
          }
          this.livenessOptions = null;
          res(false);
        },
        onConfirm: () => {
          res(true);
        }
      };
    });
    return promiseRes;
  }
}
