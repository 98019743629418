import { CopyButton, Tooltip } from "@mantine/core";
import { ReactComponent as CopyIcon } from "shared/static/icons/copy.svg";
import styles from "./CopyIconButton.module.scss";

type TProps = {
  value: string;
};

export function CopyIconButton(props: TProps) {
  return (
    <CopyButton value={props.value} timeout={600}>
      {({ copied, copy }) => (
        <Tooltip opened={copied} label={"Скопировано"} withArrow position="right">
          <CopyIcon className={styles.icon} onClick={copy}/>
        </Tooltip>
      )}
    </CopyButton>
  );
}
