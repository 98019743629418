import { TCurrency, TUserType } from "shared/definitions";
import { API_URL, axiosInstancePrivate } from "shared/definitions/api";
import { sentryCaptureError } from "shared/utils/sentry";
import { TAccountInfo, TCompanyDetails, TPersonalDetails, TUserTurnoverResponse, UpdateSettingsConfig } from "./types";

export async function fetchAccountInfo() {
  return axiosInstancePrivate.get<TAccountInfo>(`${API_URL}/api/v1/account`);
}

export async function fetchTurnover() {
  const data = await axiosInstancePrivate.get<TUserTurnoverResponse>(`${API_URL}/api/v1/turnover`);
  const res = {
    max: 0,
    amount: 0,
  };
  const parsedAmount = Number.parseFloat(data.data.amount);
  const parsedMax = Number.parseFloat(data.data.max);
  if (Number.isNaN(parsedAmount) || Number.isNaN(parsedMax)) {
    sentryCaptureError("Error while parsing turnover max or amount");
  }
  res.amount = parsedAmount || 0;
  res.max = parsedMax || 0;
  return {
    status: data.status,
    data: res
  };
}

export async function fetchDetails(userType: TUserType) {
  if (userType === "company") {
    return axiosInstancePrivate.get<TCompanyDetails>(`${API_URL}/api/v1/account/details`);
  }
  return axiosInstancePrivate.get<TPersonalDetails>(`${API_URL}/api/v1/account/details`);
}

export async function updateUserCurrency(value: number) {
  return axiosInstancePrivate.post<{ data: TCurrency }>(`${API_URL}/api/v1/account/currency/${value}`);
}

export async function updateUserNotifications(config: UpdateSettingsConfig) {
  return axiosInstancePrivate.post<any>(`${API_URL}/api/v1/account/notification-settings`, config);
}

export async function removeAccount() {
  return axiosInstancePrivate.delete<any>(`${API_URL}/api/v1/account`);
}
