import { TFlowType, TTickerOperation, TTickerOperationType } from "shared/definitions";
import { fetchTickerOperations } from "../api/tickerOperations";
import { sentryCaptureError } from "../sentry";

type TExchangeDescriptor = {
  tickerFrom: string;
  tickerTo: string;
  txType: "exchange";
  flowType: TFlowType.crypto;
};

type TWithdrawDescriptor = {
  tickerFrom: string;
  txType: "withdraw";
  network: string;
  flowType: TFlowType.crypto;
} | {
  tickerFrom: string;
  txType: "withdraw";
  flowType: TFlowType.cash_register | TFlowType.transfer;
};

type TDepositDescriptor = {
  tickerTo: string;
  txType: "deposit";
  network: string;
  flowType: TFlowType.crypto;
} | {
  tickerTo: string;
  txType: "deposit";
  flowType: TFlowType.cash_register | TFlowType.transfer;
};

export type TAnyOperationDescriptor = TExchangeDescriptor | TWithdrawDescriptor | TDepositDescriptor;

export function findOperation(tickerOperations: TTickerOperation[], descriptor: TAnyOperationDescriptor) {
  let res: undefined | TTickerOperation;
  switch (descriptor.txType) {
    case "deposit": {
      res = tickerOperations.find((el) => {
        return (
          el.transactionType === TTickerOperationType.deposit &&
          el.tickerTo === descriptor.tickerTo &&
          ("network" in descriptor ? el.network === descriptor.network : true) &&
          el.flowType === descriptor.flowType
        );
      });
      break;
    }
    case "exchange": {
      res = tickerOperations.find((el) => {
        return el.transactionType === TTickerOperationType.exchange && el.tickerFrom === descriptor.tickerFrom && el.tickerTo === descriptor.tickerTo;
      });
      break;
    }
    case "withdraw": {
      res = tickerOperations.find((el) => {
        return (
          el.transactionType === TTickerOperationType.withdraw &&
          descriptor.tickerFrom === el.tickerFrom &&
          ("network" in descriptor ? el.network === descriptor.network : true) &&
          el.flowType === descriptor.flowType
        );
      });
      break;
    }
    default:
      break;
  }
  return res;
}

export async function checkIfOperationAllowed(descriptor: TAnyOperationDescriptor) {
  try {
    const tickerOpsResponse = await fetchTickerOperations();
    const tickerOperations = tickerOpsResponse.data.items || [];
    const res = findOperation(tickerOperations, descriptor);
    if (res) {
      return res.allowed;
    }
    throw new Error(`Couldn't find operation ${JSON.stringify(descriptor)} in ticker-operations`);
  } catch (e) {
    sentryCaptureError(e);
    return false;
  }
}