import { useMemo, useState } from "react";
import { Menu, Title } from "@mantine/core";
import { observer } from "mobx-react";
import { RouteContext } from "react-router5/dist/types";
import { Route } from "shared/definitions";
import { ReactComponent as DropdownArrow } from "shared/static/icons/dropdownArrow.svg";
import { ReactComponent as Back } from "shared/static/icons/back.svg";
import { ReactComponent as Logo } from "shared/static/icons/logo.svg";
import classNames from "classnames";
import { useStores } from "store/root/provider";
import { Burger } from "components/common/Burger";
import { logout } from "shared/utils/auth";
import router from "../../../../../router";
import styles from "./Header.module.scss";
import { NavBar } from "../NavBar";

type TProps = {
  routeState: RouteContext;
};

type THeaderConfig = {
  header?: string;
  back?: boolean;
};

const getHeaderConfig = (routerState: RouteContext): THeaderConfig => {
  switch (routerState.route.name) {
    case Route.MAIN_SCREEN:
      return {
        header: "Кошелек",
      };
    case Route.EXCHANGE:
      return {
        header: "Обмен",
      };
    case Route.HISTORY:
      return {
        header: "История",
      };
    case Route.ACCOUNT:
      return {
        header: "Аккаунт",
      };
    case Route.SETTINGS:
      return {
        header: "Настройки",
      };
    default:
      return {
        back: true,
      };
  }
};

export const Header = observer((props: TProps) => {
  const { user: userStore } = useStores();
  const headerConfig = getHeaderConfig(props.routeState);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const userNameInitials = useMemo(() => {
    const fullName = userStore?.userDetails?.fullName;
    if ((fullName?.split?.(" ")?.length ?? 0) >= 1) {
      return fullName?.split(" ").map((v) => v[0]);
    }

    return null;
  }, [userStore.userDetails?.fullName]);

  return (
    <div className={styles.root}>
      <div className={styles.mobileHeader}>
        <a
          href={`/${Route.MAIN_SCREEN}`}
          className={styles.logo}
          onClick={(e) => {
            e.preventDefault();
            router.navigate(Route.MAIN_SCREEN);
          }}>
          <Logo className={styles.logo} />
        </a>
        <Burger
          className={styles.burger}
          onClick={() => {
            setIsNavbarOpen((prev) => !prev);
          }}
          opened={isNavbarOpen}
        />
        {isNavbarOpen && (
          <NavBar
            onNavigate={() => {
              setIsNavbarOpen(false);
            }}
            className={styles.navbar}
          />
        )}
      </div>
      <div className={styles.desktopHeader}>
        {headerConfig.back && (
          <span className={styles.backLink} onClick={() => window.history.back()}>
            <Back />
            <Title inline className={styles.pageTitle} order={1}>
              Назад
            </Title>
          </span>
        )}
        {headerConfig.header && (
          <span>
            <Title className={styles.pageTitle} order={1}>
              {headerConfig.header}
            </Title>
          </span>
        )}
        <span className={styles.menuContainer}>
          <Menu onChange={setIsMenuOpen}>
            <Menu.Target>
              <div className={styles.userInfoContainer}>
                <div className={styles.userInfoLeftColumn}>
                  <span className={styles.userInfoName}>{userStore.isVerified && userStore.userDetails?.fullName}</span>

                  <span className={userStore.isVerified ? styles.verified : styles.notVerified}>
                    {userStore.isVerified !== undefined && (userStore.isVerified ? "Верифицирован" : "Не верифицирован")}
                  </span>
                </div>

                <div className={styles.userImageContainer}>
                  {userStore.isVerified !== undefined && (
                    <>
                      {!userStore.isVerified ? (
                        <img className={styles.avatarUnverified} src="/images/empty-avatar.svg" alt="empty logo" />
                      ) : (
                        <div className={styles.avatarVerified}>
                          <span className="user-initials--label">{userNameInitials}</span>
                        </div>
                      )}

                      <img
                        className={styles.userImageBadge}
                        width={16}
                        height={16}
                        src={
                          userStore.isVerified
                            ? "/images/header-verified-badge.svg"
                            : "/images/header-unverified-badge.svg"
                        }
                        alt="verification state icon"
                      />
                    </>
                  )}
                </div>
                <DropdownArrow
                  className={classNames(styles.dropdownArrow, { [styles.dropdownArrowOpen]: isMenuOpen })}
                />
              </div>
            </Menu.Target>

            <Menu.Dropdown className={styles.dropdown}>
              <Menu.Item
                onClick={() => {
                  router.navigate(Route.SETTINGS);
                }}>
                Настройки
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  router.navigate(Route.ACCOUNT);
                }}>
                Аккаунт
              </Menu.Item>

              <div className={styles.dropdownHorizontalDivider} />

              <Menu.Item onClick={() => logout()}>Выйти</Menu.Item>
            </Menu.Dropdown>
          </Menu>
          {/* <div className={styles.divider} />
        <Letter /> */}
        </span>
      </div>
    </div>
  );
});
