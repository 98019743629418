import * as Sentry from "@sentry/react";
import { APP_ENV } from "app-env";

let sentryInitialized = false;
export function initSentry() {
  if (APP_ENV.REACT_APP_SENTRY_DSN) {
    Sentry.init({
      dsn: APP_ENV.REACT_APP_SENTRY_DSN,
      environment: APP_ENV.REACT_APP_SENTRY_ENVIRONMENT,
      integrations: [new Sentry.BrowserTracing({ tracingOrigins: ["*"] }), new Sentry.Replay()],
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
    sentryInitialized = true;
  } else {
    // eslint-disable-next-line no-console
    console.warn("Sentry dsn wasn't provided");
  }
}

type TSentryCaptureErrorFunction = typeof Sentry.captureException;
type TSentryCaptureMessageFunction = typeof Sentry.captureMessage;

export const sentryCaptureError: TSentryCaptureErrorFunction = (...args) => {
  if (sentryInitialized) {
    return Sentry.captureException(...args);
  }
  // eslint-disable-next-line no-console
  console.error("Sentry capture error", ...args);
  return "";
};

export const sentryCaptureMessage: TSentryCaptureMessageFunction = (...args) => {
  if (sentryInitialized) {
    return Sentry.captureMessage(...args);
  }
  // eslint-disable-next-line no-console
  console.log("Sentry capture message", ...args);
  return "";
};

export const sentryCaptureErrorWithExtra: (extra: any) => TSentryCaptureErrorFunction = (extra: any) => {
  return (error: any) => {
    return Sentry.captureException(error, { extra });
  };
};