import { observer } from "mobx-react";
import { VerificationStore } from "store/verificationCompany";
import { Page1 } from "./pages/Page1";
import { Page2 } from "./pages/Page2";
import { Page3 } from "./pages/Page3";
import { Page4 } from "./pages/Page4";
import { Page5 } from "./pages/Page5";
import { Page0 } from "./pages/Page0";

type TProps = {
  store: VerificationStore;
}

export const PageFields = observer((props: TProps) => {
  switch (props.store.page) {
    case 0: {
      return <Page0 store={props.store} />;
    }
    case 1: {
      return <Page1 store={props.store} />;
    }
    case 2: {
      return <Page2 store={props.store} />;
    }
    case 3: {
      return <Page3 store={props.store} />;
    }
    case 4: {
      return <Page4 store={props.store} />;
    }
    case 5: {
      return <Page5 store={props.store} />;
    }
    default:
      return null;
  }
});