import { useMemo } from "react";
import { useStores } from "store/root/provider";
import { VerificationStatePersonal } from "store/user/types";
import { VerificationStore } from "store/verificationPersonal";
import { computed } from "mobx";
import { VerificationStateCard } from "../components/VerificationStateCard";

export const VerificationPersonalCard = () => {
  const { user } = useStores();
  const verificationStore = useMemo(() => {
    return new VerificationStore(user);
  }, [user]);

  const verificationState = computed(() => {
    switch (verificationStore.verificationState) {
      case VerificationStatePersonal.TypeSelection:
      case VerificationStatePersonal.SumSub:
      case VerificationStatePersonal.ManualApprove:
        return "novice";
      case VerificationStatePersonal.FullVerified:
        return "pro";
      default:
        return "novice";
    }
  }).get();

  return <VerificationStateCard verificationState={verificationState} />;
};
