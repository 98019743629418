import { useStores } from "store/root/provider";
import classNames from "classnames";
import { observer } from "mobx-react";
import styles from "./verification.module.scss";
import { TypeSelection } from "./components/TypeSelection";
import { VerificationProgressCard } from "./components/VerificationProgressCard";
import { CompanyVerification } from "./Company";
import { PersonalVerification } from "./Personal";

export const Verification = observer(() => {
  const { user } = useStores();

  if (user.userType === "company") {
    return <CompanyVerification />;
  }

  if (user.userType === "individual") {
    return <PersonalVerification />;
  }

  return (
    <div className={styles.root}>
      <VerificationProgressCard step={0} type={null} />
      <div className={classNames(styles.card, styles.contentCard)}>
        <TypeSelection
          onTypeSelected={async (value) => {
            await user.setUserType(value);
          }}
        />
      </div>
    </div>
  );
});
