import React, { FC } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { Button } from "@mantine/core";
import { Route } from "shared/definitions";
import { useStores } from "store/root/provider";
import { User } from "store/user/user";
import styles from "../Main.module.scss";
import router from "../../../router";

function getMessageText(user: User, needTest: boolean) {
  if (user.isBlocked) {
    return "Обслуживание вашего аккаунта временно приостановлено. Пожалуйста, свяжитесь с технической поддержкой.";
  }
  if (!user.isVerified) {
    return "Пожалуйста, пройдите верификацию для доступа к операциям.";
  }
  if (needTest) {
    return "Пожалуйста, пройдите тестирование для доступа к операциям.";
  }
  return "Пожалуйста, пройдите верификацию для доступа к операциям.";
}

export const UnverifiedUserAlert: FC = observer(() => {
  const { user, modals } = useStores();
  const { setStartTestModalOpen } = modals;
  const needTest = (user.isVerified && user.needTest && !user.userDetails?.isTestCompleted) || false;

  return (
    <div className={classNames(styles.card, styles.warn, styles.verifyContainer)}>
      <span className={styles.verifyContainerText}>{getMessageText(user, needTest)}</span>

      <div className={styles.verifyContainerButtons}>
        {needTest && (
          <Button className={styles.button} onClick={() => setStartTestModalOpen(true)}>
            Пройти тест
          </Button>
        )}

        {!user.isBlocked && !user.isVerified && (
          <a
            href={Route.VERIFICATION}
            onClick={(e) => {
              e.preventDefault();
              router.navigate(Route.VERIFICATION);
            }}>
            <Button className={styles.button}>Пройти верификацию</Button>
          </a>
        )}

        {user.isBlocked && (
          <a href={"mailto:manager@topswap.io"}>
            <Button className={styles.modalButton} radius={8}>
              Связаться
            </Button>
          </a>
        )}
      </div>
    </div>
  );
});
