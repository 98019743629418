export function printHtml(str: string) {
  const a = window.open("", "", "height=500, width=500");
  if (a) {
    a.document.write("<html>");
    a.document.write("<body>");
    a.document.write(str);
    a.document.write("</body></html>");
    a.document.close();
    a.print();
  }
}