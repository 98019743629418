import { HoverCard } from "@mantine/core";
import { ReactElement } from "react";
import styles from "./DisabledWrapper.module.scss";

type TProps = {
  children: ReactElement;
  disabled: boolean;
};

export function DisabledWrapper(props: TProps) {
  if (props.disabled) {
    return (
      <HoverCard withArrow position="top">
        <HoverCard.Target>
          <div>{props.children}</div>
        </HoverCard.Target>
        <HoverCard.Dropdown className={styles.popover}>
          Операция временно
          <br /> недоступна
        </HoverCard.Dropdown>
      </HoverCard>
    );
  }
  return props.children;
}
