import { useMemo } from "react";
import { observer } from "mobx-react";
import { useStores } from "store/root/provider";
import { VerificationStateCompany } from "store/user/types";
import { VerificationStore } from "store/verificationCompany";
import { computed } from "mobx";
import { VerificationStateCard } from "../components/VerificationStateCard";

export const VerificationCompanyCard = observer(() => {
  const { user } = useStores();
  const verificationStore = useMemo(() => {
    return new VerificationStore(user);
  }, [user]);

  const verificationState = computed(() => {
    switch (verificationStore.verificationState) {
      case VerificationStateCompany.TypeSelection:
      case VerificationStateCompany.UploadDocs:
      case VerificationStateCompany.ManualApprove:
        return "novice";
      case VerificationStateCompany.FullVerified:
        return "pro";
      default:
        return "novice";
    }
  }).get();

  return <VerificationStateCard verificationState={verificationState}/>;
});
