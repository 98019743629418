import { RouteContext } from "react-router5/dist/types";
import { ReactElement } from "react";
import { CodeConfirmation } from "components/common/CodeConfirmation";
import { useStores } from "store/root/provider";
import { observer } from "mobx-react";
import { LivenessCheck } from "components/common/LivenessCheck";
import { OperationDisabled } from "components/common/OperationDisabled";
import { LivenessTimeout } from "components/common/LivenessTimeout";
import styles from "./PageLayout.module.scss";
import { NavBar } from "./Components/NavBar";
import { Header } from "./Components/Header/Header";
import { UserLimitationModals } from "./Components/Modals/userLimitationModals";
import { NetworkError } from "./Components/NetworkError";

type TProps = {
  routeState: RouteContext;
  children: ReactElement;
};

export const PageLayout = observer((props: TProps) => {
  const { modals } = useStores();
  return (
    <>
      <div className={styles.root}>
        <NavBar className={styles.navbar} />
        <div className={styles.content}>
          <Header routeState={props.routeState} />
          {props.children}
        </div>
        {modals.codeConfirmOptions && (
          <CodeConfirmation
            onClose={modals.codeConfirmOptions.onClose}
            onConfirmed={modals.codeConfirmOptions.onConfirm}
            transactionId={modals.codeConfirmOptions.transactionId}
          />
        )}
        {modals.livenessOptions && <LivenessCheck {...modals.livenessOptions} />}
        <OperationDisabled />
        <LivenessTimeout />
      </div>

      <NetworkError />
      <UserLimitationModals />
    </>
  );
});
