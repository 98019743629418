import "./shared/static/styles/common.scss";
import { useRoute } from "react-router5";
import { MantineProvider } from "@mantine/core";
import { theme } from "shared/utils/mantine/theme";
import { InitStores } from "store/initStores";
import { RootProvider } from "store/root/provider";
import { Authentication } from "./components/common/Authentication";
import Routes from "./routes";

export default function App(props: any) {
  const { route } = useRoute();
  return (
    <MantineProvider theme={theme} withCSSVariables withGlobalStyles withNormalizeCSS>
      <Authentication>
        <RootProvider>
          <InitStores>
            <Routes router={route !== undefined ? route : {}} {...props} />
          </InitStores>
        </RootProvider>
      </Authentication>
    </MantineProvider>
  );
}
