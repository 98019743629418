import { Wallets } from "store/wallets/wallets";
import { History } from "store/history/history";
import { User } from "store/user/user";
import { Currencies } from "store/currencies/currencies";
import { Modals } from "store/modals/modals";
import { Estimate } from "store/estimate";

export class RootStore {
  public wallets: Wallets;
  public history: History;
  public user: User;
  public currencies: Currencies;
  public modals: Modals;
  public estimate: Estimate;

  constructor() {
    this.user = new User();
    this.wallets = new Wallets();
    this.history = new History();
    this.currencies = new Currencies();
    this.modals = new Modals({ user: this.user });
    this.estimate = new Estimate(this.user, this.currencies);
  }
}
