import { Input, Select, TextInput, InputProps, TextInputProps, SelectProps } from "@mantine/core";
import classNames from "classnames";
import { BaseSelectProps } from "@mantine/core/lib/Select/types";
import { InputWrapperBaseProps } from "@mantine/core/lib/Input/InputWrapper/InputWrapper";
import { ReactNode, useRef, useId } from "react";
import { TCurrencyNoNetwork } from "store/currencies/types";
import styles from "./ExchangeInput.module.scss";
import { RoundCurrencyIcon } from "../RoundCurrencyIcon";
import { SelectItem } from "../SelectItem";

type TWrapperProps = TExchangeInputProps & {
  id: string;
  hasError: boolean;
  selectNode: ReactNode;
  inputNode: ReactNode;
  wrapperProps?: InputWrapperBaseProps;
};

type TExchangeInputProps = InputProps & {
  selectOptions?: TCurrencyNoNetwork[];
  wrapperProps?: InputWrapperBaseProps;
  inputProps?: TextInputProps;
  selectProps?: BaseSelectProps & Pick<SelectProps, "value">;
  onSelect?: (value: string) => void;
  preDefaultCurrency?: string;
  currentCurrency?: string | null;
};

const ExchangeInputWrapper = (props: TWrapperProps) => {
  const { id, selectNode, inputNode, hasError, wrapperProps = {} } = props;
  return (
    <Input.Wrapper id={id} {...wrapperProps}>
      <div
        className={classNames(styles.inputWrapper, {
          [styles.hasError]: hasError,
        })}>
        <div
          className={classNames(styles.inputWrapperAfter, {
            [styles.hasError]: hasError,
          })}>
          {selectNode}
        </div>
        <div>{inputNode}</div>
      </div>
    </Input.Wrapper>
  );
};

export const ExchangeInput = (props: TExchangeInputProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const id = useId();
  const { onSelect, currentCurrency, selectOptions = [], wrapperProps = {}, inputProps = {}, selectProps = {} } = props;

  const hasError = !!props.wrapperProps?.error;

  return (
    <ExchangeInputWrapper
      id={id}
      wrapperProps={wrapperProps}
      hasError={hasError}
      selectNode={
        <div className="w-100 display_flex">
          <Select
            wrapperProps={{
              onClick: (e: any) => {
                if (e.target.classList.length) {
                  if (e.target.classList[0].indexOf("-wrapper") > -1) {
                    // додумать
                    // e.preventDefault();
                    // console.log(e.currentTarget.querySelector("input[type='search']"));
                    // e.currentTarget.querySelector("input[type='search']")?.click();
                    // console.log(33, ref.current);
                  }
                }
              },
            }}
            ref={ref}
            size="md"
            data={selectOptions.map(({ name, ticker }) => ({
              name,
              label: name,
              value: ticker,
            }))}
            placeholder="Выберите валюту"
            maxDropdownHeight={400}
            itemComponent={SelectItem}
            nothingFound="Ничего не найдено"
            value={currentCurrency || ""}
            onChange={(val: string) => onSelect?.(val)}
            className={styles.select}
            classNames={{ input: styles.selectInput, icon: styles.selectIcon, wrapper: styles.selectWrapper }}
            icon={
              currentCurrency ? (
                <>
                  <RoundCurrencyIcon icon={currentCurrency} />
                  <div className={styles.iconContainerValue}>
                    <span>{currentCurrency}</span>
                  </div>
                </>
              ) : null
            }
            filter={(value, item) =>
              item.name!.toLowerCase().includes(value.toLowerCase().trim()) ||
              item.ticker.toLowerCase().includes(value.toLowerCase().trim())
            }
            {...selectProps}
          />
        </div>
      }
      inputNode={
        <div>
          <TextInput
            id={id}
            autoComplete="off"
            size="md"
            type="number"
            placeholder="0"
            classNames={{ rightSection: styles.amountInputRight, input: styles.amountInputField }}
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            className={styles.amountInput}
            {...inputProps}
          />
        </div>
      }
    />
  );
};
