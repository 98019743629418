import { observer } from "mobx-react";
import { useStores } from "store/root/provider";
import { PersonalAccount } from "./Personal";
import { CompanyAccount } from "./Company";

export const Account = observer(() => {
  const { user } = useStores();

  if (user.userType === "company") {
    return <CompanyAccount />;
  }
  return <PersonalAccount />;
});
