import React, { FC, useMemo } from "react";
import { useStores } from "store/root/provider";
import { Divider, Select, Title } from "@mantine/core";
import { THistoryOperationType } from "store/history/types";
import styles from "../History.module.scss";
import { Spacer } from "../../../components/ui/universal/Spacer/Spacer";

const typeFilterOptions = [
  { value: "deposit", label: "Пополнение" },
  { value: "exchange", label: "Обмен" },
  { value: "withdraw", label: "Вывод" },
];

const getTitle = (count: number) => {
  const lastDigit = count % 10;
  switch (lastDigit) {
    case 1:
      return "Операция";
    case 2:
    case 3:
    case 4:
      return "Операции";
    default:
      return "Операций";
  }
};

export const HistoryHeader: FC = () => {
  const {
    history: { isLoaded, historyPageFilters, historyCount, setHistoryPageTypeFilter },
  } = useStores();

  return (
    <>
      <div className={styles.historyHeaderContainer}>
        <Title order={2}>{isLoaded ? `${historyCount} ${getTitle(historyCount!)}` : ""}</Title>
        <div className={styles.historyHeaderFiltersContainer}>
          <Select
            placeholder={"Все типы"}
            value={historyPageFilters.type}
            data={typeFilterOptions}
            onChange={(v: THistoryOperationType | null) => setHistoryPageTypeFilter(v)}
          />
        </div>
      </div>
      <Spacer size={3} />
      <Divider />
    </>
  );
};
