import { Space, Title } from "@mantine/core";
import classNames from "classnames";
import { CurrencyIcon } from "components/common/CurrencyIcon";
import { observer } from "mobx-react";
import { CryptoWithdraw } from "store/cryptoWithdraw/cryptoWithdraw";
import { formatCurrency, formatFiat } from "shared/utils/formatCurrency";
import styles from "./Processing.module.scss";
import { ProcessingTopSection } from "./ProcessingTopSection";
import { ProcessingCentralSection } from "./ProcessingCentralSection";
import { ProcessingFooterSection } from "./ProcessingFooterSection";

type TProps = {
  store: CryptoWithdraw;
};

export const Processing = observer((props: TProps) => {
  return (
    <div className={classNames(styles.card, styles.root)}>
      <div className={styles.mainContent}>
        <ProcessingTopSection store={props.store} />
        <Space h="xl" />
        <div className={styles.iconContainer}>
          <CurrencyIcon className={styles.coinIcon} icon={props.store.currency || ""} />
        </div>
        <Space h={64} />
        <ProcessingCentralSection store={props.store} />
        <Space h="md" />
        <div className={styles.text}>
          Сумма операции ≈{" "}
          {props.store.defaultCurrency &&
            formatFiat(props.store.amountDefaultCurrency, {
              ticker: props.store.defaultCurrency?.ticker,
              displayTicker: true,
            })}{" "}
        </div>
        <Space h="md" />
        <Title order={3}>
          -
          {props.store.currency &&
            props.store.amount &&
            formatCurrency(props.store.amount, {
              ticker: props.store.currency,
              displayTicker: true,
            })}
        </Title>
      </div>
      <ProcessingFooterSection store={props.store} />
    </div>
  );
});
