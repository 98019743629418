import { makeAutoObservable, runInAction } from "mobx";
import { sentryCaptureError } from "shared/utils/sentry";
import { setUserType } from "shared/utils/api/setUserType";
import { TUserType } from "shared/definitions";
import {
  TCompanyDetails,
  TPersonalDetails,
  TAccountInfo,
  TUserTurnover,
  UpdateSettingsConfig,
  VerificationLevel,
} from "./types";
import { fetchAccountInfo, fetchDetails, fetchTurnover, updateUserCurrency, updateUserNotifications } from "./utils";

type TFullInfo = TPersonalDetails | TCompanyDetails;
export class User {
  public user: TAccountInfo | null = null;
  public userFullInfo: TFullInfo | null = null;
  public userTurnover: TUserTurnover | null = null;

  loading: boolean = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public init = () => {
    return this.fetchUserData();
  };

  public async fetchUserData() {
    this.loading = true;
    try {
      const accountInfo = await fetchAccountInfo();
      runInAction(() => {
        this.user = accountInfo.data;
      });
      if (this.user) {
        const result = await Promise.all([fetchDetails(this.user.type || "individual"), fetchTurnover()]);
        runInAction(() => {
          this.userFullInfo = result[0].data || null;
          this.userTurnover = result[1].data || null;
        });
      }
    } catch (e) {
      sentryCaptureError(e, { extra: { error: "Couldn't initialize user store" } });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  public async updateUserCurrency(value: number) {
    this.loading = true;
    return updateUserCurrency(value)
      .then(() => {
        return this.fetchUserData();
      })
      .catch((e) => {
        sentryCaptureError(e, { extra: { error: "Couldn't update user currency" } });
      })
      .finally(() => {
        runInAction(() => {
          this.loading = false;
        });
      });
  }

  public async setUserType(value: TUserType) {
    return setUserType(value)
      .then(() => {
        return this.fetchUserData();
      })
      .catch((e) => {
        sentryCaptureError(e, { extra: { error: "Couldn't set user type" } });
      });
  }

  public async updateUserNotifications(config: UpdateSettingsConfig) {
    this.loading = true;
    return updateUserNotifications(config)
      .then(this.fetchUserData)
      .catch((e) => {
        sentryCaptureError(e, { extra: { error: "Couldn't update user notifications" } });
      })
      .finally(() => {
        runInAction(() => {
          this.loading = false;
        });
      });
  }

  public get userDetails(): TFullInfo {
    if (!this.userFullInfo) {
      // TODO fix usages
      throw new Error("Trying to use user data before initialization");
    }
    return this.userFullInfo;
  }

  public get userType() {
    return this.userFullInfo?.type;
  }

  public get documents() {
    if (this.userDetails.type === "individual")
    {
      return this.userDetails.documents;
    }
    sentryCaptureError("Trying to get documents of company-type user");
    return [];
  }

  public get companyDocs() {
    if (this.userDetails.type === "company") {
      return this.userDetails.documents;
    }
    sentryCaptureError("Trying to use companyDocs getter for personal user type");
    return [];
  }

  public get isVerified() {
    switch (this.userDetails?.verificationLevel) {
      case VerificationLevel.Base:
        return false;
      case VerificationLevel.Level2:
      case VerificationLevel.Level3:
        return true;
      default:
        sentryCaptureError("Couldn't determine verification level", {
          extra: { level: this.userDetails?.verificationLevel },
        });
        return false;
    }
  }

  public get isBlocked() {
    return this.user?.isBlocked || false;
  }

  public handleVerificationStateChange = () => {
    void this.fetchUserData();
  };

  public get address() {
    return this.documents?.[0]?.country;
  }

  public get needTest() {
    const country = this.userDetails?.nationality;
    if (country !== "BLR") {
      return false;
    }
    return true;
  }

  public get canAccessPlatform() {
    return !this.isBlocked && this.isVerified && (!this.needTest || this.isTestPassed);
  }

  public get isTestPassed() {
    return this.userDetails?.isTestCompleted;
  }

  public get isOrganization() {
    return this.userDetails?.type === "company";
  }
}
