import { createContext, FC, ReactElement, useContext, useMemo } from "react";
import { sentryCaptureError } from "shared/utils/sentry";
import { RootStore } from "store/root/root";

const RootContext = createContext<RootStore>({} as RootStore);

export const useStores = () => {
  const rootStore = useContext(RootContext);
  if (!rootStore.currencies) {
    sentryCaptureError("Can't use useStores without RootContext.Provider");
  }
  return rootStore;
};

export const RootProvider: FC<{ children: ReactElement }> = ({ children }) => {
  const store = useMemo(() => new RootStore(), []);
  return <RootContext.Provider value={store}>{children}</RootContext.Provider>;
};
