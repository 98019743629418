import React, { FC } from "react";
import { Text, Title } from "@mantine/core";
import { observer } from "mobx-react";
import { useStores } from "store/root/provider";
import { computed } from "mobx";
import { formatFiat } from "shared/utils/formatCurrency";
import styles from "../Main.module.scss";

export const UserBalance: FC = observer(() => {
  const { wallets, estimate } = useStores();

  const defaultCurrency = estimate.defaultCurrency;
  const balance: string[] = computed(() => {
    let sum = 0;
    let formattedSum = formatFiat(sum);
    if (defaultCurrency) {
      const fiatBalance = wallets.fiatBalances.reduce((prev, el) => {
        const estimated = estimate.estimateToDefault(el.ticker, el.balance);
        return prev + estimated;
      }, 0);
      const cryptoBalance = wallets.cryptoBalances.reduce((prev, el) => {
        const estimated = estimate.estimateToDefault(el.ticker, el.balance);
        return prev + estimated;
      }, 0);
      sum = cryptoBalance + fiatBalance;
      formattedSum = formatFiat(sum, { ticker: defaultCurrency.ticker, displayTicker: true });
    }

    const splittedFormattedSum = formattedSum.split(".");
    return splittedFormattedSum.length < 2
      ? [splittedFormattedSum[0], "00"]
      : [splittedFormattedSum[0], splittedFormattedSum[splittedFormattedSum.length - 1] ?? ""];
  }).get();

  return (
    <div className={styles.balanceContainer}>
      <Text className={styles.balanceHeader} inline={false}>
        Общий баланс:
      </Text>
      <Title className={styles.balance} order={1}>
        ≈ {balance[0]}
        <span className={styles.balanceFractional}>.{balance[1]}</span>&nbsp;
      </Title>
    </div>
  );
});
