import { Button, Title } from "@mantine/core";
import classNames from "classnames";
import { CurrencyIcon } from "components/common/CurrencyIcon";
import { observer } from "mobx-react";
import { CryptoWithdraw } from "store/cryptoWithdraw/cryptoWithdraw";
import { ReactComponent as AlertIcon } from "shared/static/icons/adapted/alert.svg";
import { ReactComponent as HistoryIcon } from "shared/static/icons/history.svg";
import router from "router";
import { Route } from "shared/definitions/routes";
import styles from "./ErrorPage.module.scss";

type TProps = {
  store: CryptoWithdraw;
};

export const ErrorPage = observer((props: TProps) => {
  return (
    <div className={classNames(styles.card, styles.root)}>
      <div className={styles.mainContent}>
        <Title order={3}>Операция не выполнена</Title>
        <p className={styles.text}>Что-то пошло не так. Попробуйте еще раз или напишите нам в тех. поддержку.</p>
        <div className={styles.iconContainer}>
          <CurrencyIcon className={styles.coinIcon} icon={props.store.currency || ""} />
        </div>
        <div className={styles.errorBlock}>
          <AlertIcon className={styles.statusIcon} />
          <p className={styles.textError}>Неизвестная ошибка</p>
          <Button
            className={styles.actionButton}
            size="sm"
            radius={6}
            mb={12}
            variant="filled"
            onClick={props.store.retry}>
            Попробовать снова
          </Button>
        </div>
      </div>
      <div className={styles.footer}>
        <Button
          className={styles.buttonHistory}
          variant="outline"
          size="sm"
          radius={6}
          mb={12}
          onClick={() => {
            router.navigate(Route.HISTORY);
          }}>
          <HistoryIcon className={styles.historyIcon} /> Посмотреть Историю
        </Button>
      </div>
    </div>
  );
});
