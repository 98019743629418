import React, { useEffect } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { useStores } from "store/root/provider";
import { getDisabledCurrencies } from "shared/utils/currencies/findDisabled";
import { ToApprove } from "components/common/ToApprove";
import { useHistoryMode } from "pages/History/utils";
import styles from "./Main.module.scss";
import { History } from "../../components/common/History";
import { Wallets } from "./Components/Wallets";
import { UserBalance } from "./Components/UserBalance";
import { UnverifiedUserAlert } from "./Components/UnverifiedUserAlert";
import { BlockedCurrencies } from "./Components/Wallets/BlockedCurrencies";

export const Main = observer(() => {
  const { history, user, wallets } = useStores();
  const blockedCurrencies = getDisabledCurrencies(wallets.allBalances.map((el) => el.currency));
  const hasBlockedCurrencies = blockedCurrencies.length > 0;
  useHistoryMode(null, null);

  useEffect(() => {
    wallets.startAutoUpdate();
    return () => wallets.stopAutoUpdate();
  }, [wallets]);

  return (
    <div className={styles.root}>
      {user.canAccessPlatform ? <UserBalance /> : <UnverifiedUserAlert />}
      {hasBlockedCurrencies && <BlockedCurrencies blockedCurrencies={blockedCurrencies} />}
      {history.hasPendingApprove && <ToApprove />}
      <div className={styles.mainContainer}>
        <Wallets className={classNames(styles.content)} />
        <History />
      </div>
    </div>
  );
});
