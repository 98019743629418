import { THistoryOperationType } from "store/history/types";

export const getTitle = (mode: THistoryOperationType | null) => {
  switch (mode?.toLowerCase()) {
    case "deposit":
      return "Последние пополнения";
    case "withdraw":
      return "Последние выводы";
    case "exchange":
      return "Последние обмены";
    default:
      return "Последние операции";
  }
};

export const getHumanTransactionType = (type: THistoryOperationType) => {
  switch (type) {
    case "deposit":
      return "Пополнение";
    case "withdraw":
      return "Вывод";
    case "exchange":
      return "Обмен";
    default:
      return "Операция";
  }
};

export const getCurrenciesString = (fromCurrency?: string, toCurrency?: string) =>
  [fromCurrency, toCurrency].filter((v) => v).join(" → ");
