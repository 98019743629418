import { API_URL, axiosInstancePrivate } from "shared/definitions/api";
import { sentryCaptureError } from "shared/utils/sentry";
import { TTransaction } from "shared/definitions";
import { TFetchParams, THistoryItem, THistoryOperationType } from "./types";

export async function fetchTransactionsHistory(config: TFetchParams) {
  return axiosInstancePrivate.get<{ transactions: TTransaction[], count: number }>(`${API_URL}/api/v1/transactions`, {
    params: {
      page_size: config.pageSize,
      page: config.page,
      type: config.type,
      ticker: config.ticker,
    },
  });
}

export const groupHistory = (data: THistoryItem[]) => {
  const map: Record<string, THistoryItem[]> = {};
  data.forEach((el) => {
    const key = new Date(el.createdAt).toDateString();
    if (!map[key]) {
      map[key] = [el];
    } else {
      map[key].push(el);
    }
  });
  return map;
};

export function getOperationType(backendType: string): THistoryOperationType {
  switch (backendType) {
    case "exchange":
      return "exchange";
    case "deposit":
      return "deposit";
    case "withdraw":
      return "withdraw";
    default:
      sentryCaptureError(`Unknown transaction type ${backendType}`);
      return "exchange";
  }
}

export function mapHistoryItem(item: TTransaction): THistoryItem {
  return {
    ...item,
    isFiat: !item?.network,
    needLiveness: item.status === "user_approving",
    operationType: getOperationType(item.type),
  };
}

export function compareDates(a: string, b: string) {
  if (a === b) {
    return 0;
  }
  const dateA = new Date(a);
  const dateB = new Date(b);
  return dateA.getTime() > dateB.getTime() ? 1 : -1;
}
