type TProps = {
  width?: number;
  height?: number;
};

export function GoogleMap({ width = 600, height = 250 }: TProps) {
  return (
    <iframe
      title={"map"}
      src="https://www.google.com/maps/embed?pb=!1m21!1m12!1m3!1d13307.055254318231!2d27.54446822685319!3d53.91187405616601!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m6!3e2!4m0!4m3!3m2!1d53.911981406187905!2d27.55248720011431!5e0!3m2!1sru!2srs!4v1713949903861!5m2!1sru!2srs"
      width={width}
      height={height}
      style={{ border: 0 }}
      allowFullScreen={false}
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    />
  );
}
