import dayjs from "dayjs";
import "dayjs/locale/ru";

dayjs.locale("ru");

export const historyFormatTime = (value: string) =>
  !!`${value}`.length && value !== "–" ? dayjs(value).format("HH:mm") : "–";

export const historyFormatDateTime = (value: string) =>
  !!`${value}`.length && value !== "–" ? dayjs(value).format("DD MMM YYYY, HH:mm") : "–";

export const historyFormatDate = (value: string) =>
  !!`${value}`.length && value !== "–" ? dayjs(value).format("DD MMMM YYYY") : "–";
