function toCamelCase(str: string): string {
  return str.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace("-", "").replace("_", "");
  });
}

export function copyFieldsCamelCase(data: any): any {
  if (typeof data !== "object") {
    return data;
  }
  if (Array.isArray(data)) {
    return data.map((item: any) => copyFieldsCamelCase(item));
  }
  if (typeof data === "object" && data !== null) {
    const result: any = {};
    const keys = Object.keys(data);
    keys.forEach((key) => {
      result[toCamelCase(key)] = copyFieldsCamelCase(data[key]);
    });
    return result;
  }
  return data;
}