import { action, makeAutoObservable, reaction, runInAction } from "mobx";
import { getTransactionInfo } from "shared/utils/api/getTransactionInfo";
import { validateAddress } from "shared/utils/validateAddress";
import { Currencies } from "store/currencies/currencies";
import { Estimate, SystemLimitsCheckResult } from "store/estimate";
import { Modals } from "store/modals/modals";
import { User } from "store/user/user";
import { Wallets } from "store/wallets/wallets";
import { estimateFee } from "shared/utils/api/estimateFee";
import { sentryCaptureError } from "shared/utils/sentry";
import { TFlowType } from "shared/definitions";
import { checkIfOperationAllowed } from "shared/utils/currencies/tickerOperations";
import { TFee, TWithdrawStep } from "./types";
import { createWithdrawRequest, mapStatusToStep } from "./utils";

const TRANSACTION_INFO_INTERVAL = 5000; // ms

export class CryptoWithdraw {
  private _currency: string | null = null;
  private _chain: string | null = null;
  private _address: string = "";
  private _amount: number | null = null;
  private _step: TWithdrawStep = "Edit";
  private _isOwnAccount: boolean = false;
  private systemLimitsStatus: SystemLimitsCheckResult = SystemLimitsCheckResult.OK;
  private insufficientBalanceError: boolean = false;
  private incorrectAddressError: boolean = false;
  private estimatedFee: TFee | null = null;

  private walletStore: Wallets;
  private currenciesStore: Currencies;
  private estimateStore: Estimate;
  private userStore: User;
  private modals: Modals;

  private interval: number | null = null;
  private transactionId: string | null = null;

  private _isConfirmationError: boolean = false;

  public get isOwnAccount(): boolean {
    return this._isOwnAccount;
  }
  public set isOwnAccount(value: boolean) {
    this._isOwnAccount = value;
  }

  public get isConfirmationError(): boolean {
    return this._isConfirmationError;
  }

  public get error() {
    return {
      balance: this.insufficientBalanceError,
      address: this.incorrectAddressError,
      limits: this.systemLimitsStatus,
    };
  }

  public get step(): TWithdrawStep {
    return this._step;
  }

  public set step(value: TWithdrawStep) {
    this._step = value;
  }

  constructor(wallets: Wallets, currencies: Currencies, user: User, modals: Modals, estimate: Estimate) {
    makeAutoObservable(this, {}, { autoBind: true });
    reaction(
      () => this.step,
      action(() => {
        if (this.interval) {
          window.clearInterval(this.interval);
        }
        if (this.step === "Processing") {
          this.interval = window.setInterval(async () => {
            if (this.transactionId) {
              const res = await getTransactionInfo(this.transactionId);
              if (res.status === 200) {
                runInAction(() => {
                  this._step = mapStatusToStep(res.data.status);
                });
              }
            }
          }, TRANSACTION_INFO_INTERVAL);
        }
      }),
    );
    reaction(
      () => [this.chain, this.currency, this.amount, this.address],
      action(() => {
        this.insufficientBalanceError = false;
        if (this.selectedWallet && this.amount !== null) {
          const max = this.selectedWallet.amount;
          if (this.amount > max) {
            this.insufficientBalanceError = true;
          }
          this.systemLimitsStatus = this.estimateStore.checkSystemLimits(
            this.selectedWallet.currency.ticker,
            this.amount,
            "withdraw",
          );
        }
      }),
    );
    reaction(
      () => [this._address, this._currency, this._chain],
      action(() => {
        this.incorrectAddressError = false;
        if (this._address && this._chain && this._currency) {
          const isValidAddress = validateAddress(this._address, { ticker: this._currency, network: this._chain });
          if (!isValidAddress) {
            this.incorrectAddressError = true;
          }
        }
      }),
    );
    reaction(
      () => [this._currency, this.chains],
      action(() => {
        if (!this.chain || this.chains.indexOf(this.chain) === -1) {
          if (this.chains.length) {
            this.setChain(this.chains[0]);
          } else {
            this.setChain("");
          }
        }
      }),
    );
    reaction(
      () => [this.currency, this.amount],
      async () => {
        if (this.currency && this.amount !== null) {
          const fee = await estimateFee({
            amount: `${this.amount}`,
            tickerFrom: this.currency,
          });
          runInAction(() => {
            if (fee) {
              this.estimatedFee = {
                amount: fee.fee,
                ticker: fee.feeTicker,
              };
            } else {
              this.estimatedFee = null;
            }
          });
        }
      },
    );
    this.walletStore = wallets;
    this.currenciesStore = currencies;
    this.estimateStore = estimate;
    this.userStore = user;
    this.modals = modals;
  }

  get address() {
    return this._address;
  }

  set address(value: string) {
    this._address = value;
  }

  get defaultCurrency() {
    const userDetails = this.userStore.userDetails;
    if (userDetails) {
      const id = userDetails.defaultCurrencyId;
      const defaultCurrency = this.currenciesStore.currencyById(id);
      return defaultCurrency;
    }
    return null;
  }

  public setCurrency(value: string) {
    this._currency = value;
  }

  public setChain(value: string) {
    this._chain = value;
  }

  public setAmount(value: number | null) {
    this._amount = value;
  }

  public setMax() {
    if (this.selectedWallet) {
      this.setAmount(this.selectedWallet.amount);
    }
  }

  public setMin() {
    if (this._currency) {
      const { min } = this.estimateStore.getLimitsWithType(this._currency, "withdraw");
      this.setAmount(min);
    }
  }

  public async livenessCheck() {
    if (this.transactionId) {
      const livenessCheck = await this.modals.livenessCheck(this.transactionId);
      if (!livenessCheck) {
        this.step = "Error";
      } else {
        this.step = "Processing";
      }
    }
  }

  public async createTransaction() {
    const wallet = this.selectedWallet;
    if (wallet && wallet.currency.network) {
      const res = await createWithdrawRequest({
        addressTo: this._address,
        amount: `${this.amount}`,
        network: wallet.currency.network,
        ticker: wallet.currency.ticker,
        createdAt: new Date().toISOString(),
      });
      if (res.status === 200) {
        runInAction(() => {
          this.transactionId = res.data.id;
        });
        this.step = "SmsConfirm";
        await this.modals
          .confirmTransactionWithCode(res.data.id)
          .then((confirmed) => {
            if (confirmed) {
              this.step = "Processing";
            } else {
              this.step = "Edit";
            }
          })
          .catch(() => {
            sentryCaptureError(`Couldn't confirm transaction ${res.data.transactionId}`);
            this.step = "Error";
          });
      } else {
        sentryCaptureError("Error while creating transaction");
        this.step = "Error";
      }
    }
  }

  public destroy() {
    if (this.interval) {
      window.clearInterval(this.interval);
    }
  }

  public cancelTransaction() {
    this.step = "Edit";
  }

  public retry() {
    this.step = "Edit";
  }

  get currency() {
    return this._currency;
  }

  get selectedWallet() {
    if (this._chain && this._currency) {
      return this.walletStore.cryptoWalletByCoin(this._currency, this._chain);
    }
    return null;
  }

  get chain() {
    return this._chain;
  }

  get chains() {
    return this.currency ? this.currenciesStore.getChainsByTicker(this.currency) : [];
  }

  get amount() {
    return this._amount;
  }

  get amountDefaultCurrency() {
    if (this.defaultCurrency && this._currency && this.amount !== null) {
      return this.estimateStore.estimateToDefault(this._currency, this.amount);
    }
    return 0;
  }

  get estimatedAmount() {
    if (this.estimatedFee?.amount && this.amount !== null) {
      const estimatedAmount = this.amount;
      if (estimatedAmount < 0) {
        return 0;
      }
      return estimatedAmount;
    }
    return 0;
  }

  get valid() {
    return (
      this._isOwnAccount &&
      this._address.length &&
      !this.error.address &&
      !this.error.balance &&
      this.systemLimitsStatus === SystemLimitsCheckResult.OK
    );
  }

  get estimatedAmountDefaultCurrency() {
    if (this.defaultCurrency && this._currency) {
      return this.estimateStore.estimateToDefault(this._currency, this.estimatedAmount);
    }
    return 0;
  }

  get fee() {
    return this.estimatedFee;
  }
  
  public async checkIfOperationAvailable() {
    if (this.currency && this.chain) {
      return checkIfOperationAllowed({
        tickerFrom: this.currency,
        network: this.chain,
        txType: "withdraw",
        flowType: TFlowType.crypto
      });
    }
    return false;
  }
}
