import { Timeline, Title } from "@mantine/core";
import classNames from "classnames";
import { ReactComponent as CheckIcon } from "shared/static/icons/adapted/check.svg";
import { TUserType } from "shared/definitions";
import styles from "./VerificationProgress.module.scss";

type TUserTypeExtended = TUserType | null;

type TProps = {
  step: 0 | 1 | 2 | 3;
  type: TUserTypeExtended;
};

function getActiveFromStep(step: number) {
  if (step > 2) {
    return 1;
  }
  if (step > 1) {
    return 0;
  }
  return undefined;
}

function getFirstBulletTitle(type: TUserTypeExtended) {
  if (type === "company") {
    return "Юридическое лицо";
  }
  if (type === "individual") {
    return "Физическое лицо";
  }
  return "Выберите тип аккаунта";
}

/**
 * Personal:
 *  0 - select type;
 *  1 - sumsub;
 *  2 - manual approve;
 *  3 - pro;
 * 
 * Company:
 *  0 - select type;
 *  1 - send docs;
 *  2 - manual approve;
 *  3 - pro;
 * 
 * @param props 
 * @returns 
 */
export function VerificationProgress(props: TProps) {
  return (
    <Timeline
      classNames={{ itemBullet: styles.itemBullet, item: styles.timelineItem }}
      color="green.4"
      mt={24}
      bulletSize={40}
      active={getActiveFromStep(props.step)}
      lineWidth={0}>
      <Timeline.Item
        bullet={props.step >= 2 && <CheckIcon color="white" />}
        pl={40}
        title={<Title order={4}>Новичок</Title>}>
        <div className={classNames(styles.itemContent, { [styles.active]: props.step > 0 })}>
          {getFirstBulletTitle(props.type)}
        </div>
      </Timeline.Item>
      <Timeline.Item
        bullet={props.step >= 3 && <CheckIcon color="white" />}
        pl={40}
        title={<Title order={4}>Продвинутый</Title>}>
        <div className={styles.itemContentContainer}>
          <div className={classNames(styles.itemContent, { [styles.active]: props.step >= 2 })}>
            Предоставлены документы
          </div>
        </div>
      </Timeline.Item>
    </Timeline>
  );
}
