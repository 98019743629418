import { ReactElement, useEffect, useState } from "react";
import router from "router";
import { auth, getAccessToken, isTokenExpired, refreshToken, requestAccessToken, saveAccessToken } from "../../../shared/utils/auth";
import { UnexpectedError } from "../UnexpectedError";

type TProps = {
  children: ReactElement;
};

export function Authentication(props: TProps) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [error, setError] = useState<any>("");

  useEffect(() => {
    const params = router.getState().params;
    if (params.code) {
      requestAccessToken(params.code)
        .then((res) => {
          if (!res.accessToken) {
            throw new Error("Couldn't get access token");
          }
          // eslint-disable-next-line no-debugger
          router.replaceHistoryState(router.getState().name, {
            ...router.getState().params,
            code: undefined,
            state: undefined,
            session_state: undefined,
          });
          saveAccessToken(res.accessToken);
          setIsAuthenticated(true);
        })
        .catch(setError);
    } else {
      const token = getAccessToken();
      if (!token) {
        auth();
      } else if (isTokenExpired(token)) {
        refreshToken().then(newToken => {
          if (!newToken) {
            auth();
          } else {
            setIsAuthenticated(true);
          }
        });
      } else {
        setIsAuthenticated(true);
      }
    }
  }, []);

  if (isAuthenticated) {
    return props.children;
  }
  if (error) {
    return <UnexpectedError />;
  }
  return null;
}
