import { Anchor, Button, Title } from "@mantine/core";
import { observer } from "mobx-react";
import { useStores } from "store/root/provider";
import { WarningModal } from "components/common/WarningModal";
import { logout } from "shared/utils/auth";
import styles from "./EditRequestModal.module.scss";

type TProps = {
  open: boolean;
  onClose: () => void;
};

export const EditRequestModal = observer(({ open, onClose }: TProps) => {
  const { user } = useStores();

  return (
    <WarningModal
      mode="warn"
      open={open}
      onClose={!user.isBlocked ? onClose : () => void 0}
      header={
        <>
          <Title order={4}>Доступ ограничен</Title>
          <div className={styles.blockedText}>
            Для изменения персональной информации, пожалуйста, обратитесь в нашу тех. поддержку:
          </div>
          <div className={styles.blockedTextMobile}>Почта для связи</div>
          <Anchor tabIndex={-1} sx={{ fontWeight: "bold" }} href="mailto:manager@topswap.io" target="_blank">
            <span>manager@topswap.io</span>
          </Anchor>
        </>
      }
      footerActions={
        <>
          {!user.isBlocked ? (
            <Button variant="default" radius={8} onClick={onClose}>
              Назад
            </Button>
          ) : (
            <Button variant="default" radius={8} onClick={() => logout()}>
              Выйти
            </Button>
          )}

          <a href={"mailto:manager@topswap.io"}>
            <Button className={styles.modalButton} radius={8}>
              Связаться
            </Button>
          </a>
        </>
      }
    />
  );
});
