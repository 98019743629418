import { makeAutoObservable, reaction, runInAction } from "mobx";
import { TEstimateFeeRequestParams, estimateFee } from "shared/utils/api/estimateFee";
import { getTransactionInfo } from "shared/utils/api/getTransactionInfo";
import { compareArrayItems } from "shared/utils/compareArrays";
import { Currencies } from "store/currencies/currencies";
import { Estimate, SystemLimitsCheckResult } from "store/estimate";
import { User } from "store/user/user";
import { Wallets } from "store/wallets/wallets";
import { sentryCaptureError } from "shared/utils/sentry";
import { TFlowType, TTransaction } from "shared/definitions";
import { Modals } from "store/modals/modals";
import { checkIfOperationAllowed } from "shared/utils/currencies/tickerOperations";
import { TEstimatedFee, TExchangeInfo, TExchangeStep, TInputData } from "./types";
import { getTransactionStatus, sendExchanges } from "./utils";

const TRANSACTION_INFO_INTERVAL = 5000; // ms
export class Exchange {
  fromInfo: TInputData = {
    value: "",
    currency: "",
    estimate: 0,
  };
  toInfo: TInputData = {
    value: "",
    currency: "",
    estimate: 0,
  };
  estimatedFee: TEstimatedFee | null = null;
  currencies: Currencies;
  estimate: Estimate;
  wallets: Wallets;
  user: User;
  modals: Modals;
  private _step: TExchangeStep = "Edit";
  private _errors = {
    insufficientBalance: false,
    systemLimitsStatus: SystemLimitsCheckResult.OK,
  };
  private _isChainEstimated: boolean = false;

  private transactionId: string | null = null;
  private intervalId: number | null = null;
  private _processingTransactionInfo: TTransaction | null = null;

  public get processingTransactionInfo(): TTransaction | null {
    return this._processingTransactionInfo;
  }

  constructor(currencies: Currencies, wallets: Wallets, user: User, estimate: Estimate, modals: Modals) {
    this.currencies = currencies;
    this.estimate = estimate;
    this.wallets = wallets;
    this.user = user;
    this.modals = modals;

    makeAutoObservable(this, undefined, { autoBind: true });

    reaction(
      () => {
        return this.tickersChain;
      },
      this.updateChainState,
      { equals: compareArrayItems },
    );
    reaction(
      () => this.step,
      async () => {
        if (this.intervalId) {
          window.clearInterval(this.intervalId);
        }
        if (this._step === "Processing") {
          this.intervalId = window.setInterval(async () => {
            if (this.transactionId) {
              const res = await getTransactionInfo(this.transactionId);
              if (res.status === 200) {
                runInAction(() => {
                  this._step = getTransactionStatus(res.data);
                  if (this._step === "Finished" || this._step === "Error" || this._step === "Rejected") {
                    this.wallets.reload();
                    if (this.intervalId) {
                      window.clearInterval(this.intervalId);
                    }
                  }
                  this._processingTransactionInfo = res.data;
                });
              }
            }
          }, TRANSACTION_INFO_INTERVAL);
        }
      },
    );
    reaction(() => this.fromInfo.value, this.updateChainState, { delay: 500 });
  }

  public get step(): TExchangeStep {
    return this._step;
  }

  public setToCurrency(value: string) {
    this.toInfo.currency = value;
  }

  public setFromCurrency(value: string) {
    this.fromInfo.currency = value;
  }

  public setFromAmount(value: string) {
    this.fromInfo.value = value;
  }

  public setMin() {
    const { min } = this.estimate.getLimitsWithType(this.fromInfo.currency, "exchange");
    this.fromInfo.value = `${min}`;
  }

  public presetTickers(presetValue: string) {
    if (this.fromInfo.currency || this.toInfo.currency) {
      return;
    }
    if (presetValue) {
      const currencyInfo = this.currencies.allCurrencies.find((el) => el.ticker === presetValue);
      if (currencyInfo) {
        this.fromInfo.currency = presetValue;
      }
    }
    if (!this.fromInfo.currency) {
      const fromCurrency =
        this.fiatCurrencies.find((el) => el.ticker === "BYN")?.ticker ?? this.currencies.fiat[0].ticker;
      this.fromInfo.currency = fromCurrency;
    }
    if (!this.toInfo.currency) {
      if (this.currencies.isFiat(this.fromInfo.currency)) {
        const toCurrency =
          this.cryptoCurrencies.find((el) => el.ticker === "USDT")?.ticker ?? this.currencies.crypto[0].ticker;
        this.toInfo.currency = toCurrency;
      } else {
        const toCurrency =
          this.fiatCurrencies.find((el) => el.ticker === "BYN")?.ticker ?? this.currencies.fiat[0].ticker;
        this.toInfo.currency = toCurrency;
      }
    }
  }

  get defaultCurrency() {
    return this.estimate.defaultCurrency;
  }

  get fromBalance() {
    if (this.fromInfo.currency) {
      return this.wallets.balanceByTicker(this.fromInfo.currency);
    }
    return 0;
  }

  get errors() {
    return this._errors;
  }

  public get isCryptoToFiat() {
    if (this.currencies.isFiat(this.fromInfo.currency)) {
      return false;
    }
    return true;
  }

  get fromCurrencies() {
    if (!this.fromInfo.currency) {
      return [];
    }
    if (this.isCryptoToFiat) {
      return this.cryptoCurrencies;
    }
    return this.fiatCurrencies;
  }

  get toCurrencies() {
    if (!this.fromInfo.currency) {
      return [];
    }
    if (this.isCryptoToFiat) {
      return this.fiatCurrencies;
    }
    return this.cryptoCurrencies;
  }

  get cryptoCurrencies() {
    return this.currencies.cryptoCurrencies;
  }

  get fiatCurrencies() {
    return this.currencies.fiatCurrencies;
  }

  private async updateChainState() {
    this._isChainEstimated = false;
    this.validateAmount();
    if (Number(this.fromInfo.value) > 0) {
      await this.estimateWholeChain();
      this.updateDefaultCurrencyEstimates();
    }
  }

  private async estimateWholeChain() {
    try {
      const estimateFeeParams: TEstimateFeeRequestParams = {
        amount: `${this.fromInfo.value}`,
        tickerFrom: this.fromInfo.currency,
        tickerTo: this.toInfo.currency,
      };
      const firstEstimate = await estimateFee(estimateFeeParams);
      if (firstEstimate) {
        const estimate = {
          amount: `${firstEstimate.fee}`,
          ticker: firstEstimate.feeTicker,
          tickerFrom: this.fromInfo.currency,
          tickerTo: this.toInfo.currency,
        };
        runInAction(() => {
          this.toInfo.value = `${firstEstimate.amountTo}`;
          this.estimatedFee = estimate;
          this._isChainEstimated = true;
        });
      } else {
        sentryCaptureError("Couldn't get fee estimation", { extra: { estimateFeeParams } });
      }
    } catch (e) {
      sentryCaptureError(e);
      this._isChainEstimated = false;
    }
  }

  public updateDefaultCurrencyEstimates() {
    const defaultCurrency = this.defaultCurrency;
    if (defaultCurrency) {
      if (this.fromInfo.currency) {
        this.fromInfo.estimate = this.estimate.estimateToDefault(this.fromInfo.currency, Number(this.fromInfo.value));
      }
      if (this.toInfo.currency) {
        this.toInfo.estimate = this.estimate.estimateToDefault(this.toInfo.currency, Number(this.toInfo.value));
      }
    }
  }

  public async livenessCheck() {
    if (this.transactionId) {
      const livenessCheckResult = await this.modals.livenessCheck(this.transactionId);
      if (!livenessCheckResult) {
        this._step = "Error";
      } else {
        this._step = "Processing";
      }
    }
  }

  public async exchange() {
    this._step = "Processing";
    const exchanges: TExchangeInfo[] = [];
    exchanges.push({
      tickerFrom: this.fromInfo.currency,
      tickerTo: this.toInfo.currency,
    });
    try {
      const res = await sendExchanges({
        amount: Number(this.fromInfo.value),
        createdAt: new Date().toISOString(),
        exchanges,
      });
      if (res.status === 200) {
        this.transactionId = res.data.items[0].id;
      } else {
        sentryCaptureError("Couldn't create transaction(s) for exchange");
        this._step = "Error";
      }
    } catch (e) {
      sentryCaptureError(e);
      this._step = "Error";
    }
  }

  public validateAmount() {
    this._errors.insufficientBalance = false;
    const parsedAmount = Number.parseFloat(this.fromInfo.value);
    if (parsedAmount > 0 && this.fromInfo.currency) {
      const balance = this.wallets.balanceByTicker(this.fromInfo.currency);
      const systemLimitsStatus = this.estimate.checkSystemLimits(this.fromInfo.currency, parsedAmount, "exchange");
      if (balance < Number(this.fromInfo.value)) {
        this._errors.insufficientBalance = true;
      }
      this._errors.systemLimitsStatus = systemLimitsStatus;
    }
    return true;
  }

  get tickersChain() {
    return [this.fromInfo.currency, this.toInfo.currency];
  }

  get valid() {
    return (
      this._errors.systemLimitsStatus === SystemLimitsCheckResult.OK &&
      !this._errors.insufficientBalance &&
      Number(this.fromInfo.value) > 0
    );
  }

  get canExchange() {
    return this.valid && this._isChainEstimated;
  }

  public swap() {
    const temp = { ...this.fromInfo };
    this.fromInfo.currency = this.toInfo.currency;
    this.toInfo.currency = temp.currency;
    this.validateAmount();
  }

  public retry() {
    this._step = "Edit";
  }

  public destroy() {
    if (this.intervalId) {
      window.clearInterval(this.intervalId);
    }
  }
  public async checkIfOperationAvailable() {
    if (this.fromInfo.currency && this.toInfo.currency) {
      return checkIfOperationAllowed({
        tickerFrom: this.fromInfo.currency,
        tickerTo: this.toInfo.currency,
        flowType: TFlowType.crypto,
        txType: "exchange",
      });
    }
    return false;
  }
}
