import { CryptoWithdraw } from "store/cryptoWithdraw/cryptoWithdraw";
import { observer } from "mobx-react";
import { SpinnerLoader } from "components/common/SpinnerLoader";
import { ReactComponent as InfoIcon } from "shared/static/icons/hint-big.svg";
import { ReactComponent as FinishedIcon } from "shared/static/icons/success.svg";
import classNames from "classnames";
import { Button, Center, Container, Space } from "@mantine/core";
import { useStores } from "store/root/provider";
import styles from "./Processing.module.scss";

type TProps = {
  store: CryptoWithdraw;
};

export const ProcessingCentralSection = observer((props: TProps) => {
  const { wallets, history } = useStores();
  switch (props.store.step) {
    case "Processing":
      return <SpinnerLoader />;
    case "Finished":
      return <FinishedIcon className={classNames(styles.statusIcon, styles.finished)} />;
    default:
      return (
        <Container>
          <InfoIcon className={classNames(styles.statusIcon, styles.info)} />
          <Space h="md" />
          <div>
            <Button
              onClick={() => {
                props.store.livenessCheck();
                wallets.reload();
                history.reload();
              }}>
              Подтвердить
            </Button>
          </div>
        </Container>
      );
  }
});
