import { Center, Group, SegmentedControl, Title } from "@mantine/core";
import classNames from "classnames";
import { Limits } from "components/common/Limits";
import { useHistoryMode } from "pages/History/utils";
import { useState } from "react";
import { RouteState } from "react-router5/dist/types";
import { useStores } from "store/root/provider";
import { ToApprove } from "components/common/ToApprove";
import { History } from "../../components/common/History";
import styles from "./DepositFiat.module.scss";
import { Cash } from "./Tabs/Cash";
import { Transfer } from "./Tabs/Transfer";

type TProps = {
  routeState: RouteState;
};

enum FiatFlowType {
  cash = "cash",
  transfer = "transfer",
}

export const DepositFiat = (props: TProps) => {
  useHistoryMode("deposit", "Fiat");
  const { history } = useStores();
  const [type, setType] = useState<FiatFlowType>(FiatFlowType.cash);

  return (
    <div className={styles.root}>
      {history.hasPendingApprove && <ToApprove />}

      <div className={styles.mainContainer}>
        <div className={classNames(styles.card, styles.fiatCard)}>
          <Title order={3}>Пополнить счет</Title>
          <Group position="center">
            <SegmentedControl
              value={type}
              classNames={{ label: styles.segmentSwitchLabel, labelActive: styles.segmentSwitchLabelActive }}
              color="violet"
              onChange={(value: FiatFlowType) => {
                setType(value);
              }}
              data={[
                {
                  value: FiatFlowType.cash,
                  label: <Center>Наличными</Center>,
                },
                {
                  value: FiatFlowType.transfer,
                  label: <Center>Переводом</Center>,
                },
              ]}
            />
          </Group>
          {type === FiatFlowType.cash ? (
            <Cash routeState={props.routeState} />
          ) : (
            <Transfer routeState={props.routeState} />
          )}
        </div>
        <Limits />
        <History />
      </div>
    </div>
  );
};
