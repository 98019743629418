import { useStores } from "store/root/provider";
import { Button, Title, Text } from "@mantine/core";
import { useCallback } from "react";
import { observer } from "mobx-react";
import { WarningModal } from "../WarningModal";

export const LivenessTimeout = observer(() => {
  const { modals } = useStores();
  const closeHandler = useCallback(() => {
    modals.setLivenessTimeoutModalOpen(false);
  }, [modals]);
  return (
    <WarningModal
      mode="warn"
      open={modals.isLivenessTimeoutModalOpen}
      onClose={closeHandler}
      header={<Title order={4}>Время на подтверждение истекло</Title>}
      footerActions={<Button onClick={closeHandler}>Закрыть</Button>}>
      <Text>Пожалуйста, создайте новую операцию</Text>
    </WarningModal>
  );
});
