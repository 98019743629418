import { Button, Modal, Space } from "@mantine/core";
import QRCode from "react-qr-code";
import { CopyIconButton } from "components/common/CopyIconButton";
import styles from "./AddressPopup.module.scss";

type TProps = {
  address: string;
  amountString: string;
  onClose: () => void;
};

export function AddressPopup(props: TProps) {
  return (
    <Modal classNames={{ title: styles.title }} title="Данные для пополнения" centered opened onClose={props.onClose}>
      <div>
        <div className={styles.label}>Сумма пополнения</div>
        <div className={styles.value}>
          {props.amountString} <CopyIconButton value={props.amountString} />
        </div>
        <Space h="sm" />
        <div className={styles.label}>Адрес</div>
        <div className={styles.value}>
          {props.address} <CopyIconButton value={props.address} />
        </div>
        <Space h="md" />
        <div className={styles.qrContainer}>
          <QRCode size={256} className={styles.qr} value={props.address || ""} viewBox="0 0 256 256" />
        </div>
        <div className={styles.footer}>
          <Button onClick={props.onClose}>Закрыть</Button>
        </div>
      </div>
    </Modal>
  );
}
