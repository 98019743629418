import { Navbar, Group } from "@mantine/core";
import { Route } from "shared/definitions";
import { ReactComponent as Logo } from "shared/static/icons/logo.svg";
import { ReactComponent as Wallet } from "shared/static/icons/wallet.svg";
import { ReactComponent as History } from "shared/static/icons/history.svg";
import { ReactComponent as Exchange } from "shared/static/icons/sidebar-exchange-icon.svg";
import { ReactComponent as Help } from "shared/static/icons/help.svg";
import { observer } from "mobx-react";
import classNames from "classnames";
import { useEffect } from "react";
import { SUPPORT_EMAIL, SUPPORT_EMAIL_LINK } from "shared/definitions/constants";
import router from "../../../../../router";
import { useStyles } from "./styles";
import { NavLinks } from "./NavLinks";
import type { INavLink } from "./types";

const navLinks: INavLink[] = [
  { link: Route.MAIN_SCREEN, label: "Кошелек", icon: Wallet, allowOnlyVerifiedUsers: false },
  { link: Route.EXCHANGE, label: "Обмен", icon: Exchange, allowOnlyVerifiedUsers: false },
  { link: Route.HISTORY, label: "История", icon: History, allowOnlyVerifiedUsers: false },
];

type TProps = {
  className?: string;
  onNavigate?: () => void;
};

export const NavBar = observer(({ onNavigate, className }: TProps) => {
  const { classes } = useStyles();
  useEffect(() => {
    const unsub = router.subscribe(() => {
      onNavigate?.();
    });
    return () => {
      if (typeof unsub === "function") {
        unsub();
      } else {
        unsub.unsubscribe();
      }
    };
  }, [onNavigate]);

  return (
    <Navbar color="green" width={{ xs: 224, xl: 256 }} p="md" className={classNames(classes.navbar, className)}>
      <Navbar.Section grow>
        <a
          href={`/${Route.MAIN_SCREEN}`}
          onClick={(e) => {
            e.preventDefault();
            router.navigate(Route.MAIN_SCREEN);
          }}>
          <Group className={classes.header} position="apart">
            <Logo />
          </Group>
        </a>
        <NavLinks links={navLinks} />
      </Navbar.Section>

      <Navbar.Section className={classes.footer}>
        <div className={classes.support}>
          <Help className={classes.linkIcon} />
          <div>
            <span>Тех. поддержка:</span>
            <div>
              <a className={classes.mailToLink} href={SUPPORT_EMAIL_LINK}>
                {SUPPORT_EMAIL}
              </a>
            </div>
          </div>
        </div>
      </Navbar.Section>
    </Navbar>
  );
});
