import { CopyButton, Tooltip } from "@mantine/core";
import styles from "./CopyContent.module.scss";

type TProps = {
  content: string;
};

export function CopyContent(props: TProps) {
  return (
    <CopyButton value={props.content} timeout={600}>
      {({ copied, copy }) => (
        <Tooltip
          opened={copied}
          label={"Скопировано"}
          withArrow>
          <span className={styles.clipboardItem} onClick={copy}>
            {props.content}
          </span>
        </Tooltip>
      )}
    </CopyButton>
  );
}
