import classNames from "classnames";
import { observer } from "mobx-react";
import { ReactComponent as SpinnerIcon } from "shared/static/icons/adapted/spinner.svg";
import styles from "./SpinnerLoader.module.scss";

type TProps = {
  className?: string;
};

export const SpinnerLoader = observer((props: TProps) => {
  return <SpinnerIcon className={classNames(styles.root, styles.rotating, props.className)} />;
});
