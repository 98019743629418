import { autorun, makeAutoObservable } from "mobx";
import { AnyEventName, MessageHandler } from "@sumsub/websdk/types";

import { API_URL, axiosInstancePrivate } from "shared/definitions/api";
import { sentryCaptureError } from "shared/utils/sentry";
import { User } from "store/user/user";
import { VerificationLevel, VerificationStatePersonal } from "store/user/types";
import { TUserType } from "shared/definitions";

const SUMSUB_APPROOVE_REVIEW_STATUS = "GREEN";

const sumSubTypes = {
  applicantStatusChanged: "idCheck.applicantStatus" as AnyEventName,
  applicantSubmitted: "idCheck.onApplicantSubmitted" as AnyEventName,
};

interface SumsubReviewMessage {
  reviewResult?: {
    reviewAnswer: string;
  };
}

export class VerificationStore {
  private user: User;
  private _token: string | null = null;
  private _hasSumsubApproveMessage: boolean = false;

  public get hasSumsubApproveMessage(): boolean {
    return this._hasSumsubApproveMessage;
  }
  private set hasSumsubApproveMessage(value: boolean) {
    this._hasSumsubApproveMessage = value;
  }

  public get token(): string | null {
    return this._token;
  }
  private set token(value: string | null) {
    this._token = value;
  }

  constructor(user: User) {
    this.user = user;
    makeAutoObservable(this, {}, { autoBind: true });
    autorun(() => {
      if (this.user.userDetails?.type && this.verificationState === VerificationStatePersonal.SumSub && !this.token) {
        this.getSumSubToken();
      }
    });
  }

  public get verificationState(): VerificationStatePersonal {
    const details = this.user.userDetails;
    if (!details) {
      return VerificationStatePersonal.TypeSelection;
    }
    if (!details.type) {
      return VerificationStatePersonal.TypeSelection;
    }
    if (details.verificationLevel === VerificationLevel.Base) {
      if (details.isSumsubChecked && details.sumsubRejectType !== "RETRY") {
        return VerificationStatePersonal.ManualApprove;
      }
      return VerificationStatePersonal.SumSub;
    }

    if (details.verificationLevel === VerificationLevel.Level2) {
      return VerificationStatePersonal.FullVerified;
    }

    sentryCaptureError("Couldn't determine verification state", { extra: { details } });
    return VerificationStatePersonal.TypeSelection;
  }

  public get levelContent() {
    if (this.verificationState === VerificationStatePersonal.TypeSelection) {
      return 0;
    }
    if (this.verificationState === VerificationStatePersonal.SumSub) {
      if (this.hasSumsubApproveMessage) {
        return 1;
      }
      return 0;
    }
    if (this.verificationState === VerificationStatePersonal.ManualApprove) {
      return 2;
    }
    if (this.verificationState === VerificationStatePersonal.FullVerified) {
      return 3;
    }
    return 0;
  }

  public getSumSubToken(): Promise<string> {
    return axiosInstancePrivate
      .get<{ token: string }>(`${API_URL}/api/v1/account/sumsub-token`)
      .then((res) => {
        this.token = res.data.token || null;
        if (this.token === null) {
          sentryCaptureError("Couldn't get sumsub token");
          return "";
        }
        return this.token;
      })
      .catch((e) => {
        sentryCaptureError(e, { extra: { error: "Can't retrieve sumsub token" } });
        return "";
      });
  }

  public onSumsubMessage: MessageHandler = (type, payload) => {
    if (type === sumSubTypes.applicantStatusChanged) {
      const sumsubMessage = payload as SumsubReviewMessage;
      if (sumsubMessage.reviewResult?.reviewAnswer !== undefined) {
        this.user.handleVerificationStateChange();
        if (sumsubMessage.reviewResult?.reviewAnswer === SUMSUB_APPROOVE_REVIEW_STATUS) {
          this.hasSumsubApproveMessage = true;
        }
      }
    }
  };

  public updateUserInfo() {
    this.user.handleVerificationStateChange();
  }

  public setUserType(value: TUserType) {
    return this.user.setUserType(value);
  }
}
