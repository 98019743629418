import { CollapseCard } from "components/common/CollapseCard";
import styles from "./DocRequirements.module.scss";

export function DocRequirements() {
  return (
    <CollapseCard title="Требования к загружаемым документам">
      <ul className={styles.docRequirements}>
        <li>цветные фото/сканы</li>
        <li>видимы все 4 угла документа</li>
        <li>применение графических редакторов не допускается</li>
        <li>документы должны быть актуальны и действительны на момент подачи</li>
        <li>одному документу соответствует 1 файл</li>
      </ul>
    </CollapseCard>
  );
}
