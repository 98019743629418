import { observer } from "mobx-react";
import { Timeline, Text, Button } from "@mantine/core";
import { useHistoryMode } from "pages/History/utils";
import { useStores } from "store/root/provider";
import { Exchange as ExchangeStore } from "store/exchange";
import { useEffect, useState } from "react";
import { RouteState } from "react-router5/dist/types";
import { formatCurrency } from "shared/utils/formatCurrency";
import { getExchangeDisabledCurrencies } from "shared/utils/currencies/findDisabled";
import { BlockedCurrencies } from "pages/Main/Components/Wallets/BlockedCurrencies";
import { InfoPopover } from "components/common/InfoPopover";
import { ToApprove } from "components/common/ToApprove";
import { History } from "../../components/common/History";
import { Spacer } from "../../components/ui/universal/Spacer/Spacer";
import StepCounter from "./components/StepCounter";
import FromInput from "./components/FromInput";
import styles from "./Exchange.module.scss";
import ToInput from "./components/ToInput";
import { UnverifiedUserAlert } from "../Main/Components/UnverifiedUserAlert";
import { ProcessScreen } from "./screens/Process/Process";

type TProps = {
  routeState: RouteState;
};

export const Exchange = observer((props: TProps) => {
  const {
    currencies: currenciesStore,
    wallets: walletsStore,
    user: userStore,
    estimate: estimateStore,
    modals,
  } = useStores();
  const [exchangeStore] = useState(
    () => new ExchangeStore(currenciesStore, walletsStore, userStore, estimateStore, modals),
  );
  const blockedCurrencies = getExchangeDisabledCurrencies(currenciesStore.allCurrencies);
  const hasBlockedCurrencies = blockedCurrencies.length > 0;
  useHistoryMode("exchange");

  const {
    user,
    modals: { withVerificationCheck },
    history,
    wallets,
  } = useStores();

  useEffect(() => {
    const preSelectCurrency = props.routeState.route.params.currencyFrom;
    exchangeStore.presetTickers(preSelectCurrency);
  }, [props, exchangeStore]);

  useEffect(() => {
    return () => {
      exchangeStore.destroy();
    };
  }, []);

  if (exchangeStore.step !== "Edit") {
    return (
      <div className={styles.root}>
        <div className={styles.exchangeAndHistoryContainer}>
          <div className={styles.card}>
            <ProcessScreen store={exchangeStore} />
          </div>
          <History />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      {!user.canAccessPlatform && <UnverifiedUserAlert />}
      {hasBlockedCurrencies && <BlockedCurrencies blockedCurrencies={blockedCurrencies} />}
      {history.hasPendingApprove && <ToApprove />}

      <div className={styles.exchangeAndHistoryContainer}>
        <div className={styles.exchangeCard}>
          <div className={styles.exchangeCardContent}>
            <Timeline lineWidth={1} className={styles.timeLineRoot}>
              <Timeline.Item className={styles.timeLineItem} bulletSize={35} bullet={<StepCounter step={1} />}>
                <FromInput store={exchangeStore} />
              </Timeline.Item>
              <Timeline.Item className={styles.timeLineItem} bulletSize={35} bullet={<StepCounter step={2} />}>
                <ToInput store={exchangeStore} />
              </Timeline.Item>
            </Timeline>
          </div>
          <div className={styles.exchangeCardFooter}>
            <Button
              mr={10}
              size="sm"
              radius={6}
              variant="filled"
              className={styles.submit}
              disabled={!exchangeStore.canExchange}
              onClick={withVerificationCheck(async () => {
                const isOperationAllowed = await exchangeStore.checkIfOperationAvailable();
                if (isOperationAllowed) {
                  await exchangeStore.exchange();
                  history.reload();
                  wallets.reload();
                } else {
                  modals.setDisabledOperationModalOpen(true);
                }
              })}>
              Купить
            </Button>
            <span className={styles.spacer}>
              <Spacer size={3} direction="horizontal" />
            </span>

            <Text className="display_flex no_select">
              <div className={styles.fees}>
                {exchangeStore.estimatedFee && (
                  <div className={styles.feeRow}>
                    Комиссия {exchangeStore.estimatedFee.tickerFrom} → {exchangeStore.estimatedFee.tickerTo}:{" "}
                    {formatCurrency(exchangeStore.estimatedFee.amount, {
                      ticker: exchangeStore.estimatedFee.ticker,
                      displayTicker: true,
                    })}
                    <InfoPopover message="Комиссия взимается с отправляемой суммы" />
                  </div>
                )}
              </div>
            </Text>
          </div>
        </div>
        <History />
      </div>
    </div>
  );
});
