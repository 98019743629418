import React, { FC } from "react";
import { observer } from "mobx-react";
import { Route } from "shared/definitions";
import { useStores } from "store/root/provider";
import { FinanceTest } from "../FinanceTest";
import { VerifyDialog } from "../../../../Main/Components/Wallets/modals/VerifyDialog";
import router from "../../../../../router";
import { StartTestModal } from "../../../../Main/Components/Wallets/modals/StartTestModal";
import { Blocked } from "../../../../Main/Components/Wallets/modals/Blocked";

export const UserLimitationModals: FC = observer(() => {
  const { modals } = useStores();

  const {
    isTestModalOpen,
    setTestModalOpen,
    isVerifyModalOpen,
    setVerifyModalOpen,
    isStartTestModalOpen,
    setStartTestModalOpen,
    isShowBlockedModalOpen,
    setShowBlockedModalOpen,
  } = modals;

  return (
    <>
      <FinanceTest open={isTestModalOpen} onClose={() => setTestModalOpen(false)} />

      <VerifyDialog
        onStartTest={() => {
          setTestModalOpen(true);
          setVerifyModalOpen(false);
        }}
        onVerify={() => {
          router.navigate(Route.VERIFICATION);
          setVerifyModalOpen(false);
        }}
        open={isVerifyModalOpen}
        onClose={() => setVerifyModalOpen(false)}
      />

      <StartTestModal
        onStart={() => {
          setStartTestModalOpen(false);
          setTestModalOpen(true);
        }}
        isOpen={isStartTestModalOpen}
        onClose={() => setStartTestModalOpen(false)}
      />

      <Blocked open={isShowBlockedModalOpen} onClose={() => setShowBlockedModalOpen(false)} />
    </>
  );
});
