import { TAddress } from "shared/definitions";

export function getBuildingString(buildingNumber: string, buildingName?: string) {
  if (buildingName) {
    return `д. ${buildingNumber}, корп. ${buildingName}`;
  }
  return `д. ${buildingNumber}`;
}

const CODE_COUNTRY_NAME: Record<string, string> = {
  RUS: "Россия",
  BLR: "Беларусь",
  ARM: "Армения",
  AUT: "Австрийская Республика",
};

export function getAddressString(address: TAddress, isBank?: boolean) {
  const addressArray: string[] = [];
  if (isBank) {
    addressArray.push(
      ...[
        address.postCode,
        address.town,
        address.street,
        getBuildingString(address.buildingNumber, address.buildingName),
      ],
    );
  } else {
    addressArray.push(
      ...[
        address.postCode,
        CODE_COUNTRY_NAME[address.country] || address.country,
        address.town,
        address.street,
        getBuildingString(address.buildingNumber, address.buildingName),
        address.flatNumber ? `кв. ${address.flatNumber}` : "",
      ],
    );
  }
  return addressArray.filter((el) => !!el).join(", ");
}
