import { Loader, Title } from "@mantine/core";
import { ReactElement, useEffect, useState } from "react";
import { timeoutWrapper } from "shared/utils/promiseTimeout";
import { useStores } from "store/root/provider";
import { sentryCaptureError } from "shared/utils/sentry";
import styles from "./InitStores.module.scss";

type TProps = {
  children: ReactElement;
};

const INIT_REQUEST_TIMEOUT = 30000;

export function InitStores(props: TProps) {
  const [inited, setInited] = useState(false);
  const [initializationError, setInitializationError] = useState(false);
  const { wallets, user, currencies } = useStores();

  useEffect(() => {
    const promiseArray = [wallets.init(), user.init(), currencies.init()];
    const arrayWithTimeouts = promiseArray.map((el) => timeoutWrapper(el, INIT_REQUEST_TIMEOUT));
    Promise.all(arrayWithTimeouts)
      .finally(() => {
        setInited(true);
      })
      .catch((e) => {
        sentryCaptureError(e);
        setInitializationError(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!inited) {
    return (
      <div className={styles.root}>
        <Loader />
      </div>
    );
  }
  if (initializationError) {
    return (
      <div className={styles.root}>
        <Title order={5}>Неизвестная ошибка</Title>
      </div>
    );
  }
  return props.children;
}
