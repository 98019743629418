import { sentryCaptureError } from "./sentry";

export type TValidateParams = {
  ticker: string;
  network: string;
};

function mapNetworkToBlockchain(network: string) {
  switch (network) {
    case "BEP20":
    case "ETH":
      return "eth";
    case "TRX":
      return "trx";
    default:
      return null;
  }
}

export function validateAddress(address: string, { network }: TValidateParams): boolean {
  try {
    const blockchain = mapNetworkToBlockchain(network);
    if (!blockchain) {
      sentryCaptureError(`Incorrect chain ${network}, or chain is not supported`);
      return true;
    }
    // @ts-ignore
    return WAValidator.validate(address, blockchain);
  } catch (e) {
    sentryCaptureError(e, {
      extra: { error: "Couldn't validate wallet address. Check if the WAValidator is initialized." },
    });
    return true;
  }
}
