import { Popover } from "@mantine/core";
import { ReactComponent as InfoIcon } from "shared/static/icons/hint.svg";
import { ReactNode } from "react";
import classNames from "classnames";
import styles from "./InfoPopover.module.scss";

type TProps = {
  message: ReactNode;
  iconClassName?: string;
};

export function InfoPopover(props: TProps) {
  return (
    <Popover width={200} position="bottom" withArrow shadow="md">
      <Popover.Target>
        <InfoIcon className={classNames(props.iconClassName, styles.commissionIcon)} />
      </Popover.Target>
      <Popover.Dropdown className={styles.popover}>{props.message}</Popover.Dropdown>
    </Popover>
  );
}
