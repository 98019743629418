import { Text } from "@mantine/core";
import { RouteState } from "react-router5/dist/types";
import { GoogleMap } from "components/common/GoogleMap";
import { TFlowType } from "shared/definitions";
import styles from "../DepositFiat.module.scss";
import { Form } from "./Form";

type TProps = {
  routeState: RouteState;
};

export function Cash(props: TProps) {
  return (
    <>
      <Form fiatFlow={TFlowType.cash_register} routeState={props.routeState} />
      <section className={styles.contactsContainer}>
        <div className={styles.contactsColumn}>
          <Text className={styles.contactsHeader}>Пополните счет в отделении любого<br/> банка или в офисе TopSwap:</Text>
          <Text className={styles.contactsContent}>
            Республика Беларусь, г. Минск,
            <br />
            Проспект Победителей, д. 100, пом. 108
          </Text>
        </div>
        <div className={styles.contactsColumn}>
          <Text className={styles.contactsHeader}>График работы:</Text>
          <Text className={styles.contactsContent}>Пнд - Пт: 09:00 - 18:00</Text>
          <Text className={styles.contactsContent}>Сб - Вс: Выходные</Text>
        </div>
      </section>
      <GoogleMap />
    </>
  );
}
