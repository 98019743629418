import { Title, Space, Flex, Overlay } from "@mantine/core";
import { observer } from "mobx-react";
import { VerificationStore } from "store/verificationCompany";
import { useEffect } from "react";
import { FileInputCard } from "../FileInputCard";
import { DocRequirements } from "./DocRequirements";
import { getFileCardProps } from "../utils";
import { FileCardConfigs, Page1Fields } from "./FileCardConfigs";

type TProps = {
  store: VerificationStore;
};

export const Page1 = observer((props: TProps) => {
  useEffect(() => {
    props.store.setRequiredFields(Page1Fields);
    return () => {
      props.store.setRequiredFields([]);
    };
  }, [props.store]);

  return (
    <>
      <Title order={4}>Документы вашей компании</Title>
      <Space h="md" />
      <DocRequirements />
      <Space h="md" />
      <Flex pos="relative" direction="column" gap="sm">
        { props.store.loading && <Overlay /> }
        {Page1Fields.map((field) => {
          return <FileInputCard key={field} {...FileCardConfigs[field]} {...getFileCardProps(props.store, field)} />;
        })}
      </Flex>
    </>
  );
});
