import { Button, Modal } from "@mantine/core";
import { Spacer } from "components/ui/universal/Spacer/Spacer";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { ReactComponent as Warning } from "shared/static/icons/warning.svg";
import { removeAccount } from "store/user/utils";
import { getUpdatePasswordUrl, logout } from "shared/utils/auth";
import Card from "../components/Card";
import styles from "../Settings.module.scss";

const SecurityCard = observer(() => {
  const [isOpenedRemoveAccountModal, setIsOpenedRemoveAccountModal] = useState(false);

  const [resetPasswordUrl, setResetPasswordUrl] = useState("");
  useEffect(() => {
    getUpdatePasswordUrl().then(setResetPasswordUrl);
  }, []);

  return (
    <Card title="Безопасность">
      <Modal // TODO USE WarningModal with mode="danger"
        centered
        classNames={{ title: styles.modalHeader, modal: styles.modal }}
        opened={isOpenedRemoveAccountModal}
        onClose={() => setIsOpenedRemoveAccountModal(false)}
        title={
          <div>
            <Warning color="red" />
            <span>Вы уверены, что хотите удалить свой аккаунт?</span>
          </div>
        }
        radius={16}>
        <div className={styles.modalContent}>
          Ваш аккаунт будет удален безвозвратно. Вы не сможете отменить это действие.
        </div>
        <Spacer size={6} />
        <Button
          variant="default"
          radius={8}
          onClick={() => {
            setIsOpenedRemoveAccountModal(false);
          }}>
          Отменить
        </Button>
        <Button
          variant="filled"
          color="red"
          radius={8}
          onClick={async () => {
            await removeAccount();
            await logout();
          }}>
          Удалить
        </Button>
      </Modal>

      <Spacer size={6} />

      <a href={resetPasswordUrl ?? "#"}>
        <Button className={styles.button} variant="default">
          Изменить пароль
        </Button>
      </a>
      {/* <Spacer size={6} />
        <Button variant="outline" color="red" onClick={() => setIsOpenedRemoveAccountModal(true)}>
          Удалить аккаунт
        </Button> */}
    </Card>
  );
});

export default SecurityCard;
