import { Loader, Progress, Title } from "@mantine/core";
import classNames from "classnames";
import { observer } from "mobx-react";
import { useStores } from "store/root/provider";
import { useMemo } from "react";
import { formatCurrency } from "shared/utils/formatCurrency";
import { TURNOVER_CURRENCY_TICKER } from "shared/definitions/constants";
import styles from "./Limits.module.scss";

enum LimitState {
  Normal,
  Warn,
  Danger,
}

const COLORS = {
  [LimitState.Normal]: "purpleUI.6",
  [LimitState.Warn]: "orange.6",
  [LimitState.Danger]: "red.6",
};

function getLimitState(percentage: number): LimitState {
  if (percentage >= 100) {
    return LimitState.Danger;
  }
  if (percentage >= 50) {
    return LimitState.Warn;
  }
  return LimitState.Normal;
}

export const Limits = observer(() => {
  const { user } = useStores();

  if (!user.userTurnover) {
    return <div className={classNames(styles.card, styles.root)}>Нет данных о лимитах</div>;
  }

  const max = user.userTurnover.max;
  const amount = user.userTurnover.amount;
  const amountLeft = Math.max(max - amount, 0);
  const exceededAmount = Math.max(amount - max, 0);
  const percentValue = (amount / max) * 100;
  const limitState = getLimitState(percentValue);
  const currency = TURNOVER_CURRENCY_TICKER;
  const color = COLORS[limitState];

  const text = useMemo(() => {
    switch (limitState) {
      case LimitState.Normal:
        return (
          <div>
            Доступно без подтверждения <a className={styles.primary}>источника происхождения средств</a>
          </div>
        );
      case LimitState.Warn:
        return (
          <div>
            При превышении лимита потребуется документ, подтверждающий{" "}
            <a className={styles.warn}>источник происхождения средств</a>.
          </div>
        );
      case LimitState.Danger:
        return (
          <div>
            Подтвердите <a className={styles.danger}>источник происхождения средств</a> для зачисления средств на счет.
          </div>
        );
      default:
        return "";
    }
  }, [limitState]);

  if (!user.userTurnover) {
    return (
      <div className={classNames(styles.card, styles.root)}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={classNames(styles.card, styles.root)}>
      <Title className={styles.title} order={3}>
        Лимит на пополнения
      </Title>
      {text}
      <div className={styles.limitBarContainer}>
        <div className={styles.bottomLabelsContainer}>
          <span>пополнения</span>
          <span>{limitState === LimitState.Danger ? "превышено на" : "осталось"}</span>
        </div>
        <Progress size={12} radius={6} color={color} value={percentValue} />
        <div className={styles.limitAmounts}>
          <span className={styles.amountContainer}>
            <div>{formatCurrency(amount, { ticker: currency, displayTicker: true, noDecimal: true })}</div>
          </span>
          <span
            className={classNames(styles.amountContainer, styles.reverseAlign, styles.maxAmount, {
              [styles.danger]: limitState === LimitState.Danger,
            })}>
            <div>
              {formatCurrency(limitState === LimitState.Danger ? exceededAmount : amountLeft, {
                ticker: currency,
                displayTicker: true,
                noDecimal: true,
              })}
            </div>
          </span>
        </div>
      </div>
    </div>
  );
});
