import { Space, Title } from "@mantine/core";
import classNames from "classnames";
import { observer } from "mobx-react";
import { useEffect, useMemo } from "react";
import { ReactComponent as NoData } from "shared/static/icons/noData.svg";
import { Route } from "shared/definitions";
import { useStores } from "store/root/provider";
import { LinkButton } from "components/ui/LinkButton";
import router from "../../../router";
import styles from "./History.module.scss";
import { getTitle } from "./utils";
import { HistoryTable } from "./HistoryTable";
import { Spacer } from "../../ui/universal/Spacer/Spacer";

export const History = observer(() => {
  const {
    history: { latestHistory, operationMode, isLoaded, startAutoUpdate, stopAutoUpdate },
  } = useStores();

  useEffect(() => {
    startAutoUpdate();
    return () => stopAutoUpdate();
  }, [startAutoUpdate, stopAutoUpdate]);

  const noHistory = useMemo(() => Object.keys(latestHistory ?? {}).length === 0, [latestHistory]);

  return (
    <div className={classNames(styles.card, styles.root)}>
      <Title className={styles.title} order={3}>
        {getTitle(operationMode)}
      </Title>

      <Spacer size={3} />

      <hr />

      {isLoaded && !noHistory && (
        <>
          <HistoryTable history={latestHistory} />
          <Space h="lg" />
          <LinkButton>
            <a
              href={Route.HISTORY}
              onClick={(e) => {
                e.preventDefault();
                router.navigate(Route.HISTORY);
              }}>
              Перейти в Историю
            </a>
          </LinkButton>
        </>
      )}

      {isLoaded && noHistory && (
        <div className={styles.noDataContainer}>
          <NoData className={styles.noDataIcon} />
          <p>Пока нет операций</p>
        </div>
      )}
    </div>
  );
});
