import { TTransaction } from "shared/definitions";
import { API_URL, axiosInstancePrivate } from "shared/definitions/api";
import { TExchangeRequestParams, TExchangeStep } from "./types";

export async function sendExchanges(exchanges: TExchangeRequestParams) {
  return axiosInstancePrivate.post<{ items: TTransaction[] }>(`${API_URL}/api/v1/transactions/exchanges`, exchanges);
}

export function getTransactionStatus(tx: TTransaction): TExchangeStep {
  switch (tx.status) {
    case "completed":
      return "Finished";
    case "new":
    case "sending":
    case "pending":
      return "Processing";
    case "rejected":
      return "Rejected";
    case "user_approving":
      return "Liveness";
    default:
      return "Error";
  }
}