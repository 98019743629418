import { MantineThemeOverride } from "@mantine/core";

const palette: MantineThemeOverride["colors"] = {
  purpleUI: [
    "#F5F3FF", // purple50
    "#EDE9FE", // purple100
    "#DDD6FE", // purple200
    "#C4B5FD", // purple300
    "#A78BFA", // purple400
    "#8B5CF6", // purple500
    "#7C3AED", // purple600
    "#6D28D9", // purple700
    "#5B21B6", // purple800
    "#4C1D95", // purple900
  ],
  gray: [
    "#F9FAFB", // gray50
    "#F3F4F6", // gray100
    "#E5E7EB", // gray200
    "#D1D5DB", // gray300
    "#9CA3AF", // gray400
    "#6B7280", // gray500
    "#4B5563", // gray600
    "#374151", // gray700
    "#1F2937", // gray800
    "#111827", // gray900
  ],
  redUI: [
    "#FEEBED", // red50
    "#FDD7DC", // red100
    "#FBAEB8", // red200
    "#FA8695", // red300
    "#F85D71", // red400
    "#F6354E", // red500
    "#C52A3F", // red600
    "#95202F", // red700
    "#641520", // red800
    "#340B10", // red900
  ],
  greenUI: [
    "#E6FFF2",
    "#CCFFE5",
    "#99FFCC",
    "#66FFB2",
    "#33FF99",
    "#00FF7F",
    "#00CC66",
    "#00994C",
    "#006633",
    "#003319",
  ],
  green: ["#ECFDF5", "#D1FAE5", "#A7F3D0", "#6EE7B7", "#34D399", "#10B981", "#059669", "#047857", "#065F46", "#064E3B"],
  orange: [
    "#FFF7ED",
    "#FFEDD5",
    "#FED7AA",
    "#FDBA74",
    "#FB923C",
    "#F97316",
    "#EA580C",
    "#C2410C",
    "#9A3412",
    "#7C2D12",
  ],
  red: ["#FEF2F2", "#FEE2E2", "#FECACA", "#FCA5A5", "#F87171", "#EF4444", "#DC2626", "#B91C1C", "#991B1B", "#7F1D1D"],
};

export const theme: MantineThemeOverride = {
  fontFamily: "Actay",
  colorScheme: "light",
  primaryShade: 6,
  primaryColor: "purpleUI",
  colors: palette,
  radius: {
    sm: 6,
    xs: 4,
  },
  headings: {
    fontFamily: "Actay Wide",
    sizes: {
      h6: {
        fontSize: "12px"
      }
    }
  },
  components: {
    Button: {
      styles: {
        root: {
          fontFamily: "Actay Wide",
          fontSize: "14px",
          lineHeight: "20px",
        },
      },
    },
    Input: {
      styles: {
        input: {
          color: palette.gray?.[9],
          height: "42px",
          fontSize: "16px",
          fontFamily: "Actay",
          borderColor: palette.gray?.[3],
          boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
        },
        rightSection: {
          fontSize: "16px",
        },
      },
    },
    Select: {
      styles: {
        input: {
          height: "42px",
          fontSize: "14px",
          fontFamily: "Actay",
          borderColor: palette.gray?.[3],
          boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
        },
        item: {
          "&[data-hovered]": {
            backgroundColor: palette.gray?.[0],
          },
          "&[data-selected]": {
            "&, &:hover": {
              backgroundColor: palette.gray?.[1],
              color: "black",
            },
          },
        },
      },
    },
    Title: {
      styles: {
        root: { fontFamily: "Actay Wide" },
      },
    },
    Menu: {
      styles: {
        dropdown: { padding: 0 },
        item: { height: "36px" },
        itemLabel: { color: palette.gray?.[7], fontWeight: 400, fontSize: "14px", lineHeight: "20px" },
      },
    },
    Modal: {
      styles: {
        modal: { padding: "24px", borderRadius: "8px" },
      },
    },
    Anchor: {
      styles: {
        root: {
          fontFamily: "inherit",
        },
      },
    },
    Card: {
      styles: {
        root: {
          borderRadius: "12px",
          boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);"
        }
      }
    },
    SegmentedControl: {
      styles: {
        label: {
          fontFamily: "Actay Wide",
        }
      }
    },
    Pagination: {
      styles: {
        item: {
          fontFamily: "Actay Wide",
        }
      }
    }
  },
};
