import { Button } from "@mantine/core";
import classNames from "classnames";
import router from "router";
import { Route } from "shared/definitions";
import { ReactComponent as ExchangeIcon } from "shared/static/icons/exchange.svg";
import { useStores } from "store/root/provider";
import { DisabledWrapper } from "./modals/DisabledWrapper";
import styles from "./WalletActions.module.scss";

type TProps = {
  ticker: string;
  isFiat: boolean;
  // currency: TCurrency;
};

type TRestrictions = {
  exchange: boolean;
  withdraw: boolean;
  deposit: boolean;
};

export function WalletActions(props: TProps) {
  const { modals } = useStores();
  const availableActions: TRestrictions = {
    exchange: true, // TODO ADD REAL RESTRICTIONS
    withdraw: true, // TODO ADD REAL RESTRICTIONS
    deposit: true, // TODO ADD REAL RESTRICTIONS
  };
  const noAvailableActions = !availableActions.exchange && !availableActions.deposit && !availableActions.withdraw;

  if (noAvailableActions) {
    return (
      <div className={styles.noOptions}>
        Операции временно
        <br /> приостановлены
      </div>
    );
  }

  return (
    <div className={styles.actionsContainer}>
      <DisabledWrapper disabled={!availableActions.deposit}>
        <Button
          disabled={!availableActions.deposit}
          className={classNames(styles.button, styles.depositButton)}
          onClick={modals.withVerificationCheck(() => {
            router.navigate(props.isFiat ? Route.DEPOSIT_FIAT : Route.DEPOSIT_CRYPTO, { currency: props.ticker });
          })}>
          Пополнить
        </Button>
      </DisabledWrapper>
      {
        <DisabledWrapper disabled={!availableActions.exchange}>
          <Button
            disabled={!availableActions.exchange}
            className={classNames(styles.button, styles.exchangeButton)}
            variant="outline"
            color="gray.9"
            onClick={() => {
              router.navigate(Route.EXCHANGE, { currencyFrom: props.ticker });
            }}>
            <ExchangeIcon className={styles.exchangeIcon} />
            <div className={styles.exchangeText}>Обменять</div>
          </Button>
        </DisabledWrapper>
      }
      <DisabledWrapper disabled={!availableActions.withdraw}>
        <Button
          disabled={!availableActions.withdraw}
          className={classNames(styles.button, styles.withdrawButton)}
          variant="outline"
          color="gray.9"
          onClick={modals.withVerificationCheck(() => {
            router.navigate(props.isFiat ? Route.WITHDRAW_FIAT : Route.WITHDRAW_CRYPTO, { currency: props.ticker });
          })}>
          Вывести
        </Button>
      </DisabledWrapper>
    </div>
  );
}
