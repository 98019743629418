import { ReactNode } from "react";
import { Divider, Paper, Space, Title } from "@mantine/core";
import styles from "./Card.module.scss";

export const Card = (props: { title: string; afterTitleContent: ReactNode; children: ReactNode }) => {
  return (
    <Paper
      className={styles.root}
      shadow="xs">
      <div className={styles.cardHeader}>
        <Title className="no_select" order={3}>
          {props.title}
        </Title>
        {props.afterTitleContent}
      </div>
      <Space h={12} />
      <Divider/>
      <Space h={12} />
      <div className={styles.cardContent}>{props.children}</div>
    </Paper>
  );
};
