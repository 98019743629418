export function memoWithTimer<T extends () => any>(f: T, timeout: number) {
  let memo: ReturnType<T>;
  let hasMemo = false;
  return () => {
    if (hasMemo) {
      return memo;
    }
    memo = f();
    hasMemo = true;
    setTimeout(() => {
      hasMemo = false;
    }, timeout);

    return memo;
  };
}