import { Button, Title } from "@mantine/core";
import { WarningModal } from "components/common/WarningModal";
import styles from "./Modals.module.scss";

type TProps = {
  isOpen: boolean;
  onClose: () => void;
  onStart: () => void;
};

export const StartTestModal = ({ onStart, onClose, isOpen }: TProps) => (
  <WarningModal
    open={isOpen}
    onClose={onClose}
    footerClassName={styles.modalFooter}
    header={<Title order={4}>Тестирование</Title>}
    footerActions={
      <>
        <span onClick={onClose} className={styles.laterButton}>Пройти позже</span>
        <Button className={styles.button} onClick={onStart}>
          Начать тестирование
        </Button>
      </>
    }>
    <p>Для полного доступа к платформе необходимо пройти короткое тестирование. Тест займет не более 5 минут.</p>
  </WarningModal>
);
