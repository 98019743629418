interface ICurrencyOptions {
  ticker?: string;
  displayTicker?: boolean;
  isFiat?: boolean;
  noDecimal?: boolean
}

const defaultLocale = "en";
const fiatLocale = defaultLocale;
const cryptoLocale = defaultLocale;

const formatCrypto = (v: number, formatOptions?: ICurrencyOptions) => {
  const params: Intl.NumberFormatOptions = {
    minimumFractionDigits: 0
  };
  params.maximumFractionDigits = formatOptions?.noDecimal ? 0 : 6;
  return new Intl.NumberFormat(cryptoLocale, params).format(v);
};

export const formatFiat = (v: number, formatOptions?: ICurrencyOptions) => {
  const currencyOptions: Intl.NumberFormatOptions =
    !formatOptions || !formatOptions.ticker || !formatOptions.displayTicker
      ? {}
      : { style: "currency", currencyDisplay: "narrowSymbol", currency: formatOptions.ticker };
  currencyOptions.maximumFractionDigits = formatOptions?.noDecimal ? 0 : 2;
  const formatter = new Intl.NumberFormat(fiatLocale, {
    minimumFractionDigits: 0,
    ...currencyOptions,
  });
  return formatter.format(v).replace("BYN", "Br"); // BYN has it's own symbol (Br), but chrome doesn't use it for some reason
};

const getIsFiat = (currencyTicker: string) => {
  try {
    Intl.NumberFormat(undefined, { currency: currencyTicker });
    return true;
  } catch {
    return false;
  }
};

export const formatCurrency = (n: number | string, currencyOptions?: ICurrencyOptions) => {
  const isFiat = currencyOptions?.isFiat ?? getIsFiat(currencyOptions?.ticker ?? "");

  if (isFiat) {
    return formatFiat(+n, currencyOptions);
  }

  return (
    `${formatCrypto(+n, currencyOptions)}` +
    (currencyOptions?.displayTicker && currencyOptions?.ticker !== undefined ? ` ${currencyOptions?.ticker}` : "")
  );
};
