import { Center, Button } from "@mantine/core";
import classNames from "classnames";
import { Route } from "shared/definitions";
import { Exchange } from "store/exchange";
import { useStores } from "store/root/provider";
import styles from "./ProcessScreen.module.scss";
import { ReactComponent as ClockIcon } from "../../../../shared/static/icons/adapted/clock.svg";
import { ReactComponent as RefreshIcon } from "../../../../shared/static/icons/adapted/refresh.svg";
import router from "../../../../router";

type TProps = {
  store: Exchange;
};

export const ProcessBottomSection = ({ store }: TProps) => {
  const { history } = useStores();
  switch (store.step) {
    case "Processing":
    case "Edit":
      return null;
    case "Rejected":
    case "Error":
      return (
        <Center>
          <Button className={styles.actionButton} size="sm" radius={6} mb={12} variant="filled" onClick={store.retry}>
            Попробовать снова
          </Button>
        </Center>
      );
    case "Finished":
      return (
        <div style={{ display: "block" }}>
          <div>
            <Center>
              <Button
                className={styles.actionButton}
                size="sm"
                radius={6}
                mb={12}
                leftIcon={<ClockIcon className={styles.buttonIcon} />}
                variant="default"
                onClick={(e: { preventDefault: () => void }) => {
                  e.preventDefault();
                  router.navigate(Route.HISTORY);
                }}>
                Посмотреть историю
              </Button>
            </Center>
          </div>
          <div>
            <Center>
              <Button
                className={styles.actionButton}
                size="sm"
                radius={6}
                mb={12}
                leftIcon={<RefreshIcon className={classNames(styles.buttonIcon, styles.dark)} />}
                variant="filled"
                onClick={store.retry}>
                Новый обмен
              </Button>
            </Center>
          </div>
        </div>
      );
    default:
      return null;
  }
};
