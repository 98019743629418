import { ComponentPropsWithoutRef, forwardRef } from "react";
import { Group, Text } from "@mantine/core";
import { RoundCurrencyIcon } from "../RoundCurrencyIcon";

interface ItemProps extends ComponentPropsWithoutRef<"div"> {
  label: string;
  value: string;
}

export const SelectItem = forwardRef<HTMLDivElement, ItemProps>(({ label, value, ...others }: ItemProps, ref) => (
  <div ref={ref} {...others} style={{ borderRadius: "6px", padding: "8px 12px" }}>
    <Group noWrap>
      <RoundCurrencyIcon icon={value.toLowerCase()} />
      <div>
        <Text size="sm">{label}</Text>
      </div>
    </Group>
  </div>
));
