import { Button, Title } from "@mantine/core";
import { WarningModal } from "components/common/WarningModal";
import router from "router";
import { Route } from "shared/definitions/routes";
import { ReactComponent as Success } from "shared/static/icons/success.svg";
import styles from "./SuccessModal.module.scss";

type TProps = {
  onClose: () => void;
};

export const SuccessModal = (props: TProps) => {
  return (
    <WarningModal
      modalClassName={styles.modal}
      open
      centeredHeader
      onClose={props.onClose}
      header={<div className={styles.header}>
        <Success className={styles.success} />
      </div>}
      footerActions={
        <>
          <a
            href={Route.HISTORY}
            onClick={(e) => {
              e.preventDefault();
              router.navigate(Route.HISTORY);
            }}>
            <Button variant="default" className={styles.finishScreenButton}>Открыть историю</Button>
          </a>
          <Button onClick={props.onClose} className={styles.finishScreenButton}>
            Закрыть
          </Button>
        </>
      }>
      <div className={styles.content}>
        <Title className={styles.title}>
          Заявка на вывод средств,
          <br />
          успешно создана
        </Title>
        <p className={styles.text}>Отслеживайте статус операции в Истории.</p>
      </div>
    </WarningModal>
  );
};
