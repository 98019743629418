import { Button, Title } from "@mantine/core";
import { WarningModal } from "components/common/WarningModal";
import { ReactComponent as Close } from "shared/static/icons/adapted/close.svg";
import styles from "./FailedModal.module.scss";

type TProps = {
  onClose: () => void;
};

export const FailedModal = (props: TProps) => {
  return (
    <WarningModal
      modalClassName={styles.modal}
      open
      centeredHeader
      onClose={props.onClose}
      header={
        <div className={styles.header}>
          <Close className={styles.failIcon} />
        </div>
      }
      footerActions={
        <Button onClick={props.onClose} className={styles.finishScreenButton}>
          Закрыть
        </Button>
      }>
      <div className={styles.content}>
        <Title className={styles.title}>
          Мы не смогли создать <br />
          заявку на вывод средств.
        </Title>
        <p className={styles.text}>Попробуйте позже или обратитесь в тех. поддержку.</p>
      </div>
    </WarningModal>
  );
};
