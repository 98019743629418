import { useCallback, useState } from "react";
import { Grid } from "@mantine/core";
import { observer } from "mobx-react";
import { useStores } from "store/root/provider";
import { CopyContent } from "components/common/CopyContent";
import styles from "./CompanyAccount.module.scss";
import { Spacer } from "../../../components/ui/universal/Spacer/Spacer";
import { VerificationCompanyCard } from "./VerificationCompanyCard";
import { EditButton } from "../components/EditButton";
import { Card } from "../components/Card";
import { Field } from "../components/Field";
import { EditRequestModal } from "../components/EditRequestModal";

export const CompanyAccount = observer(() => {
  const { user } = useStores();
  const [isModalShown, setIsModalShown] = useState(false);

  const editButtonHandler = useCallback(() => setIsModalShown(true), []);

  return (
    <div className={styles.root}>
      <EditRequestModal open={isModalShown} onClose={() => setIsModalShown(false)} />
      <Grid>
        <Grid.Col xs={12} md={6} lg={4}>
          <Card title="Персональная информация" afterTitleContent={<EditButton onClick={editButtonHandler} />}>
            {user.userDetails && (
              <>
                <div>
                  <Field
                    title="ID"
                    info={
                      user.userDetails.publicId ? (
                        <CopyContent content={user.userDetails.publicId?.toString() || ""} />
                      ) : (
                        "Нет данных"
                      )
                    }
                  />
                </div>
                {user.userDetails?.fullName && (
                  <>
                    <Spacer size={3} />
                    <div>
                      <Field title={"Полное наименование компании"} info={user.userDetails?.fullName || "Нет данных"} />
                    </div>
                  </>
                )}
              </>
            )}
          </Card>
        </Grid.Col>

        <Grid.Col xs={12} md={6} lg={4}>
          <Card title="Контактная информация" afterTitleContent={<EditButton onClick={editButtonHandler} />}>
            {user.userDetails?.email && (
              <div>
                <Field title="Email для связи" info={user.userDetails?.email || "Нет данных"} />
              </div>
            )}
          </Card>
        </Grid.Col>

        <Grid.Col xs={12} md={6} lg={4}>
          <VerificationCompanyCard />
        </Grid.Col>
      </Grid>
    </div>
  );
});
