import { Title } from "@mantine/core";
import { ReactNode } from "react";
import classNames from "classnames";
import styles from "./LevelContentWrapper.module.scss";

type TProps = {
  title: ReactNode;
  children: ReactNode;
  fullWidth?: boolean;
};

export function LevelContentWrapper({ title, children, fullWidth }: TProps) {
  return (
    <div className={classNames(styles.root, { [styles.fullWidth]: fullWidth })}>
      <div className={classNames(styles.levelDescriptionContainer, { [styles.fullWidth]: fullWidth })}>
        <Title>{title}</Title>
        {children}
      </div>
    </div>
  );
}
