import classNames from "classnames";
import { observer } from "mobx-react";
import { FinanceTest } from "../../../../../store/financeTest";
import styles from "./ModalContent.module.scss";

type TProps = {
  store: FinanceTest;
};

export const QuestionNav = observer(({ store }: TProps) => {
  const { validationResult, withResults, activeQuestionIndex, answers } = store;
  if (withResults && validationResult) {
    return (
      <div className={styles.questionNavContainer}>
        {store.questions.map((el, key) => (
          <div
            key={key}
            className={classNames(styles.questionNavItem, {
              [styles.incorrect]: !validationResult[key],
              [styles.correct]: validationResult[key],
              [styles.active]: key === activeQuestionIndex,
            })}
          />
        ))}
      </div>
    );
  }
  return (
    <div className={styles.questionNavContainer}>
      {store.questions.map((el, key) => (
        <div
          key={key}
          className={classNames(styles.questionNavItem, {
            [styles.active]: key === activeQuestionIndex,
            [styles.answered]: typeof answers[key] === "number",
          })}
        />
      ))}
    </div>
  );
});
