import classNames from "classnames";
import { observer } from "mobx-react";
import { useStores } from "store/root/provider";
import { useMemo } from "react";
import { VerificationStateCompany } from "store/user/types";
import { VerificationStore } from "store/verificationCompany";
import styles from "./CompanyVerification.module.scss";
import { Level2 } from "../components/LevelDescription";
import { VerificationProgressCard } from "../components/VerificationProgressCard";
import { DocumentsVerification } from "./DocumentsVerification";

export const CompanyVerification = observer(() => {
  const { user } = useStores();
  const store = useMemo(() => {
    const res = new VerificationStore(user);
    res.init();
    return res;
  }, [user]);

  return (
    <div className={styles.root}>
      <VerificationProgressCard step={store.levelContent} type="company" />
      <div className={classNames(styles.card, styles.contentCard)}>
        {store.verificationState === VerificationStateCompany.UploadDocs && <DocumentsVerification store={store} />}
        {store.verificationState === VerificationStateCompany.ManualApprove && <Level2 />}
        {store.verificationState === VerificationStateCompany.FullVerified && <Level2 />}
      </div>
    </div>
  );
});
