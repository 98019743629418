import { FC, Fragment } from "react";
import { THistoryItem } from "store/history/types";
import { observer } from "mobx-react";
import classNames from "classnames";
import { historyFormatDate } from "shared/utils";
import { ReactComponent as InfoIcon } from "shared/static/icons/hint.svg";
import { formatCurrency } from "shared/utils/formatCurrency";
import { HoverCard } from "@mantine/core";
import { LinkButton } from "components/ui/LinkButton";
import { useStores } from "store/root/provider";
import styles from "./History.module.scss";
import { getCurrenciesString, getHumanTransactionType } from "./utils";

const ItemRightSection = observer((props: { item: THistoryItem }) => {
  const { history, modals, wallets } = useStores();
  const hasIssues = props.item.status === "rejected" || props.item.status === "hold";

  if (props.item.status === "user_approving") {
    return (
      <LinkButton
        onClick={async () => {
          await modals.livenessCheck(props.item.id);
          await history.reload();
          await wallets.reload();
        }}
        className={styles.approveButton}>
        Подтвердить
      </LinkButton>
    );
  }

  return (
    <span
      className={classNames({
        [styles.amountDeposit]: !hasIssues && props.item.operationType === "deposit",
        [styles.amountExchange]: !hasIssues && props.item.operationType === "exchange",
        [styles.amountError]: hasIssues,
      })}>
      {props.item.operationType === "withdraw" &&
        `-${formatCurrency(props.item.amountTo, { ticker: props.item.tickerFrom, displayTicker: true })}`}

      {props.item.operationType === "deposit" &&
        `${formatCurrency(props.item.amountTo, { ticker: props.item.tickerTo, displayTicker: true })}`}

      {props.item.operationType === "exchange" &&
        `${formatCurrency(props.item.amountTo, { ticker: props.item.tickerTo, displayTicker: true })}`}

      {hasIssues && (
        <HoverCard withArrow position="top">
          <HoverCard.Target>
            <span>
              {" "}
              <InfoIcon className={styles.hintIcon} />
            </span>
          </HoverCard.Target>
          <HoverCard.Dropdown className={styles.popover}>
            {props.item.status === "hold" && "Операция приостановлена"}
            {props.item.status === "rejected" && "Операция не выполнена"}
          </HoverCard.Dropdown>
        </HoverCard>
      )}
    </span>
  );
});

export const HistoryTable: FC<{ history: Record<string, THistoryItem[]> }> = observer(({ history }) => {
  return (
    <ul className={styles.historyList}>
      {Object.entries(history).map(([groupKey, items]) => (
        <Fragment key={groupKey}>
          <li className={classNames(styles.historyItem, styles.historyDateItem, styles.greyedText)}>
            {historyFormatDate(items?.[0]?.createdAt)}
          </li>

          <ol key={groupKey} className={styles.historySubList}>
            {items.map((el) => {
              return (
                <li key={el.id} className={styles.historyItem}>
                  <span>
                    {getHumanTransactionType(el.operationType)}{" "}
                    <span className={styles.greyedText}>{getCurrenciesString(el.tickerFrom, el.tickerTo)}</span>
                  </span>
                  &nbsp;
                  <ItemRightSection item={el} />
                </li>
              );
            })}
          </ol>
        </Fragment>
      ))}
    </ul>
  );
});
