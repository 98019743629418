import { Button, Title, Text, Container, Select, Space, Loader, Flex } from "@mantine/core";
import { observer } from "mobx-react";
import { useEffect, useMemo } from "react";
import { LivenessCheck as LivenessCheckStore } from "store/livenessCheck";
import { SumsubWrapper } from "pages/Verification/components/SumsubWrapper";
import { useStores } from "store/root/provider";
import { WarningModal } from "../WarningModal";
import styles from "./LivenessCheck.module.scss";

type TProps = {
  txId: string;
  onClose: (byTimeout?: boolean) => void;
  onConfirm: () => void;
};

export const LivenessCheck = observer(({ txId, onClose, onConfirm }: TProps) => {
  const { modals, user } = useStores();
  const store = useMemo(() => {
    return new LivenessCheckStore(txId, () => onClose(true), modals, user);
  }, [modals, txId, user, onClose]);

  const userSelectOptions = store.responsibleUsers?.map((el) => {
    return { label: `${el.fullName || "–"}, ${el.position || "–"}`, value: el.id };
  });

  useEffect(() => {
    return () => {
      store.destroy();
    };
  }, [store]);

  useEffect(() => {
    if (store.step === "liveness") {
      store.initSumsub().then((res) => {
        if (!res) {
          onClose();
        }
      });
    } else if (store.step === "select" && store.isCompany) {
      store.initResponsible().then((res) => {
        if (!res) {
          onClose();
        }
      });
    }
  }, [store.step, store.isCompany, onClose, store]);

  if (store.step === "select") {
    return (
      <WarningModal
        modalClassName={styles.modalRoot}
        open
        onClose={onClose}
        header={<Title order={4}>Подтвердите операцию</Title>}
        footerActions={
          <>
            <Button onClick={() => onClose()} variant="outline" color="gray.9">
              Отмена
            </Button>
            <Button disabled={!userSelectOptions?.length || !store.selectedUser} onClick={store.onUserSelected}>
              Продолжить
            </Button>
          </>
        }>
        <Container>
          <Text>
            Пройдите liveness проверку для
            <br />
            подтверждения операции.
          </Text>
          <Space h="lg" />
          {userSelectOptions ? (
            <Select
              value={store.selectedUser}
              classNames={{ label: styles.label }}
              label="Cотрудник"
              placeholder={userSelectOptions?.length ? "Выберите сотрудника" : "Нет сотрудников"}
              data={userSelectOptions}
              onChange={(value) => {
                store.selectedUser = value || "";
              }}
            />
          ) : (
            <Flex justify="center">
              <Loader />
            </Flex>
          )}
        </Container>
      </WarningModal>
    );
  }

  return (
    <WarningModal
      modalClassName={styles.modalRoot}
      open
      onClose={() => onClose()}
      footerActions={
        store.checkCompleted && (
          <Flex justify="flex-end">
            <Button onClick={() => onClose()} variant="default">
              Закрыть
            </Button>
          </Flex>
        )
      }
      header={<Title order={4}>Подтвердите операцию</Title>}>
      {store.sumsubToken ? (
        <SumsubWrapper
          onMessage={(messageType: string) => {
            if (messageType === "idCheck.livenessCompleted") {
              onConfirm();
              store.checkCompleted = true;
            }
          }}
          accessToken={store.sumsubToken}
          expirationHandler={() => {
            throw new Error("Function not implemented.");
          }}
        />
      ) : (
        <Flex justify="center">
          <Loader />
        </Flex>
      )}
    </WarningModal>
  );
});
