import { makeAutoObservable, reaction, runInAction } from "mobx";
import { sentryCaptureError } from "shared/utils/sentry";
import { THistoryCurrencyType, THistoryItem, THistoryOperationType, THistoryPageFilters } from "./types";
import { fetchTransactionsHistory, groupHistory, mapHistoryItem } from "./utils";

const PAGE_SIZE = 10;
const FIRST_PAGE = 1;
const AUTOUPDATE_INTERVAL = 15000; // ms
export class History {
  private data: THistoryItem[] = [];
  private _operationMode: THistoryOperationType | null;
  private _currencyMode: THistoryCurrencyType | null;
  private _page: number = FIRST_PAGE;
  private _lastPage: number = FIRST_PAGE;
  private _allHistoryCount: number = 0;
  private _isLoaded = false;
  private autoUpdateInterval: number | null = null;
  private _isLoadingMore = false;

  public get lastPage(): number {
    return this._lastPage;
  }
  public set lastPage(value: number) {
    this._lastPage = value;
  }
  public get page(): number {
    return this._page;
  }
  public set page(value: number) {
    this._page = value;
  }

  public historyPageFilters: THistoryPageFilters = {
    type: null,
    ticker: null,
  };

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    this._operationMode = null;
    this._currencyMode = null;

    reaction(() => [this.page], this.reload, {
      fireImmediately: true
    });
  }

  public async reload() {
    const filterParams = Object.fromEntries(Object.entries(this.historyPageFilters).filter(([, v]) => v !== null));

    await fetchTransactionsHistory({ page: this.page, pageSize: PAGE_SIZE, ...filterParams }).then(({ data }) => {
      runInAction(() => {
        this.data = data.transactions.map(mapHistoryItem);
        this.lastPage = Math.ceil(data.count / PAGE_SIZE);
        this._allHistoryCount = data.count;
        this._isLoaded = true;
      });
    }).catch(sentryCaptureError);
  }

  get latestHistory() {
    let filtered: THistoryItem[];

    if (this._operationMode === null && this._currencyMode === null) {
      filtered = this.data.slice();
    } else {
      filtered = this.data.filter((el) => {
        let matchOperationType = true;
        let matchCurrency = true;

        if (this._operationMode !== null) {
          matchOperationType = this._operationMode === el.operationType;
        }

        if (this._currencyMode !== null) {
          matchCurrency =
            (el.isFiat && this._currencyMode === "Fiat") || (!el.isFiat && this._currencyMode === "Crypto");
        }

        return matchOperationType && matchCurrency;
      });
    }
    return groupHistory(
      filtered
        .sort((a, b) => {
          return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        })
        .slice(0, 6),
    );
  }

  get rawData() {
    return this.data;
  }

  get canLoadMore() {
    return this.page < this.lastPage;
  }

  get groupedHistory() {
    return groupHistory(
      this.data.slice().sort((a, b) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      }),
    );
  }

  get isLoaded() {
    return this._isLoaded;
  }

  get isLoadingMore() {
    return this._isLoadingMore;
  }

  get operationMode() {
    return this._operationMode;
  }

  set operationMode(value: THistoryOperationType | null) {
    this._operationMode = value;
  }

  get historyCount() {
    return this._allHistoryCount;
  }

  set currencyMode(value: THistoryCurrencyType | null) {
    this._currencyMode = value;
  }

  get hasPendingApprove() {
    return !!this.data.find(el => el.needLiveness);
  }

  setHistoryPageTypeFilter = (type: THistoryOperationType | null) => {
    const nextType = type === this.historyPageFilters.type ? null : type;
    this.historyPageFilters = { ...this.historyPageFilters, type: nextType };
  };

  setHistoryPageTickerFilter = (ticker: string | null) => {
    const nextTicker = ticker === this.historyPageFilters.ticker ? null : ticker;
    this.historyPageFilters = { ...this.historyPageFilters, ticker: nextTicker };
  };

  public startAutoUpdate() {
    if (this.autoUpdateInterval !== null) {
      sentryCaptureError("Trying to create more than one auto update interval in history");
      this.stopAutoUpdate();
    }
    this.autoUpdateInterval = window.setInterval(() => {
      this.reload();
    }, AUTOUPDATE_INTERVAL);
  }

  public stopAutoUpdate() {
    if (this.autoUpdateInterval) {
      window.clearInterval(this.autoUpdateInterval);
      this.autoUpdateInterval = null;
    }
  }
}
