import { TAddress, TCurrency, TUserType } from "shared/definitions";

export type TAccountInfo = {
  id: string;
  isBlocked: boolean;
  isTestCompleted: boolean;
  isVerified: boolean;
  name: string;
  type: TUserType | null;
  currency: TCurrency;
};

export type TUserTurnover = {
  max: number;
  amount: number;
};

export type TUserTurnoverResponse = {
  max: string;
  amount: string;
};

export type UpdateSettingsConfig = {
  account?: boolean;
  news?: boolean;
  transactions?: boolean;
};

export const CompanyDocTypes = [
  "questionnaire",
  "registration_certificate",
  "charter",
  "excerpt_from_egr",
  "office_lease_agreement",
  "Tax_declaration",
  "Balance_sheet",
  "Audit_report",
  "ceo_identity_proof",
  "ceo_appointment_protocol",
  "ceo_contract",
  "accountant_identity_proof",
  "accountant_appointment_protocol",
  "accountant_contract",
  "founder_identity_proof",
  "founder_charter",
  "founder_org_structure",
  "founder_beneficial_ownership",
  "representative_identity_proof",
  "representative_appointment_protocol",
  "appointment_proxy",
] as const;

export type TCompanyDocType = typeof CompanyDocTypes[number];

export type TDocument = {
  country: string;
  dateOfIssue: string;
  gender: string;
  id: string;
  type: string;
  typeExternal: string;
  placeOfBirth: string;
  validThrough: string;
  isOld: boolean;
};

export type TCompanyDocument = {
  type: TCompanyDocType;
  fileLinks: string[];
  id: string;
}

export type TUserDetails = {
  addresses: TAddress[];
  defaultCurrencyId: number;
  email: string;
  fullName: string;
  id: string;
  isBlocked: boolean;
  isTestCompleted: boolean;
  isSumsubChecked: boolean;
  isSumsubApproved: boolean;
  sumsubRejectType: string;
  isVerified: boolean;
  nationality: string;
  notificationSettings: {
    account: boolean;
    news: boolean;
    transactions: boolean;
  };
  phone: string;
  residence: string;
  registration: string;
  type: TUserType | null;

  verificationLevel: VerificationLevel;

  publicId?: number;
};

export type TPersonalDetails = TUserDetails & {
  type: "individual";
  documents: TDocument[];
};

export type TCompanyDetails = TUserDetails & {
  type: "company";
  organizationIsDocumentUploaded: boolean;
  documents: TCompanyDocument[];
};

export enum VerificationLevel {
  Base = "base",
  Level2 = "level_2",
  Level3 = "level_3",
}

export enum VerificationStatePersonal {
  TypeSelection,
  SumSub,
  ManualApprove,
  FullVerified,
}

export enum VerificationStateCompany {
  TypeSelection,
  UploadDocs,
  ManualApprove,
  FullVerified,
}
