import { Title } from "@mantine/core";
import classNames from "classnames";
import { observer } from "mobx-react";
import { formatCurrency, formatFiat } from "shared/utils/formatCurrency";
import { Exchange } from "store/exchange";
import { ExchangeInput } from "../../../../components/common/ExchangeInput";
import { Spacer } from "../../../../components/ui/universal/Spacer/Spacer";
import styles from "./ToInput.module.scss";

type TProps = {
  store: Exchange;
};

export default observer(({ store }: TProps) => {
  return (
    <div>
      <div className={classNames("w-100", styles.titleContainer)}>
        <Title className={styles.stepTitle} order={4}>Вы получаете</Title>
      </div>
      <Spacer size={2} />
      <ExchangeInput
        currentCurrency={store.toInfo.currency}
        selectOptions={store.toCurrencies}
        onSelect={(value) => {
          store.setToCurrency(value);
        }}
        inputProps={{
          disabled: true,
          type: "text",
          value: formatCurrency(store.toInfo.value, { ticker: store.toInfo.currency }),
          rightSection: (
            <div className={styles.estimateContainer}>
              ≈{" "}
              {formatFiat(store.toInfo.estimate, { ticker: store.defaultCurrency?.ticker, displayTicker: true })}
            </div>
          ),
        }}
        selectProps={{
          placeholder: "Выберите валюту",
        }}
      />
    </div>
  );
});
