
import classNames from "classnames";
import styles from "./StepCounter.module.scss";

export default ({ step }: { step: number }) => {
  return (
    <div className={classNames("margin_auto", styles.text)}>
      {step}
    </div>
  );
};
