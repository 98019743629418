import { makeAutoObservable } from "mobx";

export class DialogStore {
  private _open: boolean = false;
  private _promise: Promise<boolean> | null = null;
  private _resolve: ((value: boolean) => void) | null = null;
  constructor() {
    makeAutoObservable(this);
  }
  public async ask(): Promise<boolean> {
    this._open = true;
    this._promise = new Promise((resolve) => {
      this._resolve = resolve;
    });
    return this._promise;
  }
  public resolve(value: boolean) {
    this._resolve?.(value);
    this._open = false;
    this._promise = null;
    this._resolve = null;
  }
  public get open(): boolean {
    return this._open;
  }
}