import { ReactNode } from "react";
import { Paper, Title } from "@mantine/core";

export default (props: { title: string; children: ReactNode }) => {
  return (
    <Paper shadow="xs" radius="md" pt={24} pb={24} pl={28} pr={28} style={{ minHeight: 100, position: "relative" }}>
      <div className="w-100 display_flex">
        <Title className="no_select" order={4}>
          {props.title}
        </Title>
      </div>
      <div className="w-100">{props.children}</div>
    </Paper>
  );
};
