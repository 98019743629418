import { ReactNode } from "react";
import styles from "../History.module.scss";

type TProps = {
  title?: string;
  children?: ReactNode;
};

export default ({ title, children }: TProps) => {
  return (
    <div className={styles.cardFieldWrapper}>
      <div className={styles.cardFieldTitle}>
        <span>{title}</span>
      </div>
      <div className={styles.cardFieldContent}>{children}</div>
    </div>
  );
};
