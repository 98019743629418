import classNames from "classnames";
import { Loader } from "@mantine/core";
import { observer } from "mobx-react";
import { useStores } from "store/root/provider";
import { useEffect, useMemo } from "react";
import { VerificationStatePersonal } from "store/user/types";
import { VerificationStore } from "store/verificationPersonal";
import styles from "./PersonalVerification.module.scss";
import { Level2 } from "../components/LevelDescription";
import { SumsubWrapper } from "../components/SumsubWrapper";
import { VerificationProgressCard } from "../components/VerificationProgressCard";

const POLL_USER_STATE_INTERVAL = 10000;

export const PersonalVerification = observer(() => {
  const { user } = useStores();
  const store = useMemo(() => {
    return new VerificationStore(user);
  }, [user]);

  useEffect(() => {
    if (store.verificationState === VerificationStatePersonal.SumSub) {
      // Don't know exact moment when user verification state has changed
      const id = window.setInterval(() => {
        store.updateUserInfo();
      }, POLL_USER_STATE_INTERVAL);
      return () => {
        window.clearInterval(id);
      };
    }
    return undefined;
  }, [store, store.verificationState]);

  return (
    <div className={styles.root}>
      <VerificationProgressCard step={store.levelContent} type={"individual"} />
      <div className={classNames(styles.card, styles.contentCard)}>
        {store.verificationState === VerificationStatePersonal.SumSub &&
          (store.token ? (
            <SumsubWrapper
              accessToken={store.token}
              expirationHandler={store.getSumSubToken}
              className={styles.sumSubContainer}
              onMessage={store.onSumsubMessage}
            />
          ) : (
            <div className={styles.loaderContainer}>
              <Loader />
            </div>
          ))}
        {store.verificationState === VerificationStatePersonal.ManualApprove && <Level2 />}
        {store.verificationState === VerificationStatePersonal.FullVerified && <Level2 />}
      </div>
    </div>
  );
});
