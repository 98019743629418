import classNames from "classnames";
import { ReactNode } from "react";
import styles from "./Card.module.scss";

type TProps = {
  className?: string;
  mode?: "danger" | "warning" | "info";
  children: ReactNode;
};

export function Card(props: TProps) {
  return <div className={classNames(styles.root, props.className, {
    [styles.warning]: props.mode === "warning",
    [styles.danger]: props.mode === "danger",
    [styles.info]: props.mode === "info",
  })}>{props.children}</div>;
}
