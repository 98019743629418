import { Button, Collapse, Divider, Space } from "@mantine/core";
import { ReactComponent as DownIcon } from "shared/static/icons/adapted/down.svg";
import { ReactComponent as ArrowUpRightIcon } from "shared/static/icons/adapted/arrow-up-right.svg";
import { FC, useMemo, useState } from "react";
import classNames from "classnames";
import { switchMatch, historyFormatTime, historyFormatDateTime } from "shared/utils";
import { THistoryItem } from "store/history/types";
import { formatCurrency } from "shared/utils/formatCurrency";
import { chainToName } from "shared/utils/mappings/chainToName";
import { CopyContent } from "components/common/CopyContent";
import { useStores } from "store/root/provider";
import styles from "../History.module.scss";
import { CurrencyIcon } from "../../../components/common/CurrencyIcon";
import CardField from "./CardField";
import { GreenSpan } from "../../../components/ui/text/Span";
import { StatusText } from "./StatusText";

interface IProps {
  item: THistoryItem;
}

enum HistoryItemLayoutType {
  FiatDeposit,
  FiatWithdraw,
  CryptoDeposit,
  CryptoWithdraw,
  Exchange,
}

function getLayoutType(item: THistoryItem) {
  if (item.type === "exchange") {
    return HistoryItemLayoutType.Exchange;
  }
  if (item.network) {
    if (item.type === "withdraw") {
      return HistoryItemLayoutType.CryptoWithdraw;
    }
    return HistoryItemLayoutType.CryptoDeposit;
  }
  if (!item.network) {
    if (item.type === "withdraw") {
      return HistoryItemLayoutType.FiatWithdraw;
    }
    return HistoryItemLayoutType.FiatDeposit;
  }
  return HistoryItemLayoutType.Exchange;
}

const HistoryItem: FC<IProps> = ({ item }) => {
  const { modals, history, wallets } = useStores();
  const [isOpened, setIsOpened] = useState(false);

  const network = useMemo(() => item.network || "", [item.network]);
  const layoutType = getLayoutType(item);
  const hasError = item.status === "rejected" || item.status === "hold";

  return (
    <div>
      <div className={styles.historyItemWrapper} onClick={() => setIsOpened(!isOpened)}>
        <div className="display_flex">
          <div className="position_relative">
            <CurrencyIcon
              className={styles.historyItemCoinIcon}
              icon={item?.tickerFrom ? item?.tickerFrom?.toLowerCase?.() : item?.tickerTo || ""}
            />
            {switchMatch(item.operationType, {
              exchange: (
                <div className={styles.historyDirectionIconWrapper}>
                  <CurrencyIcon icon={item?.tickerTo?.toLowerCase() ?? ""} />
                </div>
              ),
              withdraw: (
                <div className={styles.historyDirectionIconWrapper}>
                  <ArrowUpRightIcon />
                </div>
              ),
              deposit: (
                <div className={styles.historyDirectionIconWrapper}>
                  <ArrowUpRightIcon className={styles.historyDirectionIconReverse} />
                </div>
              ),
            })}
          </div>
          <div className={styles.historyItemTitleContainer}>
            <div className={styles.historyItemTitle}>
              {switchMatch(item.operationType, {
                exchange: (
                  <div>
                    <span>Обмен</span>
                    <span className={styles.exchangePairs}>
                      {item.tickerFrom}
                      {" → "}
                      {item.tickerTo}
                    </span>
                  </div>
                ),
                deposit: (
                  <div>
                    <span>Пополнение</span>
                    <span className={styles.exchangePairs}>{item.tickerFrom}</span>
                  </div>
                ),
                withdraw: (
                  <div>
                    <span>Вывод</span>
                    <span className={styles.exchangePairs}>{item.tickerFrom}</span>
                  </div>
                ),
              })}
            </div>
            <div className={styles.historyItemDate}>
              {historyFormatTime(item.createdAt)}{" "}
              <span className={styles.historyItemStatusMobile}>
                <StatusText status={item?.status} />
              </span>
            </div>
          </div>
        </div>
        <div className="margin_left_auto display_flex">
          <div className={styles.historyItemStatus}>
            <StatusText status={item?.status} />
          </div>
          <div className={styles.historyItemAmounts}>
            <div>
              {switchMatch(item.operationType, {
                withdraw: (
                  <div>
                    <div
                      className={classNames(
                        styles.itemAmount,
                        hasError ? styles.historyItemAmountHasError : styles.historyItemAmountWithdraw,
                      )}>
                      -{formatCurrency(item.amountFrom, { ticker: item.tickerFrom, displayTicker: true })}
                    </div>
                  </div>
                ),
                deposit: (
                  <div>
                    <div
                      className={classNames(
                        styles.itemAmount,
                        hasError ? styles.historyItemAmountHasError : styles.historyItemAmountDeposit,
                      )}>
                      +{formatCurrency(item.amountFrom, { ticker: item.tickerTo, displayTicker: true })}
                    </div>
                  </div>
                ),
                exchange: (
                  <div>
                    <div
                      className={classNames(
                        styles.itemAmount,
                        hasError ? styles.historyItemAmountHasError : styles.historyItemAmountDeposit,
                      )}>
                      +{formatCurrency(item.amountTo, { ticker: item.tickerTo, displayTicker: true })}
                    </div>
                    <div className={styles.historyItemAmountSecondaryDescription}>
                      -{formatCurrency(item.amountFrom, { ticker: item.tickerFrom, displayTicker: true })}
                    </div>
                  </div>
                ),
              })}
            </div>
            <div
              className={classNames({
                [styles.collapseArrow]: true,
                [styles.collapseArrowOpened]: isOpened,
              })}>
              <DownIcon />
            </div>
          </div>
        </div>
      </div>

      <Collapse in={isOpened}>
        <div className={styles.historyItemCollapseWrapper}>
          <div className={styles.rowsContainer}>
            <CardField title="Время">{historyFormatDateTime(item.createdAt)}</CardField>

            <CardField title="ID транзакции">
              <CopyContent content={item?.id || ""} />
            </CardField>

            <CardField title="Статус">
              <StatusText status={item.status} />
            </CardField>

            {layoutType === HistoryItemLayoutType.CryptoDeposit && (
              <CardField title="Адрес отправителя">
                <CopyContent content={item?.addressFrom || ""} />
              </CardField>
            )}

            {layoutType === HistoryItemLayoutType.CryptoWithdraw && (
              <CardField title="Адрес получателя">
                <CopyContent content={item?.addressTo || ""} />
              </CardField>
            )}

            {(layoutType === HistoryItemLayoutType.Exchange ||
              layoutType === HistoryItemLayoutType.FiatDeposit ||
              layoutType === HistoryItemLayoutType.FiatWithdraw) && <div className={styles.divAlign} />}

            {layoutType === HistoryItemLayoutType.Exchange && (
              <CardField title="Пополнение">
                <GreenSpan>
                  +{formatCurrency(item.amountTo, { ticker: item.tickerTo, displayTicker: true })} {network}
                </GreenSpan>
              </CardField>
            )}

            {(layoutType === HistoryItemLayoutType.FiatDeposit ||
              layoutType === HistoryItemLayoutType.CryptoDeposit) && (
              <CardField title="Пополнение">
                <GreenSpan>
                  +{formatCurrency(item.amountFrom, { ticker: item.tickerTo, displayTicker: true })} {network}
                </GreenSpan>
              </CardField>
            )}

            {(layoutType === HistoryItemLayoutType.FiatWithdraw ||
              layoutType === HistoryItemLayoutType.CryptoWithdraw) && (
              <CardField title="Вывод">
                {formatCurrency(item.amountFrom, { ticker: item.tickerFrom, displayTicker: true })}{" "}
                {network && chainToName(network)}
              </CardField>
            )}

            {layoutType === HistoryItemLayoutType.FiatWithdraw && <div className={styles.divAlign} />}

            {(layoutType === HistoryItemLayoutType.CryptoDeposit ||
              layoutType === HistoryItemLayoutType.CryptoWithdraw) && (
              <CardField title="Хэш транзакции">
                <CopyContent content={item?.transactionHash || ""} />
              </CardField>
            )}

            {layoutType === HistoryItemLayoutType.Exchange && (
              <CardField title="Отправлено">
                {formatCurrency(item.amountFrom, { ticker: item.tickerFrom, displayTicker: true })} {network}
              </CardField>
            )}

            {layoutType === HistoryItemLayoutType.Exchange && <div className={styles.divAlign} />}

            {(layoutType === HistoryItemLayoutType.FiatDeposit ||
              layoutType === HistoryItemLayoutType.FiatWithdraw ||
              layoutType === HistoryItemLayoutType.CryptoWithdraw ||
              layoutType === HistoryItemLayoutType.Exchange) && (
              <CardField title="Комиссия">
                {Number.isNaN(Number(item.fee))
                  ? "–"
                  : formatCurrency(item.fee, { ticker: item.tickerFee, displayTicker: true })}
              </CardField>
            )}
          </div>
          {item.needLiveness && (
            <>
              <Space h="md" />
              <Divider />
              <Space h="md" />
              <Button onClick={async () => {
                await modals.livenessCheck(item.id);
                await wallets.reload();
                await history.reload();
              }}>Подтвердить</Button>
            </>
          )}
        </div>
      </Collapse>
    </div>
  );
};

export default HistoryItem;
