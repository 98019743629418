import { Title, Space, TextInput } from "@mantine/core";
import { observer } from "mobx-react";
import { VerificationStore } from "store/verificationCompany";
import styles from "./Page0.module.scss";

type TProps = {
  store: VerificationStore;
};

export const Page0 = observer((props: TProps) => {
  return (
    <>
      <Title order={4}>Документы вашей компании</Title>
      <Space h="md" />
      <TextInput
        disabled={props.store.organizationExists}
        label="Полное наименование организации"
        value={props.store.fullName}
        onChange={(e) => {
          props.store.fullName = e.target.value;
        }}
        classNames={{ label: styles.inputLabel, input: styles.orgNameInput }}
        placeholder="Например, Общество с ограниченной ответственностью ..."
      />
    </>
  );
});
