import classNames from "classnames";
import { observer } from "mobx-react";
import { ReactNode } from "react";
import styles from "./WizardNav.module.scss";

type TProps = {
  active: number;
  stepsCount: number;
};

export const WizardNav = observer((props: TProps) => {
  const items: ReactNode[] = [];
  for (let i = 0; i < props.stepsCount; i++) {
    items.push(
      <div
        key={i}
        className={classNames(styles.questionNavItem, {
          [styles.active]: i === props.active,
          [styles.complete]: i < props.active,
        })}
      />,
    );
  }
  return <div className={styles.root}>{[...items]}</div>;
});
