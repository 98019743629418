import { action, makeAutoObservable } from "mobx";
import { TCurrency, TTickerOperation } from "shared/definitions";
import { sentryCaptureError } from "shared/utils/sentry";
import { fetchTickerOperations } from "shared/utils/api/tickerOperations";
import { TCurrencyNoNetwork, TLimit, TRate } from "./types";
import { fetchCurrencies, fetchLimits, fetchRates } from "./utils";

export class Currencies {
  currencies: TCurrency[] = [];
  rates: TRate[] = [];
  limits: TLimit[] = [];
  tickerOperations: TTickerOperation[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  public async init() {
    return Promise.all([
      fetchCurrencies()
        .then(
          action((res) => {
            this.currencies = res.data.items;
          }),
        )
        .catch((error) => {
          sentryCaptureError(error);
        }),
      fetchRates()
        .then(
          action((res) => {
            this.rates = res.data.items;
          }),
        )
        .catch((error) => {
          sentryCaptureError(error);
        }),
      fetchTickerOperations()
        .then(
          action((res) => {
            this.tickerOperations = res.data.items;
          }),
        )
        .catch((error) => {
          sentryCaptureError(error);
        }),
      fetchLimits()
        .then(
          action((res) => {
            this.limits = res;
          }),
        )
        .catch((error) => {
          sentryCaptureError(error);
        }),
    ]);
  }

  get chains() {
    return this.currencies.reduce((prev, el) => {
      if (el.network) {
        const ticker = el.ticker;
        const existingTickers = prev[ticker];
        if (existingTickers) {
          existingTickers.push(el.network);
        } else {
          prev[ticker] = [el.network];
        }
      }
      return prev;
    }, {} as Record<string, string[] | undefined>);
  }

  public getFiatCurrencyByTicker(ticker: string) {
    return this.fiat.find((el) => el.ticker === ticker) || null;
  }

  public getChainsByTicker(ticker: string) {
    return this.chains[ticker.toUpperCase()] || [];
  }

  public currencyById(id: number) {
    return this.currencies.find((el) => el.id === id);
  }

  get fiat() {
    return this.currencies.filter((el) => !el.network);
  }

  get crypto() {
    return this.currencies.filter((el) => !!el.network);
  }

  public isFiat(ticker: string) {
    const currency = this.currencies.find((el) => el.ticker === ticker);
    if (!currency) {
      sentryCaptureError(`Couldn't find currency with ticker ${ticker}`);
      return false;
    }
    return !currency.network;
  }

  get cryptoCurrencies() {
    const res: Record<string, TCurrencyNoNetwork> = {};
    this.currencies.forEach((el) => {
      if (el.network) {
        res[el.ticker] = {
          ticker: el.ticker,
          name: el.name,
        };
      }
    });
    return Object.values(res);
  }

  get fiatCurrencies() {
    const res: Record<string, TCurrencyNoNetwork> = {};
    this.currencies.forEach((el) => {
      if (!el.network) {
        res[el.ticker] = {
          ticker: el.ticker,
          name: el.name,
        };
      }
    });
    return Object.values(res);
  }

  get allCurrencies() {
    const res: Record<string, TCurrencyNoNetwork> = {};
    this.currencies.forEach((el) => {
      if (!res[el.ticker]) {
        res[el.ticker] = {
          ticker: el.ticker,
          name: el.name,
        };
      }
    });
    return Object.values(res);
  }
}
