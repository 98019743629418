import { RouteState } from "react-router5/dist/types";
import { TFlowType } from "shared/definitions";
import { Form } from "./Form";

type TProps = {
  routeState: RouteState;
};

export function Transfer(props: TProps) {
  return <Form fiatFlow={TFlowType.transfer} routeState={props.routeState} />;
}
