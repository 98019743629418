import { makeAutoObservable, reaction } from "mobx";
import { TFlowType } from "shared/definitions";
import { estimateFee } from "shared/utils/api/estimateFee";
import { checkIfOperationAllowed } from "shared/utils/currencies/tickerOperations";
import { sentryCaptureError } from "shared/utils/sentry";
import { Currencies } from "store/currencies/currencies";
import { Estimate, SystemLimitsCheckResult } from "store/estimate";

const FEE_ESTIMATE_DELAY = 200;

export class DepositFiat {
  currency: string | null;
  amount: number = 0;
  isOwnAccount: boolean = false;
  showDetails: boolean = false;
  fiatFlowType: TFlowType.cash_register | TFlowType.transfer;
  systemLimitStatus: SystemLimitsCheckResult = SystemLimitsCheckResult.OK;
  depositLimitExceeded: boolean = false;
  estimatedFee: number = 0;

  estimateStore: Estimate;
  currenciesStore: Currencies;

  constructor(initialCurrency: string, fiatFlowType: TFlowType.cash_register | TFlowType.transfer, estimate: Estimate, currencies: Currencies) {
    this.currency = initialCurrency;
    this.fiatFlowType = fiatFlowType;
    this.estimateStore = estimate;
    this.currenciesStore = currencies;
    makeAutoObservable(this, {}, { autoBind: true });
    reaction(
      () => [this.currency, this.amount, this.fiatFlowType],
      () => {
        if (this.currency && this.amount) {
          estimateFee({
            amount: `${this.amount}`,
            tickerTo: this.currency,
            fiatFlowType: this.fiatFlowType,
          })
            .then((res) => {
              if (res) {
                this.setEstimatedFee(res.fee);
              } else {
                sentryCaptureError("Error while calculating fee, invalid server response");
              }
            })
            .catch((e) => {
              sentryCaptureError(e, { extra: { error: "Error while calculating fee" } });
            });
        } else {
          this.setEstimatedFee(0);
        }
      },
      { delay: FEE_ESTIMATE_DELAY },
    );
  }
  setCurrency(value: string | null) {
    this.currency = value;
    this.checkLimits();
  }
  setAmount(value: number) {
    this.amount = value;
    this.checkLimits();
  }
  setIsOwnAccount(value: boolean) {
    this.isOwnAccount = value;
  }
  setShowDetails(value: boolean) {
    this.showDetails = value;
  }
  setEstimatedFee(value: number) {
    this.estimatedFee = value;
  }
  checkLimits() {
    if (this.currency) {
      this.systemLimitStatus = this.estimateStore.checkSystemLimits(this.currency, this.amount, "deposit", this.fiatFlowType);
      this.depositLimitExceeded = this.estimateStore.checkExceedEstimate(this.currency, this.amount);
    }
  }
  public async checkIfOperationAvailable() {
    if (this.currency) {
      return checkIfOperationAllowed({
        tickerTo: this.currency,
        flowType: this.fiatFlowType,
        txType: "deposit"
      });
    }
    return false;
  }
}
