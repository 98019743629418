import { Text, Space, List, Button } from "@mantine/core";
import { FC } from "react";
import { SUPPORT_EMAIL_LINK } from "shared/definitions/constants";
import styles from "./Leveldescription.module.scss";
import { LevelContentWrapper } from "./LevelContentWrapper";

const ContactButton: FC = () => (
  <a href={SUPPORT_EMAIL_LINK}>
    <Button className={styles.contactButton} radius={8}>
      Связаться
    </Button>
  </a>
);

export const Level2Rejected = () => (
  <LevelContentWrapper title={"Продвинутый"}>
    <Text size={16} ff={"Actay Wide"} color={"var(--mantine-color-red-5)"}>
      Верификация не пройдена
    </Text>
    <Space h={12} />
    <Text size={16} ff={"Actay Wide"}>
      К сожалению, мы не смогли подтвердить подлинность загруженных документов и информации.
    </Text>
    <Space h={12} />
    <Text size={16} ff={"Actay Wide"}>
      Пожалуйста, обратитесь в нашу тех. поддержку для завершения верификации.
    </Text>
    <Space h={20} />
    <ContactButton />
  </LevelContentWrapper>
);

export const Level2 = () => (
  <LevelContentWrapper title={"Продвинутый"}>
    <Text size={16} ff={"Actay Wide"} color={"var(--mantine-color-green-5)"}>
      Мы получили ваши документы
    </Text>
    <Space h={12} />
    <Text size={16} ff={"Actay Wide"}>
      Вы получите полный доступ к платформе, как только мы проверим полученные документы.
    </Text>
    <Space h={12} />
    <Text size={16} ff={"Actay Wide"}>
      Постараемся обработать вашу заявку как можно скорее.
    </Text>
  </LevelContentWrapper>
);

export const Level2Approved = () => (
  <LevelContentWrapper title={"Профессионал"}>
    <Text size={16} ff={"Actay Wide"} color={"var(--mantine-color-gray-5)"}>
      Мы заботимся о вашей безопасности. Поэтому для расширения текущих лимитов необходимо созвониться с нашим
      специалистом.
    </Text>
    <Space h={12} />
    <Text size={16} ff={"Actay Wide"}>
      Как это сделать:
    </Text>
    <Space h={12} />
    <List className={styles.levelDescriptionList} withPadding pl={16}>
      <List.Item>Обратитесь в нашу тех. поддержку по почте или в Live Chat</List.Item>
      <List.Item>Подготовьте документ, с которым вы проходили верификацию</List.Item>
      <List.Item>Созвонитесь с нашим специалистом и ответьте на несколько простых вопросов</List.Item>
    </List>
    <Space h={20} />
    <ContactButton />
  </LevelContentWrapper>
);

export const Level3 = () => (
  <LevelContentWrapper title={"Профессионал"}>
    <Text size={16} ff={"Actay Wide"}>
      У вас максимальный уровень верификации. Теперь вы можете пополнять свой баланс без ограничений*
    </Text>
    <Space h={12} />
    <Text size={16} ff={"Actay Wide"} color={"var(--mantine-color-gray-5)"}>
      *На любом уровне верификации необходимо подтверждать суммы, превышающие лимит на пополнение без источника
      происхождения средств
    </Text>
  </LevelContentWrapper>
);
