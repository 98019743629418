import { Button, Checkbox, NumberInput, TextInput } from "@mantine/core";
import { useMemo, useState } from "react";
import { RouteState } from "react-router5/dist/types";
import { formatCurrency, formatFiat } from "shared/utils/formatCurrency";
import { useStores } from "store/root/provider";
import { observer } from "mobx-react";
import { WithdrawFiat } from "store/withdrawFiat";
import { InfoPopover } from "components/common/InfoPopover";
import { SystemLimitsCheckResult } from "store/estimate";
import { CollapseCard } from "components/common/CollapseCard";
import { logOwnershipCheckbox } from "shared/utils/api/bancAccCheckbox";
import styles from "./Transfer.module.scss";
import { SuccessModal } from "../SuccessModal";
import { FailedModal } from "../FailedModal";

type TProps = {
  routeState: RouteState;
};

const REQUIRED_FIELD_ERROR = "Поле обязательно для заполнения";

function getErrorText(store: WithdrawFiat) {
  const { min, max } = store.estimate.getLimitsWithType(store.ticker, "withdraw", store.fiatFlowType);
  if (store.availableBalanceError) {
    return "Недостаточно средств на счете";
  }
  if (store.systemLimitsStatus === SystemLimitsCheckResult.MaxExceeded) {
    return (
      <>
        Сумма вывода не должна превышать {formatFiat(max, { ticker: store.ticker, displayTicker: true })}
        &nbsp;
        <InfoPopover message="Мы не сможем вывести сумму более указанного лимита" />
      </>
    );
  }
  if (store.systemLimitsStatus === SystemLimitsCheckResult.MinExceeded) {
    return (
      <>
        Сумма вывода должна превышать {formatFiat(min, { ticker: store.ticker, displayTicker: true })}
        &nbsp;
        <InfoPopover message="Мы не сможем вывести сумму менее указанного лимита" />
      </>
    );
  }
  return "";
}

export const Transfer = observer((props: TProps) => {
  const { currencies, wallets, modals, history, estimate, user } = useStores();
  const ticker = useMemo(() => {
    return `${props.routeState.route.params.currency}` || currencies.fiatCurrencies[0].ticker;
  }, [currencies.fiatCurrencies, props.routeState.route.params.currency]);

  const [store] = useState(() => new WithdrawFiat(ticker, wallets, estimate, user, modals, currencies));
  const purpose = `Перевод собственных средств пользователя ${store.addressee} с платформы TopSwap`;

  return (
    <>
      <div className={styles.root}>
        <div>
          <div className={styles.infoBlock}>
            <div>
              Сумма
              <span className={styles.actions}>
                <span
                  className={styles.action}
                  onClick={() => {
                    store.setAmount(store.availableBalance);
                  }}>
                  Все
                </span>
                <span
                  className={styles.action}
                  onClick={() => {
                    store.setMin();
                  }}>
                  Мин
                </span>
              </span>
            </div>
            <div>Доступно: {formatCurrency(store.availableBalance, { ticker, displayTicker: true })}</div>
          </div>
          <NumberInput
            classNames={{ input: styles.upperInput, rightSection: styles.inputRightSectionUpper }}
            value={store.amount || undefined}
            placeholder="0"
            error={getErrorText(store) || undefined}
            type="number"
            inputMode="decimal"
            removeTrailingZeros
            precision={2}
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            min={0}
            step={0.01}
            hideControls
            rightSection={ticker}
            onChange={store.setAmount}
            autoComplete="off"
          />
        </div>
        <TextInput
          autoComplete="off"
          classNames={{ label: styles.inputLabel }}
          disabled
          label="Получатель"
          value={store.addressee}
        />
        <TextInput
          autoComplete="off"
          classNames={{ label: styles.inputLabel }}
          error={store.errors.bankName && REQUIRED_FIELD_ERROR}
          label="Наименование банка"
          value={store.bankName}
          onChange={(e) => {
            store.setBankName(e.target.value);
          }}
        />
        <TextInput
          autoComplete="off"
          classNames={{ label: styles.inputLabel }}
          error={store.errors.bankAddress && REQUIRED_FIELD_ERROR}
          label="Адрес банка"
          value={store.bankAddress}
          onChange={(e) => {
            store.setBankAddress(e.target.value);
          }}
        />
        <TextInput
          autoComplete="off"
          classNames={{ label: styles.inputLabel }}
          error={store.errors.bic && REQUIRED_FIELD_ERROR}
          label="BIC/SWIFT"
          value={store.bic}
          onChange={(e) => {
            store.setBic(e.target.value);
          }}
        />
        <TextInput
          autoComplete="off"
          classNames={{ label: styles.inputLabel }}
          error={store.errors.accountNumber && REQUIRED_FIELD_ERROR}
          label="Номер счета"
          value={store.accountNumber}
          onChange={(e) => {
            store.setAccountNumber(e.target.value);
          }}
        />
        <div>
          <CollapseCard title="Дополнительная информация">
            <div className={styles.additionalInfoData}>
              <div className={styles.additionalFieldName}>Адрес получателя</div>
              <div className={styles.additionalFieldValue}>{store.addresseeAddress}</div>
              <div className={styles.additionalFieldName}>Назначение платежа</div>
              <div className={styles.additionalFieldValue}>{purpose}</div>
            </div>
          </CollapseCard>
        </div>
        <div className={styles.isOwnAccountContainer}>
          <Checkbox
            checked={store.isOwnAccount}
            className={styles.checkbox}
            classNames={{ label: styles.checkboxLabel }}
            onChange={() => {
              store.setIsOwnAccount(!store.isOwnAccount);
              logOwnershipCheckbox();
            }}
            label={
              <>
                Я подтверждаю, что банковский счет принадлежит мне&nbsp;
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}>
                  <InfoPopover
                    iconClassName={styles.isOwnAccountInfo}
                    message="Мы не сможем вывести средства, если счет принадлежит другому лицу"
                  />
                </span>
              </>
            }
          />
        </div>
        <div className={styles.submitBlock}>
          <Button
            className={styles.submitButton}
            disabled={!store.submitButtonEnabled}
            onClick={async () => {
              const isOperationAllowed = await store.checkIfOperationAvailable();
              if (isOperationAllowed) {
                await store.submit();
                history.reload();
                wallets.reload();
              } else {
                modals.setDisabledOperationModalOpen(true);
              }
            }}>
            Вывести
          </Button>
          <span className={styles.commissionContainer}>
            Комиссия: {formatFiat(store.fee)} {ticker}{" "}
            <InfoPopover message="Комиссия TopSwap, не включая банковские комиссии. Подробнее о комиссиях" />
          </span>
        </div>
      </div>
      {store.showTransactionSuccess && <SuccessModal onClose={() => store.setShowTransactionSuccess(false)} />}
      {store.showTransactionFailed && <FailedModal onClose={() => store.setShowTransactionFailed(false)} />}
    </>
  );
});
