import { Pagination, Space } from "@mantine/core";
import { observer } from "mobx-react";
import { useStores } from "store/root/provider";
import { ReactComponent as NoData } from "shared/static/icons/noData.svg";
import { useEffect } from "react";
import cn from "classnames";
import { computed } from "mobx";
import styles from "./History.module.scss";
import HistoryGroupItem from "./components/HistoryGroupItem";
import { HistoryHeader } from "./components/HistoryHeader";

export const History = observer(() => {
  const { history } = useStores();

  const {
    groupedHistory,
    rawData,
    isLoaded,
    reload,
    historyPageFilters,
    setHistoryPageTypeFilter,
    setHistoryPageTickerFilter,
    startAutoUpdate,
    stopAutoUpdate,
    lastPage,
  } = history;

  useEffect(() => {
    startAutoUpdate();
    return () => stopAutoUpdate();
  }, [startAutoUpdate, stopAutoUpdate]);

  useEffect(() => {
    return () => {
      setHistoryPageTickerFilter(null);
      setHistoryPageTypeFilter(null);
    };
  }, [setHistoryPageTickerFilter, setHistoryPageTypeFilter]);

  useEffect(() => {
    reload();
  }, [reload]);

  const hideHeader = computed(() => {
    return historyPageFilters.ticker === null && historyPageFilters.type === null && isLoaded && rawData.length === 0;
  }).get();

  return (
    <div className={styles.root}>
      <div className={cn(styles.historyContainer)}>
        {!hideHeader && <HistoryHeader />}

        {isLoaded && rawData.length > 0 && (
          <>
            <ol className={styles.listsContainer}>
              {Object.entries(groupedHistory).map((el, key) => {
                return (
                  <li key={`thistoryItem${key}`}>
                    <HistoryGroupItem el={el} />
                  </li>
                );
              })}
            </ol>
            <Space h="md" />
            <Pagination
              total={lastPage}
              page={history.page}
              onChange={(pageNumber) => {
                history.page = pageNumber;
              }}
            />
          </>
        )}
        {isLoaded && rawData.length === 0 && (
          <div className={styles.historyContainerNoData}>
            <div className={styles.historyContainerNoDataInner}>
              <NoData className={styles.noDataIcon} />
              <p>Пока нет операций</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});
