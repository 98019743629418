import { Button, Space, Text, Title } from "@mantine/core";
import { ReactComponent as ArrowIcon } from "shared/static/icons/arrowRight.svg";
import { ReactComponent as PersonIcon } from "shared/static/icons/person.svg";
import { ReactComponent as CompanyIcon } from "shared/static/icons/company.svg";
import { useState } from "react";
import classNames from "classnames";
import { TUserType } from "shared/definitions";
import styles from "./TypeSelection.module.scss";
import { LevelContentWrapper } from "./LevelContentWrapper";

type TCardProps = {
  userType: TUserType;
  active?: boolean;
  onClick?: () => void;
};

function UserCard(props: TCardProps) {
  return (
    <div className={styles.companyTypeContainer} onClick={props.onClick}>
      {props.userType === "company" ? (
        <CompanyIcon className={styles.userTypeIcon} />
      ) : (
        <PersonIcon className={styles.userTypeIcon} />
      )}
      <div className={classNames(styles.typeCardRight, { [styles.active]: props.active })}>
        <div className={styles.companyTypeTextContainer}>
          {props.userType === "company" ? (
            <>
              <Title className={styles.companyTypeTitle} order={5}>Юридическое лицо</Title>
              <Text size="sm">Если вы действуете от лица компании</Text>
            </>
          ) : (
            <>
              <Title className={styles.companyTypeTitle} order={5}>Физическое лицо</Title>
              <Text size="sm">Если вы действуете от своего лица</Text>
            </>
          )}
        </div>
        {props.active ? (
          <Text className={styles.typeSelectLinkButton}>Сменить</Text>
        ) : (
          <ArrowIcon className={classNames(styles.typeSelectLinkButton, styles.userTypeArrow)} />
        )}
      </div>
    </div>
  );
}

type TTypeSelectionProps = {
  onTypeSelected: (value: TUserType) => void;
};

export const TypeSelection = (props: TTypeSelectionProps) => {
  const [selectedType, setSelectedType] = useState<TUserType | null>(null);
  return (
    <LevelContentWrapper title={"Новичок"}>
      <Text size={16} ff={"Actay Wide"}>
        Выберите тип вашего аккаунта
      </Text>
      <Space h="sm" />
      {selectedType === null && (
        <>
          <UserCard userType="individual" onClick={() => setSelectedType("individual")} />
          <Space h="sm" />
          <UserCard userType="company" onClick={() => setSelectedType("company")} />
        </>
      )}
      {selectedType === "individual" && (
        <>
          <UserCard active userType="individual" onClick={() => setSelectedType(null)} />
          <Space h="sm" />
          <p>Для получения полного доступак платформе, верифицируйте ваш аккаунт.</p>
          <Space h="sm" />
          <Button onClick={() => props.onTypeSelected("individual")}>Пройти верификацию</Button>
        </>
      )}
      {selectedType === "company" && (
        <>
          <UserCard active userType="company" onClick={() => setSelectedType(null)} />
          <Space h="sm" />
          <p>Для получения полного доступак платформе, верифицируйте аккаунт вашего юр. лица:</p>
          <Space h="sm" />
          <ol className={styles.kybGuideList}>
            <li>Пройдите процедуру KYB (Know Your Business), загрузив документы вашей компании</li>
            <Space h="sm" />
            <li>Заполните информацию и загрузите документы ответственных должностных лиц</li>
            <Space h="sm" />
            <li>Ожидайте проверки документов</li>
          </ol>
          <Space h="sm" />
          <a className={styles.verificationGuideLink} href="/">
            Подробная инструкция по верификации
          </a>
          <Space h="sm" />
          <Button onClick={() => props.onTypeSelected("company")}>Пройти верификацию</Button>
        </>
      )}
    </LevelContentWrapper>
  );
};
