import { NumberInput, Select, Title, Button, Checkbox, Anchor } from "@mantine/core";
import classNames from "classnames";
import { useEffect, useMemo } from "react";
import { RouteState } from "react-router5/dist/types";
import { RoundCurrencyIcon } from "components/common/RoundCurrencyIcon";
import { useHistoryMode } from "pages/History/utils";
import { observer } from "mobx-react";
import { computed } from "mobx";
import { useStores } from "store/root/provider";
import { chainToName } from "shared/utils/mappings/chainToName";
import { Limits } from "components/common/Limits";
import { CryptoDeposit } from "store/cryptoDeposit/cryptoDeposit";
import { InfoPopover } from "components/common/InfoPopover";
import { Card } from "components/common/Card";
import { Estimate, SystemLimitsCheckResult } from "store/estimate";
import { formatCurrency } from "shared/utils/formatCurrency";
import { SUPPORT_EMAIL_LINK } from "shared/definitions/constants";
import { SelectItem } from "components/common/SelectItem";
import { ToApprove } from "components/common/ToApprove";
import { History } from "../../components/common/History";
import styles from "./DepositCrypto.module.scss";
import { AddressPopup } from "./AddressPopup";

type TProps = {
  routeState: RouteState;
};

function getErrorText(limitStatus: SystemLimitsCheckResult, ticker: string, estimate: Estimate) {
  const { min, max } = estimate.getLimitsWithType(ticker, "deposit");
  if (limitStatus === SystemLimitsCheckResult.MaxExceeded) {
    return (
      <>
        Сумма пополнения не должна превышать {formatCurrency(max, { ticker, displayTicker: true })}
        &nbsp;
        <InfoPopover message="Мы не сможем зачислить сумму более указанного лимита" />
      </>
    );
  }
  if (limitStatus === SystemLimitsCheckResult.MinExceeded) {
    return (
      <>
        Сумма пополнения должна превышать {formatCurrency(min, { ticker, displayTicker: true })}
        &nbsp;
        <InfoPopover message="Мы не сможем зачислить сумму менее указанного лимита" />
      </>
    );
  }
  return "";
}

export const DepositCrypto = observer((props: TProps) => {
  useHistoryMode("deposit", "Crypto");

  const { wallets, currencies, estimate, modals, history } = useStores();

  const store = useMemo(() => {
    return new CryptoDeposit(wallets, currencies, estimate);
  }, [currencies, estimate, wallets]);

  const cryptoOptions = computed(() => {
    return currencies.cryptoCurrencies.map((el) => ({ label: el.name, value: el.ticker }));
  }).get();

  const chainOptions = computed(() => {
    if (store.currency) {
      return currencies
        .getChainsByTicker(store.currency)
        .map((el) => ({ value: el, label: chainToName(el.toUpperCase()) }));
    }
    return [];
  }).get();

  useEffect(() => {
    const preSelectCurrency = props.routeState.route.params.currency;
    if (!store.currency) {
      if (preSelectCurrency) {
        store.currency = preSelectCurrency;
      } else if (cryptoOptions.length) {
        store.currency = cryptoOptions[0].value;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cryptoOptions]);

  useEffect(() => {
    if (chainOptions.length && !store.chain) {
      store.chain = chainOptions[0].value;
    }
  }, [chainOptions, store]);

  return (
    <div className={styles.root}>
      {history.hasPendingApprove && <ToApprove />}

      <div className={styles.mainContainer}>
        <div className={classNames(styles.card, styles.depositCard)}>
          <div className={styles.cardMainContent}>
            <section className={styles.fieldsContainer}>
              <Title className={styles.cardTitle} order={3}>
                Пополнить крипту
              </Title>
              <Select
                value={store.currency}
                classNames={{ label: styles.label }}
                label="Выберите валюту"
                data={cryptoOptions}
                icon={store.currency ? <RoundCurrencyIcon icon={store.currency} /> : null}
                itemComponent={SelectItem}
                onChange={(value) => {
                  store.currency = value;
                }}
              />

              <Select
                classNames={{ label: styles.label }}
                label="Выберите сеть"
                value={store.chain}
                data={chainOptions}
                disabled={chainOptions.length < 1}
                onChange={(value) => {
                  store.chain = value;
                }}
              />

              <div className={styles.combinedInputsContainer}>
                <NumberInput
                  classNames={{ input: styles.upperInput, rightSection: styles.inputRightSectionUpper }}
                  value={store.amount || undefined}
                  autoComplete="off"
                  type="number"
                  placeholder="0"
                  inputMode="decimal"
                  removeTrailingZeros
                  precision={10}
                  step={0.0000000001}
                  min={0}
                  onWheel={(e) => {
                    e.currentTarget.blur();
                  }}
                  hideControls
                  rightSection={store.currency}
                  onChange={(value) => {
                    store.amount = value || 0;
                  }}
                />
                <NumberInput
                  disabled
                  error={store.currency && getErrorText(store.systemLimitsStatus, store.currency, estimate)} // TODO
                  autoComplete="off"
                  type="text"
                  inputMode="decimal"
                  precision={2}
                  value={store.estimatedAmountDefaultCurrency}
                  classNames={{ input: styles.lowerInput, rightSection: styles.inputRightSectionLower }}
                  rightSection={store.defaultCurrency?.ticker}
                />
              </div>
              {store.pofWarning && (
                <Card className={styles.pofWarning} mode="danger">
                  Сумма превышает доступный месячный лимит.{" "}
                  <Anchor tabIndex={-1} href={SUPPORT_EMAIL_LINK} target="_blank">
                    <span>Отправьте в тех. поддержку документ</span>
                  </Anchor>
                  , подтверждающий{" "}
                  <Anchor tabIndex={-1} href="/" target="_blank">
                    <span>источник происхождения средств</span>
                  </Anchor>
                  , после совершения операции.
                </Card>
              )}
              <div className={styles.isOwnAccountContainer}>
                <Checkbox
                  checked={store.isOwnAccount}
                  className={styles.checkbox}
                  classNames={{ label: styles.checkboxLabel }}
                  onChange={() => {
                    store.isOwnAccount = !store.isOwnAccount;
                  }}
                  label={
                    <>
                      Я подтверждаю, что криптовалютный кошелек принадлежит мне&nbsp;
                      <span
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}>
                        <InfoPopover
                          iconClassName={styles.ownAccountInfoIcon}
                          message="Мы не сможем зачислить средства, если счет принадлежит другому лицу"
                        />
                      </span>
                    </>
                  }
                />
              </div>
            </section>
          </div>
          {store.valid && store.chain && store.showPopup && !!store.amount && !!store.currency && store.selectedWallet && (
            <AddressPopup
              amountString={`${formatCurrency(store.amount, {
                displayTicker: true,
                ticker: store.currency,
              })} ${chainToName(store.chain)}`}
              address={store.selectedWallet.address}
              onClose={() => {
                store.showPopup = false;
              }}
            />
          )}
          <div className={styles.footer}>
            <Button
              className={styles.submit}
              disabled={!store.valid}
              onClick={async () => {
                const isOperationAllowed = await store.checkIfOperationAvailable();
                if (!isOperationAllowed) {
                  modals.setDisabledOperationModalOpen(true);
                } else {
                  store.showPopup = true;
                }
              }}>
              Далее
            </Button>
            {store.fee && (
              <span>
                Комиссия: {store.fee.amount} {store.fee.ticker}
              </span>
            )}
          </div>
        </div>
        <Limits />
        <History />
      </div>
    </div>
  );
});
