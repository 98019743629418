import { Divider, Title } from "@mantine/core";
import classNames from "classnames";
import { VerificationProgress } from "./VerificationProgress";
import styles from "./VerificationProgressCard.module.scss";

type TProps = React.ComponentProps<typeof VerificationProgress>;

export function VerificationProgressCard(props: TProps) {
  return (
    <div className={classNames(styles.card, styles.root)}>
      <Title order={4}>Чтобы получить полный доступ к платформе, пожалуйста, пройдите верификацию</Title>
      <Divider />
      <VerificationProgress { ...props } />
    </div>
  );
}
