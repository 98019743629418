import { API_URL, axiosInstancePrivate } from "shared/definitions/api";
import { TTransactionStatus } from "shared/definitions";
import { TWithdrawRequestParams, TWithdrawStep } from "./types";

export async function createWithdrawRequest(config: TWithdrawRequestParams) {
  return axiosInstancePrivate.post<any>(`${API_URL}/api/v1/transactions/withdraw/crypto`, config);
}

export function mapStatusToStep(backendStatus: TTransactionStatus): TWithdrawStep {
  switch (backendStatus) {
    case "completed":
      return "Finished";
    case "new":
    case "hold":
    case "pending":
      return "Processing";
    case "user_approving":
      return "Liveness";
    default:
      return "Error";
  }
}
