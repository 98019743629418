import { useCallback, useState } from "react";
import { Grid, Title, Divider, Center, Flex, Space } from "@mantine/core";
import { observer } from "mobx-react";
import { ReactComponent as NoDataIcon } from "shared/static/icons/noData.svg";
import { useStores } from "store/root/provider";
import dayjs from "dayjs";
import { CopyContent } from "components/common/CopyContent";
import { TDocument } from "store/user/types";
import styles from "./PersonalAccount.module.scss";
import { Spacer } from "../../../components/ui/universal/Spacer/Spacer";
import { EditButton } from "../components/EditButton";
import { Card } from "../components/Card";
import { Field } from "../components/Field";
import { EditRequestModal } from "../components/EditRequestModal";
import { VerificationPersonalCard } from "./VerificationPersonalCard";

const formatDate = (date: string | null | undefined, fallback = "Нет данных") => {
  if (!date) return fallback;
  if (dayjs(new Date(date)).isBefore(dayjs(1))) return fallback;
  return dayjs(new Date(date)).format("DD.MM.YYYY");
};

const NoData = () => (
  <Flex gap="md">
    <NoDataIcon />
    <span>Пока нет данных</span>
  </Flex>
);

function filterDocs(docs: TDocument[]) {
  return docs.filter((el) => {
    return el.id && el.country && el.dateOfIssue && el.validThrough && !el.isOld;
  });
}

export const PersonalAccount = observer(() => {
  const { user } = useStores();
  const [isModalShown, setIsModalShown] = useState(false);

  const editButtonHandler = useCallback(() => setIsModalShown(true), []);
  const isCompany = user.userType === "company";
  const filteredDocs = filterDocs(user.documents || []);
  return (
    <div className={styles.root}>
      <EditRequestModal open={isModalShown} onClose={() => setIsModalShown(false)} />
      <Grid>
        <Grid.Col xs={12} md={6} lg={4}>
          <Card
            title="Персональная информация"
            afterTitleContent={filteredDocs.length && <EditButton onClick={editButtonHandler} />}>
            {user.userDetails !== null && (
              <>
                <div className="w-100">
                  <Field
                    title="ID"
                    info={
                      user.userDetails?.publicId ? (
                        <CopyContent content={user.userDetails.publicId?.toString() || ""} />
                      ) : (
                        "Нет данных"
                      )
                    }
                  />
                </div>
                {filteredDocs.length && (
                  <>
                    <Spacer size={3} />
                    <div className="w-100">
                      <Field
                        title={isCompany ? "Полное наименование компании" : "Ф.И.О."}
                        info={user.userDetails?.fullName || "Нет данных"}
                      />
                    </div>
                    <Spacer size={3} />
                    <div className="w-100">
                      <Field title="Гражданство" info={user?.userDetails?.nationality || "Нет данных"} />
                    </div>
                  </>
                )}
              </>
            )}
          </Card>
        </Grid.Col>

        <Grid.Col xs={12} md={6} lg={4}>
          <Card
            title="Контактная информация"
            afterTitleContent={
              !!(user.userDetails?.phone && user.userDetails?.email) && <EditButton onClick={editButtonHandler} />
            }>
            {user.userDetails !== null &&
              (user.userDetails?.email ? (
                <>
                  <div className="w-100">
                    <Field title="Телефон для связи" info={user.userDetails?.phone || "Нет данных"} />
                  </div>
                  <Spacer size={3} />
                  <div className="w-100">
                    <Field title="Email для связи" info={user.userDetails?.email || "Нет данных"} />
                  </div>
                </>
              ) : (
                <Flex h="100%" align="center" justify="center">
                  <NoData />
                </Flex>
              ))}
          </Card>
        </Grid.Col>

        <Grid.Col xs={12} md={6} lg={4}>
          <VerificationPersonalCard />
        </Grid.Col>

        {user.userType === "individual" && !filteredDocs.length && (
          <>
            <Grid.Col span={12} mt={32}>
              <Title order={2}>Документы</Title>
              <Divider mt={12} />
            </Grid.Col>
            <Grid.Col span={12} mt={32}>
              <Center>
                <Space h="xl" />
                <NoData />
              </Center>
            </Grid.Col>
          </>
        )}

        {filteredDocs.map((document, key) => (
          <Grid.Col xs={12} md={6} key={document.id || key}>
            <Card
              title="Документ удостоверяющий личность"
              afterTitleContent={!!document && <EditButton onClick={editButtonHandler} />}>
              <div className="w-100 display_flex">
                <Field title="Номер" info={document.id || "Нет данных"} />
              </div>
              <Spacer size={3} />

              <div className="w-100 display_flex">
                <Field title="Страна выдачи" info={document.country || "Нет данных"} />
              </div>
              <Spacer size={3} />

              <Flex>
                {document.dateOfIssue && dayjs(document.dateOfIssue).isAfter(0) && (
                  <Field
                    title="Дата выдачи"
                    info={document.dateOfIssue ? formatDate(document.dateOfIssue) : "Нет данных"}
                  />
                )}

                {document.validThrough && dayjs(document.validThrough).isAfter(0) && (
                  <Field
                    title="Действителен до"
                    info={document.validThrough ? formatDate(document.validThrough) : "Нет данных"}
                  />
                )}
              </Flex>
              <Spacer size={3} />
            </Card>
          </Grid.Col>
        ))}
      </Grid>
    </div>
  );
});
