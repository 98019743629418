import { Button, Title } from "@mantine/core";
import { observer } from "mobx-react";
import { formatCurrency, formatFiat } from "shared/utils/formatCurrency";
import { Estimate, SystemLimitsCheckResult } from "store/estimate";
import { Exchange } from "store/exchange";
import { useStores } from "store/root/provider";
import { InfoPopover } from "components/common/InfoPopover";
import { ReactComponent as SwapIcon } from "shared/static/icons/swap.svg";
import { ExchangeInput } from "../../../../components/common/ExchangeInput";
import { Spacer } from "../../../../components/ui/universal/Spacer/Spacer";
import styles from "./FromInput.module.scss";

type TProps = {
  store: Exchange;
};

function getErrorText(store: Exchange, estimate: Estimate) {
  if (store.fromInfo.currency) {
    const { min, max } = estimate.getLimitsWithType(store.fromInfo.currency, "exchange");
    if (store.errors.systemLimitsStatus === SystemLimitsCheckResult.MaxExceeded) {
      return (
        <>
          Сумма обмена не должна превышать{" "}
          {formatCurrency(max, { ticker: store.fromInfo.currency, displayTicker: true })}
          &nbsp;
          <InfoPopover message="Мы не сможем обменять сумму более указанного лимита" />
        </>
      );
    }
    if (store.errors.systemLimitsStatus === SystemLimitsCheckResult.MinExceeded) {
      return (
        <>
          Сумма обмена должна превышать {formatCurrency(min, { ticker: store.fromInfo.currency, displayTicker: true })}
          &nbsp;
          <InfoPopover message="Мы не сможем обменять сумму менее указанного лимита" />
        </>
      );
    }
    if (store.errors.insufficientBalance) {
      return "Недостаточно средств на балансе";
    }
  }
  return "";
}

export default observer(({ store }: TProps) => {
  const {
    modals: { withVerificationCheck },
    estimate,
  } = useStores();
  return (
    <div>
      <Spacer size={1} />
      <div className={styles.infoBlock}>
        <Title className={styles.stepTitle} order={4}>
          Вы отправляете
          <span
            className={styles.action}
            onClick={withVerificationCheck(() => {
              store.setFromAmount(`${store.fromBalance}`);
            })}>
            Все
          </span>
          <span
            className={styles.action}
            onClick={withVerificationCheck(() => {
              store.setMin();
            })}>
            Мин
          </span>
        </Title>
        <span className={styles.allowedBalance}>
          Доступно:{" "}
          {formatCurrency(store.fromBalance, {
            ticker: store.fromInfo.currency,
            displayTicker: true,
          })}
        </span>
      </div>
      <Spacer size={2} />
      <ExchangeInput
        currentCurrency={store.fromInfo.currency}
        onSelect={store.setFromCurrency}
        selectProps={{
          placeholder: "Выберите валюту",
        }}
        selectOptions={store.fromCurrencies}
        wrapperProps={{
          error: getErrorText(store, estimate),
        }}
        inputProps={{
          value: store.fromInfo.value,
          rightSection: (
            <div className={styles.estimateContainer}>
              ≈ {formatFiat(store.fromInfo.estimate, { ticker: store.defaultCurrency?.ticker, displayTicker: true })}
            </div>
          ),
          onChange: (e) => {
            store.setFromAmount(e.target.value);
          },
        }}
      />
      <Button className={styles.swap} variant="default" onClick={() => store.swap()}>
        <SwapIcon />
      </Button>
    </div>
  );
});
