import { InfoPopover } from "components/common/InfoPopover";
import { TTransactionStatus } from "shared/definitions";
import { GreenSpan, RedSpan } from "../../../components/ui/text/Span";

type TProps = {
  status: TTransactionStatus;
};

export function StatusText(props: TProps) {
  switch (props.status) {
    case "new":
      return <span>{"Не подтверждена"}</span>;
    case "pending":
      return <span>{"В обработке"}</span>;
    case "sending":
      return <span>{"Отправляется"}</span>;
    case "user_approving":
      return <span>{"Требуется подтверждение"}</span>;
    case "completed":
      return <GreenSpan>Выполнена</GreenSpan>;
    case "hold":
      return (
        <RedSpan>
          Приостановлена{" "}
          <span onClick={(e) => e.stopPropagation()}>
            <InfoPopover message="Пожалуйста, свяжитесь с нашей тех. поддержкой" />
          </span>
        </RedSpan>
      );
    case "rejected":
      return (
        <RedSpan>
          Не выполнена{" "}
          <span onClick={(e) => e.stopPropagation()}>
            <InfoPopover message="Пожалуйста, свяжитесь с нашей тех. поддержкой" />
          </span>
        </RedSpan>
      );
    default:
      return null;
  }
}
;
