import { TCurrency } from "shared/definitions";
import { API_URL, axiosInstancePrivate } from "shared/definitions/api";
import { sentryCaptureError } from "shared/utils/sentry";
import { TRate, TLimit, TLimitResponse } from "./types";

export async function fetchCurrencies() {
  return axiosInstancePrivate.get<{ items: TCurrency[] }>(`${API_URL}/api/v1/currencies`).then((res) => {
    res.data.items = res.data.items.map((el) => {
      return { ...el };
    });
    return res; 
  });
}

export async function fetchRates() {
  return axiosInstancePrivate.get<{ items: TRate[] }>(`${API_URL}/api/v1/currencies/rates`);
}

export async function fetchLimits(): Promise<TLimit[]> {
  const resp = await axiosInstancePrivate.get<{ items: TLimitResponse[] }>(`${API_URL}/api/v1/currencies/limits`);
  if (resp.status === 200) {
    return resp.data.items.map((el) => {
      return {
        depositTransferMin: el.depositTransferMin ? Number.parseFloat(el.depositTransferMin) : 0,
        depositTransferMax: el.depositTransferMax ? Number.parseFloat(el.depositTransferMax) : 0,
        depositMax: el.depositMax ? Number.parseFloat(el.depositMax) : 0,
        depositMin: el.depositMin ? Number.parseFloat(el.depositMin) : 0,
        exchangeMax: el.exchangeMax ? Number.parseFloat(el.exchangeMax) : 0,
        exchangeMin: el.exchangeMin ? Number.parseFloat(el.exchangeMin) : 0,
        ticker: el.ticker,
        tickerId: el.tickerId,
        withdrawMax: el.withdrawMax ? Number.parseFloat(el.withdrawMax) : 0,
        withdrawMin: el.withdrawMin ? Number.parseFloat(el.withdrawMin) : 0,
        withdrawTransferMax: el.withdrawTransferMax ? Number.parseFloat(el.withdrawTransferMax) : 0,
        withdrawTransferMin: el.withdrawTransferMin ? Number.parseFloat(el.withdrawTransferMin) : 0,
      };
    });
  }
  sentryCaptureError("Couldn't get limits data");
  return [];
}
