import { Card, Title, Text, FileButton, Button, Space } from "@mantine/core";
import { observer } from "mobx-react";
import { ReactNode } from "react";
import styles from "./FileInputCard.module.scss";
import { FileLink } from "./FileLink";

export type TFileInputCardProps = {
  title: ReactNode;
  description?: ReactNode;
  accept?: string;
  files: string[];
  onDelete: (file: string) => void;
  onUpload: (file: File[]) => void;
};

export const FileInputCard = observer((props: TFileInputCardProps) => {
  return (
    <Card className={styles.root}>
      <>
        <div className={styles.content}>
          <Title className={styles.title} order={5}>{props.title}</Title>
          <Text className={styles.description}>{props.description}</Text>
          {!!props.files.length && (
            <>
              <Space h="sm" />
              <div className={styles.filesContainer}>
                {props.files.map((el, i) => {
                  return (
                    <FileLink
                      key={i}
                      name={el}
                      onDelete={() => {
                        props.onDelete(el);
                      }}
                    />
                  );
                })}
              </div>
            </>
          )}
        </div>
        <FileButton onChange={props.onUpload} accept={props.accept} multiple>
          {(btnProps) => (
            <Button className={styles.button} {...btnProps} variant="default">
              Загрузить
            </Button>
          )}
        </FileButton>
      </>
    </Card>
  );
});
