import { makeAutoObservable, reaction } from "mobx";
import { createWithdrawRequest } from "pages/WithdrawFiat/utils";
import { TFlowType } from "shared/definitions";
import { estimateFee } from "shared/utils/api/estimateFee";
import { checkIfOperationAllowed } from "shared/utils/currencies/tickerOperations";
import { sentryCaptureError } from "shared/utils/sentry";
import { getAddressString } from "shared/utils/userDetails/address";
import { Currencies } from "store/currencies/currencies";
import { Estimate, SystemLimitsCheckResult } from "store/estimate";
import { Modals } from "store/modals/modals";
import { User } from "store/user/user";
import { Wallets } from "store/wallets/wallets";

type TRequiredFieldsErrors = {
  bankName: boolean;
  bankAddress: boolean;
  bic: boolean;
  accountNumber: boolean;
};

const FEE_ESTIMATE_DELAY = 200;

export class WithdrawFiat {
  ticker: string;
  showTransactionSuccess: boolean = false;
  showTransactionFailed: boolean = false;
  amount: number = 0;
  fee: number = 0;
  fiatFlowType: TFlowType.cash_register | TFlowType.transfer = TFlowType.transfer;
  isOwnAccount: boolean = false;

  bankName: string = "";
  bankAddress: string = "";
  bic: string = "";
  accountNumber: string = "";

  errors: TRequiredFieldsErrors = {
    bankName: false,
    bankAddress: false,
    bic: false,
    accountNumber: false,
  };

  wallets: Wallets;
  estimate: Estimate;
  user: User;
  currencies: Currencies;
  modals: Modals;
  constructor(ticker: string, wallets: Wallets, estimate: Estimate, user: User, modals: Modals, currencies: Currencies) {
    this.ticker = ticker;
    this.wallets = wallets;
    this.estimate = estimate;
    this.user = user;
    this.currencies = currencies;
    this.modals = modals;
    makeAutoObservable(this, {}, { autoBind: true });
    reaction(
      () => [this.amount, this.ticker, this.fiatFlowType],
      async () => {
        if (this.amount) {
          const res = await estimateFee({
            amount: `${this.amount}`,
            tickerFrom: this.ticker,
            fiatFlowType: TFlowType.transfer,
          });
          if (res) {
            this.fee = res.fee;
          }
        } else {
          this.fee = 0;
        }
      },
      { delay: FEE_ESTIMATE_DELAY },
    );
  }
  get availableBalance() {
    return this.wallets.balanceByTicker(this.ticker);
  }

  public setAmount(value: number | undefined) {
    this.amount = value || 0;
  }
  public setMin() {
    const { min } = this.estimate.getLimitsWithType(this.ticker, "withdraw", this.fiatFlowType);
    this.setAmount(min);
  }
  get systemLimitsStatus() {
    if (this.amount <= 0) {
      return SystemLimitsCheckResult.OK;
    }
    return this.estimate.checkSystemLimits(this.ticker, this.amount, "withdraw", this.fiatFlowType);
  }
  get availableBalanceError() {
    return this.amount > this.availableBalance;
  }
  get addressee(): string {
    if (this.user.userDetails) {
      return `${this.user.userDetails.fullName.toUpperCase()} ID ${this.user.userDetails.publicId}`;
    }
    return "";
  }
  get addresseeAddress(): string {
    const addresses = this.user.userDetails?.addresses;
    if (addresses) {
      const address = addresses.find((el) => el.addressType === "registrationaddress");
      if (address) {
        return getAddressString(address, false);
      }
      sentryCaptureError(`Couldn't get registration address for user ${this.user.userDetails?.publicId}`);
    }
    return "";
  }

  get submitButtonEnabled() {
    return (
      this.systemLimitsStatus === SystemLimitsCheckResult.OK &&
      this.amount > 0 &&
      !this.availableBalanceError &&
      this.isOwnAccount
    );
  }

  public validateFields() {
    this.errors.bankName = !this.bankName;
    this.errors.bankAddress = !this.bankAddress;
    this.errors.accountNumber = !this.accountNumber;
    this.errors.bic = !this.bic;

    return !(this.errors.bankName || this.errors.bankAddress || this.errors.accountNumber || this.errors.bic);
  }

  public resetFieldValidation(field: keyof TRequiredFieldsErrors) {
    this.errors[field] = false;
  }

  public setBankName(value: string) {
    this.bankName = value;
    this.resetFieldValidation("bankName");
  }
  public setBankAddress(value: string) {
    this.bankAddress = value;
    this.resetFieldValidation("bankAddress");
  }
  public setBic(value: string) {
    this.bic = value;
    this.resetFieldValidation("bic");
  }
  public setAccountNumber(value: string) {
    this.accountNumber = value;
    this.resetFieldValidation("accountNumber");
  }
  public setShowTransactionSuccess(value: boolean) {
    this.showTransactionSuccess = value;
  }
  public setShowTransactionFailed(value: boolean) {
    this.showTransactionFailed = value;
  }
  public setIsOwnAccount(value: boolean) {
    this.isOwnAccount = value;
  }
  public async submit() {
    try {
      const valid = this.validateFields();
      if (!valid) {
        return;
      }
      const tx = await createWithdrawRequest({
        amount: `${this.amount}`,
        ticker: this.ticker,
        createdAt: new Date().toISOString(),
        bankDetails: {
          account: this.accountNumber,
          bankAddress: this.bankAddress,
          bankName: this.bankName,
          bicSwift: this.bic,
        },
        fiatFlowType: TFlowType.transfer,
      });
      if (tx.status === 200) {
        const confirmed = await this.modals.confirmTransactionWithCode(tx.data.id);
        if (confirmed) {
          this.setShowTransactionSuccess(true);
          this.setAmount(0);
        }
      } else {
        this.setShowTransactionFailed(true);
        sentryCaptureError("Couldn't create withdraw transaction");
      }
    } catch (e) {
      this.setShowTransactionFailed(true);
      sentryCaptureError(e, { extra: { error: "Error during processing withdraw transaction" } });
    }
  }
  public async checkIfOperationAvailable() {
    if (this.ticker) {
      return checkIfOperationAllowed({
        tickerFrom: this.ticker,
        flowType: this.fiatFlowType,
        txType: "withdraw",
      });
    }
    return false;
  }
}
