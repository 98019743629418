import { action, makeAutoObservable, runInAction } from "mobx";
import { sentryCaptureError } from "shared/utils/sentry";
import { TQuestion } from "./types";
import { fetchQuestions, sendResults } from "./utils";

export class FinanceTest {
  isLoading: boolean = true;
  questions: TQuestion[] = [];
  answers: (number | null)[] = [];
  active: number = 0;
  finished: boolean = false;
  validationResult: null | boolean[] = null;
  withResults: boolean = false;
  // validationResults: boolean[] | null = null;

  constructor() {
    fetchQuestions()
      .then(
        action((res) => {
          this.active = 0;
          this.answers = new Array<number>(res.data.items.length);
          this.questions = res.data.items;
          this.isLoading = false;
        }),
      )
      .catch(
        action((e) => {
          this.isLoading = false;
          sentryCaptureError(e);
        }),
      );
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public prevQuestion() {
    if (this.isLoading) {
      return;
    }
    if (this.active > 0) {
      this.active--;
    }
  }

  public nextQuestion = () => {
    if (this.isLoading) {
      return;
    }
    if (this.active < this.questions.length - 1) {
      this.active++;
    }
  };

  public finish = () => {
    const answers = this.answers.map((el) => el ?? 0);
    if (answers) {
      this.isLoading = true;
      return sendResults(answers)
        .then(() => {
          runInAction(() => {
            this.validationResult = this.answers.map((el, key) => {
              return this.questions[key].correctAnswer === el;
            });
            this.finished = true;
            this.isLoading = false;
          });
        })
        .catch(() => {
          this.isLoading = false;
        });
    }
    return null;
  };

  public resetAnswers = () => {
    this.answers = new Array<number>(this.answers.length);
  };

  public restartWithResults = () => {
    if (this.finished) {
      this.withResults = true;
      this.finished = false;
      this.active = 0;
      this.resetAnswers();
    }
  };

  get allCorrect() {
    if (this.validationResult) {
      return this.validationResult.reduce((res, el) => {
        return res && el;
      }, true);
    }
    return null;
  }

  get activeQuestion() {
    if (this.isLoading) {
      return null;
    }
    return this.questions[this.active] || null;
  }

  get activeQuestionIndex() {
    if (this.isLoading) {
      return null;
    }
    return this.active;
  }

  get hasAnswer() {
    if (this.isLoading || this.activeQuestionIndex === null) {
      return false;
    }
    return typeof this.answers[this.activeQuestionIndex] === "number";
  }

  get hasNextQuestion() {
    if (this.isLoading || this.activeQuestionIndex === null) {
      return false;
    }
    return this.activeQuestionIndex < this.questions.length - 1;
  }

  get hasPrevQuestion() {
    if (this.isLoading || this.activeQuestionIndex === null) {
      return false;
    }
    return this.activeQuestionIndex > 0;
  }
}
