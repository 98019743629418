import { Button, Center, Container, Space, Text } from "@mantine/core";
import classNames from "classnames";
import { Exchange } from "store/exchange";
import { useStores } from "store/root/provider";
import { formatCurrency } from "shared/utils/formatCurrency";
import { SpinnerLoader } from "components/common/SpinnerLoader";
import { ReactComponent as InfoIcon } from "shared/static/icons/hint-big.svg";
import { ReactComponent as AlertIcon } from "shared/static/icons/adapted/alert.svg";
import { ReactComponent as CheckIcon } from "shared/static/icons/success.svg";
import styles from "./ProcessScreen.module.scss";

type TProps = {
  store: Exchange;
};

export const ProcessingCentralSection = ({ store }: TProps) => {
  const { estimate } = useStores();
  const toAmount = store.toInfo.value;
  const toCurrency = store.toInfo.currency;
  const defaultCurrency = store.defaultCurrency;

  const toAmountDefaultCurrency = estimate.estimateToDefault(toCurrency, Number(toAmount));

  switch (store.step) {
    case "Processing":
      return (
        <div>
          <Container>
            <Center>
              <SpinnerLoader />
            </Center>
            <Space h="md" />
            <Text className={styles.textContent} align="center">
              Вы получите ≈{" "}
              {formatCurrency(toAmountDefaultCurrency, {
                ticker: defaultCurrency?.ticker,
                displayTicker: true,
              })}
            </Text>
            <Text className={styles.amount}>
              + {formatCurrency(toAmount, { ticker: toCurrency, displayTicker: true })}
            </Text>
          </Container>
        </div>
      );
    case "Liveness":
      return (
        <Container>
          <InfoIcon className={classNames(styles.statusIcon, styles.info)} />
          <Space h={32} />
          <Center>
            <Button onClick={store.livenessCheck}>Подтвердить</Button>
          </Center>
          <Space h={32} />
          <Text className={styles.textContent}>
            Вы получите ≈{" "}
            {formatCurrency(toAmountDefaultCurrency, {
              ticker: defaultCurrency?.ticker,
              displayTicker: true,
            })}{" "}
            после проверки
          </Text>
          <Space h="sm" />
          <Text className={styles.amount}>
            + {formatCurrency(toAmount, { ticker: toCurrency, displayTicker: true })}
          </Text>
        </Container>
      );
    case "Finished":
      return (
        <div>
          <div
            className={classNames(styles.statusIcon, {
              [styles.statusIconSuccess]: true,
            })}>
            <CheckIcon />
          </div>
          <Space h="md" />
          <Text className={styles.textContent}>
            Вы получите ≈{" "}
            {formatCurrency(toAmountDefaultCurrency, {
              ticker: defaultCurrency?.ticker,
              displayTicker: true,
            })}
          </Text>
          <Text className={styles.amount} color="green.5">
            + {formatCurrency(toAmount, { ticker: toCurrency, displayTicker: true })}
          </Text>
        </div>
      );
    case "Error":
      return (
        <div>
          <div
            className={classNames(styles.statusIcon, {
              [styles.statusIconError]: true,
            })}>
            <AlertIcon />
          </div>
          <div>
            <Text className={styles.textError} mt={16} align="center">
              Неизвестная ошибка
            </Text>
          </div>
        </div>
      );
    case "Rejected":
      return (
        <div>
          <div
            className={classNames(styles.statusIcon, {
              [styles.statusIconError]: true,
            })}>
            <AlertIcon />
          </div>
          <div>
            <Text className={styles.textError} mt={16} align="center">
              Операция отклонена
            </Text>
          </div>
        </div>
      );
    default:
      return null;
  }
};
