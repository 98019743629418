import { useEffect, useState } from "react";
import { SubscribeOnNetworkError } from "shared/definitions/api";
import { ReactComponent as NetworkErrorIcon } from "shared/static/icons/networkError.svg";
import styles from "./NetworkError.module.scss";

export function NetworkError() {
  const [showError, setShowError] = useState<boolean>(false);
  useEffect(() => {
    return SubscribeOnNetworkError(() => {
      setShowError(true);
    });
  }, []);
  if (showError) {
    return (
      <div className={styles.root}>
        <NetworkErrorIcon />
        Отстутствует интернет соединение
      </div>
    );
  }
  return null;
}
