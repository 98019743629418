import { ReactNode, useState } from "react";
import { ReactComponent as DropdownArrow } from "shared/static/icons/dropdownArrow.svg";
import classNames from "classnames";
import { Collapse, Title } from "@mantine/core";
import { Card } from "../Card";
import styles from "./CollapseCard.module.scss";

type TProps = {
  children: ReactNode;
  title: ReactNode;
};

export function CollapseCard(props: TProps) {
  const [opened, setOpened] = useState(false);
  return (
    <Card mode="info">
      <div>
        <div className={styles.headerContainer} onClick={() => setOpened((old) => !old)}>
          <Title order={6}>{props.title}</Title>
          <DropdownArrow className={classNames(styles.dropdownArrow, { [styles.open]: opened })} />
        </div>
        <Collapse in={opened}>{props.children}</Collapse>
      </div>
    </Card>
  );
}
