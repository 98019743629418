import React, { FC, useCallback, useEffect, useState } from "react";
import { useRoute } from "react-router5";
import { observer } from "mobx-react";
import { useStores } from "store/root/provider";
import { ReactComponent as SidebarTestIcon } from "shared/static/icons/sidebar-test-icon.svg";
import { ReactComponent as SidebarVerification } from "shared/static/icons/sidebar-verification.svg";
import { Route } from "shared/definitions";
import router from "../../../../../router";
import type { INavLink } from "./types";
import { NavLink } from "./NavLink";
import { useStyles } from "./styles";

interface INavLinksProps {
  links: INavLink[];
}

export const NavLinks: FC<INavLinksProps> = observer(({ links }) => {
  const { route } = useRoute();
  const { classes, cx } = useStyles();
  const [activeLink, setActiveLink] = useState<string>();
  const {
    modals,
    user,
  } = useStores();

  const needTest = user.isVerified && user.needTest && !user.isTestPassed;

  const { setStartTestModalOpen, withVerificationCheck } = modals;

  useEffect(() => {
    setActiveLink(route.name);
  }, [route]);

  const handleClick = useCallback(
    (link: INavLink["link"], allowOnlyVerifiedUsers: INavLink["allowOnlyVerifiedUsers"]) => {
      const cb = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        router.navigate(link);
        setActiveLink(link);
      };

      return !allowOnlyVerifiedUsers ? cb : withVerificationCheck(cb, true);
    },
    [withVerificationCheck],
  );

  return (
    <>
      {needTest && (
        <span className={cx(classes.link)} onClick={() => setStartTestModalOpen(true)}>
          <SidebarTestIcon className={classes.linkIcon} />
          <span>{"Тестирование"}</span>
        </span>
      )}

      {user !== null && !user.isVerified && (
        <NavLink link={Route.VERIFICATION} activeLink={activeLink} icon={SidebarVerification} label={"Верификация"} />
      )}

      {links.map((item) => (
        <NavLink {...item} key={item.link} activeLink={activeLink} onClick={handleClick} />
      ))}
    </>
  );
});
