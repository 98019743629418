import { Button, Flex, Overlay, Space } from "@mantine/core";
import { VerificationStore } from "store/verificationCompany";
import { observer } from "mobx-react";
import { ReactComponent as Left } from "shared/static/icons/arrowLeft.svg";
import { organizationCreate } from "shared/utils/api/uploadOrganizationDocs";
import { sentryCaptureError } from "shared/utils/sentry";
import { useMemo } from "react";
import { DialogStore } from "store/dialog";
import { Dialog } from "components/ui/Dialog";
import { LevelContentWrapper } from "../components/LevelContentWrapper";
import { WizardNav } from "./WizardNav";
import { PageFields } from "./PageFields";
import { FileCardConfigs } from "./pages/FileCardConfigs";

type TProps = {
  store: VerificationStore;
};

export const DocumentsVerification = observer((props: TProps) => {
  const dialogStore = useMemo(() => {
    return new DialogStore();
  }, []);
  return (
    <LevelContentWrapper fullWidth title="Документы компании">
      <Space h="lg" />
      <WizardNav stepsCount={props.store.docPageCount} active={props.store.page} />
      <Space h="lg" />
      <PageFields store={props.store} />
      <Dialog store={dialogStore} title="Вы добавили не все документы">
        <div>
          <ul>
            {props.store.missingFields.map((el, key) => (
              <li key={key}>{FileCardConfigs[el].title}</li>
            ))}
          </ul>
        </div>
      </Dialog>
      <Space h="md" />
      <Flex justify="space-between">
        <Button
          onClick={() => {
            props.store.page -= 1;
          }}
          disabled={props.store.page <= 0}
          variant="default">
          <Left />
        </Button>
        <Button
          onClick={async () => {
            let canProceed = true;
            if (props.store.missingFields.length > 0) {
              canProceed = await dialogStore.ask();
            }
            if (canProceed) {
              if (props.store.page === 0 && !props.store.organizationExists) {
                try {
                  await organizationCreate(props.store.fullName);
                  props.store.page += 1;
                } catch (e) {
                  sentryCaptureError(e);
                }
              } else if (props.store.page === props.store.docPageCount - 1) {
                await props.store.confirmDocUploaded();
              } else {
                props.store.page += 1;
              }
            }
          }}>
          {props.store.page < props.store.docPageCount - 1 ? "Далее" : "Отправить"}
        </Button>
      </Flex>
    </LevelContentWrapper>
  );
});
