import React, { FC } from "react";
import { observer } from "mobx-react";
import type { INavLink } from "./types";
import { useStyles } from "./styles";

type OnClickHandler = (
  link: INavLink["link"],
  allowOnlyVerifiedUsers: INavLink["allowOnlyVerifiedUsers"],
) => (e: React.MouseEvent<HTMLAnchorElement>) => void;

interface INavLinkProps extends INavLink {
  activeLink?: string;
  onClick?: OnClickHandler;
}

export const NavLink: FC<INavLinkProps> = observer(
  ({ link, label, activeLink, icon: Icon, allowOnlyVerifiedUsers, onClick }) => {
    const { classes, cx } = useStyles();

    return (
      <a
        className={cx(classes.link, { [classes.linkActive]: link === activeLink })}
        href={link}
        key={label}
        onClick={onClick?.(link, allowOnlyVerifiedUsers)}>
        <Icon className={classes.linkIcon} />
        <span>{label}</span>
      </a>
    );
  },
);
