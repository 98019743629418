import { Card, Title } from "@mantine/core";
import { observer } from "mobx-react";
import { useHistoryMode } from "pages/History/utils";
import { useEffect } from "react";
import { RouteState } from "react-router5/dist/types";
import router from "router";
import { Route } from "shared/definitions/routes";
import { useStores } from "store/root/provider";
import { ToApprove } from "components/common/ToApprove";
import { History } from "../../components/common/History";
import styles from "./WithdrawFiat.module.scss";
import { Transfer } from "./components/Transfer";

type TProps = {
  routeState: RouteState;
};

export const WithdrawFiat = observer((props: TProps) => {
  useHistoryMode("withdraw", "Fiat");
  const { history } = useStores();

  const ticker = props.routeState.route.params.currency;

  useEffect(() => {
    if (!ticker) {
      router.navigate(Route.MAIN_SCREEN);
    }
  });

  return (
    <div className={styles.root}>
      {history.hasPendingApprove && <ToApprove />}
      <div className={styles.mainContainer}>
        <Card className={styles.fiatCard}>
          <Title order={3}>Вывести со счета</Title>
          <Transfer routeState={props.routeState} />
        </Card>
        <History />
      </div>
    </div>
  );
});
