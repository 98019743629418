import { Modal } from "@mantine/core";
import { observer } from "mobx-react";
import { useCallback, useState } from "react";
import { FinanceTest as FinanceTestStore } from "store/financeTest";
import styles from "./FinanceTest.module.scss";
import { ModalContent } from "./ModalContent";

type TProps = {
  open: boolean;
  onClose: () => void;
};

export const FinanceTest = observer(({ open, onClose }: TProps) => {
  const [store] = useState(() => {
    return new FinanceTestStore();
  });

  const handleClose = useCallback(() => {
    store.restartWithResults();
    onClose();
  }, [store, onClose]);

  return (
    <Modal
      centered
      classNames={{ title: styles.modalHeader, modal: styles.modal }}
      opened={open}
      onClose={() => handleClose()}
      withCloseButton={!store.finished}
      title={store.finished ? undefined : "Тестирование"}>
      <ModalContent testStore={store} onClose={() => handleClose()} />
    </Modal>
  );
});
