import classNames from "classnames";
import styles from "./Icon.module.scss";
import { CurrencyIcon } from "../CurrencyIcon";

type TProps = {
  icon: string;
  className?: string;
  iconClassName?: string;
};

export const RoundCurrencyIcon = (props: TProps) => {
  return (
    <div className={classNames(props.className, styles.iconContainer)}>
      <CurrencyIcon className={classNames(props.iconClassName, styles.icon)} icon={props.icon}/>
    </div>
  );
};
