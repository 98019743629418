import { Modal } from "@mantine/core";
import classNames from "classnames";
import { observer } from "mobx-react";
import { ReactComponent as Exclamation } from "shared/static/icons/exclamation.svg";
import styles from "./WarningModal.module.scss";

type TProps = {
  header?: React.ReactNode;
  children?: React.ReactNode;
  centeredHeader?: boolean;
  footerActions?: React.ReactNode;
  mode?: "warn" | "danger";
  onClose: () => void;
  open: boolean;
  modalClassName?: string;
  footerClassName?: string;
};

export const WarningModal = observer((props: TProps) => {
  const hasWarnIcon = props.mode === "warn" || props.mode === "danger";
  return (
    <Modal
      centered
      classNames={{
        title: classNames(styles.title, { [styles.successTitle]: props.centeredHeader }),
        header: classNames(styles.header, { [styles.successHeader]: props.centeredHeader }),
        modal: classNames(styles.modal, props.modalClassName),
        close: classNames(styles.closeButton, { [styles.centered]: props.centeredHeader }),
      }}
      opened={props.open}
      onClose={props.onClose}
      title={
        <section className={styles.headerSection}>
          {hasWarnIcon ? (
            <>
              <div className={classNames(styles.headIconContainer, { [styles.danger]: props.mode === "danger" })}>
                <Exclamation className={classNames(styles.headIcon, { [styles.danger]: props.mode === "danger" })} />
              </div>
              <div>{props.header}</div>
            </>
          ) : (
            props.header
          )}
        </section>
      }>
      {props.children && <section className={styles.contentSection}>{props.children}</section>}
      <div className={classNames(styles.buttonsContainer, props.footerClassName)}>{props.footerActions}</div>
    </Modal>
  );
});
