import { TTransaction } from "shared/definitions";
import { API_URL, axiosInstancePrivate } from "shared/definitions/api";

export type TWithdrawFiatRequestParams = {
  amount: string;
  ticker: string;
  createdAt: string;
  bankDetails: {
    account: string,
    bankAddress: string,
    bankName: string,
    bicSwift: string
  },
  fiatFlowType: 0 | 1, // 0 - transfer, 1 - cash
};

export async function createWithdrawRequest(config: TWithdrawFiatRequestParams) {
  return axiosInstancePrivate.post<TTransaction>(`${API_URL}/api/v1/transactions/withdraw/fiat`, config);
}
