import { Table, Title } from "@mantine/core";
import classNames from "classnames";
import { computed } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "store/root/provider";
import { formatCurrency } from "shared/utils/formatCurrency";
import { RoundCurrencyIcon } from "../../../../components/common/RoundCurrencyIcon";
import styles from "./Wallets.module.scss";
import { WalletActions } from "./WalletActions";
import { CurrencyRowMobile } from "./CurrencyRowMobile";

type TProps = {
  className?: string;
};

export const Wallets = observer((props: TProps) => {
  const { wallets, estimate } = useStores();

  const defaultCurrencyInfo = estimate.defaultCurrency;

  const header = computed(() => (
    <thead>
      <tr className={classNames(styles.tableRow, styles.tableHeadRow)}>
        <th className={styles.tableHeadCell}>Валюта</th>
        <th className={classNames(styles.tableHeadCell, styles.tableHeadCell2)}>Сумма</th>
        <th className={styles.tableHeadCell}>Действия</th>
      </tr>
    </thead>
  )).get();

  const rowsCrypto = computed(() =>
    wallets.cryptoBalances.map((el) => {
      return (
        <tr className={styles.tableRow} key={el.ticker}>
          <td className={styles.cell}>
            <div className={styles.currencyInfoContainer}>
              <RoundCurrencyIcon className={styles.icon} icon={el.ticker} />
              <div className={styles.currencyDescriptionContainer}>
                <div className={styles.currencyTicker}>{el.ticker.toUpperCase()}</div>
                <div className={styles.currencyName}>{el.name}</div>
              </div>
            </div>
          </td>
          <td className={styles.cell}>
            <div className={styles.balanceCell}>
              <div>{formatCurrency(el.balance, { ticker: el.ticker })}</div>
              <div>
                {defaultCurrencyInfo
                  ? "≈ " +
                    formatCurrency(estimate.estimateToDefault(el.ticker, el.balance), {
                      ticker: defaultCurrencyInfo!.ticker,
                      displayTicker: true,
                    })
                  : "–"}
              </div>
            </div>
          </td>
          <td className={styles.cell}>
            <WalletActions ticker={el.ticker} isFiat={false} />
          </td>
        </tr>
      );
    }),
  ).get();

  const rowsFiat = computed(() =>
    wallets.fiatBalances.map((el) => {
      return (
        <tr className={styles.tableRow} key={el.ticker}>
          <td className={styles.cell}>
            <div className={styles.currencyInfoContainer}>
              <RoundCurrencyIcon className={styles.icon} icon={el.ticker} />
              <div className={styles.currencyDescriptionContainer}>
                <div className={styles.currencyTicker}>{el.ticker.toUpperCase()}</div>
                <div className={styles.currencyName}>{el.name}</div>
              </div>
            </div>
          </td>
          <td className={styles.cell}>
            <div className={styles.balanceCell}>
              <div>{formatCurrency(el.balance, { ticker: el.ticker })}</div>
              <div>
                {defaultCurrencyInfo
                  ? "≈ " +
                    formatCurrency(estimate.estimateToDefault(el.ticker, el.balance), {
                      ticker: defaultCurrencyInfo!.ticker,
                      displayTicker: true,
                    })
                  : "–"}
              </div>
            </div>
          </td>
          <td className={styles.cell}>
            <WalletActions ticker={el.ticker} isFiat />
          </td>
        </tr>
      );
    }),
  ).get();

  const rowsCryptoMobile = computed(() =>
    wallets.cryptoBalances.map((el) => {
      return <CurrencyRowMobile key={`mobile-crypto-${el.ticker}`} el={el} isFiat={false} />;
    }),
  ).get();

  const rowsFiatMobile = computed(() =>
    wallets.fiatBalances.map((el) => {
      return <CurrencyRowMobile key={`mobile-fiat-${el.ticker}`} el={el} isFiat />;
    }),
  ).get();

  return (
    <>
      <div className={classNames(props.className, styles.rootDesktop, styles.card)}>
        <div>
          <Title className={styles.title} order={4}>
            Крипто кошельки
          </Title>
          <Table className={styles.table}>
            {header}
            <tbody className={styles.tableBody}>{rowsCrypto}</tbody>
          </Table>
        </div>

        <div>
          <Title className={styles.title} order={4}>
            Валютные счета
          </Title>
          <Table className={styles.table}>
            {header}
            <tbody className={styles.tableBody}>{rowsFiat}</tbody>
          </Table>
        </div>
      </div>
      <div className={classNames(props.className, styles.rootMobile)}>
        <div>
          <Title className={styles.title} order={4}>
            Крипто кошельки
          </Title>
          <div className={styles.mobileRowsContainer}>{rowsCryptoMobile}</div>
        </div>

        <div>
          <Title className={styles.title} order={4}>
            Валютные счета
          </Title>
          <div className={styles.mobileRowsContainer}>{rowsFiatMobile}</div>
        </div>
      </div>
    </>
  );
});
