import { LoadingOverlay, Select } from "@mantine/core";
import { observer } from "mobx-react";
import { useState } from "react";
import { useStores } from "store/root/provider";
import Card from "../components/Card";
import styles from "../../DepositCrypto/DepositCrypto.module.scss";
import { Spacer } from "../../../components/ui/universal/Spacer/Spacer";

export default observer(() => {
  const [loading, setLoading] = useState(false);

  const { user: userStore, currencies: currenciesStore } = useStores();

  const currencies = currenciesStore.fiat.map((el) => ({
    value: `${el.id}`,
    label: el.ticker,
  }));
  return (
    <Card title="Главные настройки">
      <LoadingOverlay visible={loading} />
      <div>
        <Spacer size={6} />
        <Select
          value={`${userStore.userDetails ? userStore.userDetails.defaultCurrencyId : ""}`}
          classNames={{ label: styles.label }}
          label="Фиатная валюта для отображения балансов"
          data={currencies}
          onChange={async (value) => {
            setLoading(true);
            await userStore.updateUserCurrency(Number(value));
            setLoading(false);
          }}
          sx={{ maxWidth: 400 }}
        />
      </div>
    </Card>
  );
});
