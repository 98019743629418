import { Button, Loader, Overlay, Radio } from "@mantine/core";
import { action, reaction } from "mobx";

import { observer } from "mobx-react";
import { useEffect } from "react";
import { ReactComponent as Left } from "shared/static/icons/arrowLeft.svg";
import { ReactComponent as Right } from "shared/static/icons/arrowRight.svg";
import { FinanceTest } from "store/financeTest";

import { useStores } from "store/root/provider";
import { FinishScreen } from "./FinishScreen";
import styles from "./ModalContent.module.scss";
import { QuestionNav } from "./QuestionNav";

type TProps = {
  testStore: FinanceTest;
  onClose: () => void;
};

export const ModalContent = observer(({ testStore: store, onClose }: TProps) => {
  const { user: userStore } = useStores();

  const {
    activeQuestion,
    activeQuestionIndex,
    prevQuestion,
    nextQuestion,
    answers,
    questions,
    hasNextQuestion,
    hasPrevQuestion,
    hasAnswer,
    finish,
    finished,
  } = store;

  useEffect(() => {
    const disposer = reaction(
      () => [store.finished],
      () => {
        void userStore.fetchUserData();
      },
    );

    return () => {
      disposer();
    };
  }, [store, userStore]);

  if (store.isLoading) {
    return (
      <div className={styles.root}>
        {store.isLoading && (
          <>
            <Overlay />
            <Loader />
          </>
        )}
      </div>
    );
  }

  if (store.finished) {
    return (
      <div className={styles.finishScreenRoot}>
        <FinishScreen store={store} onClose={onClose} />
      </div>
    );
  }

  return (
    <div className={styles.root}>
      {!store.isLoading && !!activeQuestion && activeQuestionIndex !== null && (
        <div className={styles.content}>
          <QuestionNav store={store} />
          <div className={styles.questionOf}>
            Вопрос {activeQuestionIndex + 1} из {questions.length}
          </div>
          {activeQuestion.text}
          <Radio.Group
            onChange={action((value) => {
              if (finished) {
                return;
              }
              answers[activeQuestionIndex] = Number(value);
            })}
            value={`${answers[activeQuestionIndex]}`}
            orientation="vertical"
            name="Answers">
            {activeQuestion.answers.map((el, key) => {
              return <Radio key={key} className={styles.radio} value={`${key}`} label={el} />;
            })}
          </Radio.Group>
          <div className={styles.arrowsContainer}>
            <Button disabled={!hasPrevQuestion} onClick={prevQuestion}>
              <Left />
            </Button>
            {hasNextQuestion ? (
              <Button disabled={!hasAnswer} onClick={nextQuestion}>
                <Right />
              </Button>
            ) : (
              <Button disabled={finished || !hasAnswer} onClick={finish}>
                Завершить
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
});
