import { autorun, makeAutoObservable, reaction, runInAction } from "mobx";
import { Currencies } from "store/currencies/currencies";
import { Estimate, SystemLimitsCheckResult } from "store/estimate";
import { Wallets } from "store/wallets/wallets";
import { estimateFee } from "shared/utils/api/estimateFee";
import { sentryCaptureError } from "shared/utils/sentry";
import { TFlowType } from "shared/definitions";
import { checkIfOperationAllowed } from "shared/utils/currencies/tickerOperations";
import { TFee } from "./types";

export class CryptoDeposit {
  private _currency: string | null = null;
  private _chain: string | null = null;
  private _amount: number | null = null;
  private _isOwnAccount: boolean = false;
  private _pofWarning: boolean = false;
  private _showPopup: boolean = false;
  private estimatedFee: TFee | null = null;

  private walletStore: Wallets;
  private currenciesStore: Currencies;
  private estimateStore: Estimate;

  public get showPopup(): boolean {
    return this._showPopup;
  }
  public set showPopup(value: boolean) {
    this._showPopup = value;
  }
  public get isOwnAccount(): boolean {
    return this._isOwnAccount;
  }
  public set isOwnAccount(value: boolean) {
    this._isOwnAccount = value;
  }
  public get amount(): number | null {
    return this._amount;
  }
  public set amount(value: number | null) {
    this._amount = value;
  }
  public get chain(): string | null {
    return this._chain;
  }
  public set chain(value: string | null) {
    this._chain = value;
  }
  public get currency(): string | null {
    return this._currency;
  }
  public set currency(value: string | null) {
    this._currency = value;
  }
  public get pofWarning(): boolean {
    return this._pofWarning;
  }
  public set pofWarning(value: boolean) {
    this._pofWarning = value;
  }

  public get systemLimitsStatus(): SystemLimitsCheckResult {
    if (this.currency && this.amount) {
      return this.estimateStore.checkSystemLimits(this.currency, this.amount, "deposit");
    }
    return SystemLimitsCheckResult.OK;
  }

  public get error() {
    return {
      limits: this.systemLimitsStatus,
    };
  }

  public get address(): string | null {
    if (this.currency) {
      const wallet = this.walletStore.cryptoWallets.find((el) => el.currency.ticker === this.currency);
      if (wallet) {
        return wallet.address;
      }
      sentryCaptureError(`No wallet found for ticker ${this.currency}`);
      return null;
    }
    return null;
  }

  constructor(wallets: Wallets, currencies: Currencies, estimate: Estimate) {
    makeAutoObservable(this, {}, { autoBind: true });
    autorun(async () => {
      if (this.currency && this.amount !== null) {
        const fee = await estimateFee({
          amount: `${this.amount}`,
          tickerTo: this.currency,
        });
        runInAction(() => {
          if (fee) {
            this.estimatedFee = {
              amount: fee.fee,
              ticker: fee.feeTicker,
            };
          } else {
            this.estimatedFee = null;
          }
        });
      }
    });
    reaction(
      () => [this.currency, this.amount],
      () => {
        if (this.currency && this.amount) {
          this.pofWarning = this.estimateStore.checkExceedEstimate(this.currency, this.amount);
        } else {
          this.pofWarning = false;
        }
      },
    );
    this.walletStore = wallets;
    this.currenciesStore = currencies;
    this.estimateStore = estimate;
  }

  get defaultCurrency() {
    return this.estimateStore.defaultCurrency;
  }

  public setMax() {
    if (this.selectedWallet) {
      this.amount = this.selectedWallet.amount;
    }
  }

  public setMin() {
    if (this._currency) {
      const { min } = this.estimateStore.getLimitsWithType(this._currency, "withdraw");
      this.amount = min;
    }
  }

  get selectedWallet() {
    if (this.chain && this.currency) {
      return this.walletStore.cryptoWalletByCoin(this.currency, this.chain);
    }
    return null;
  }

  get chains() {
    return this.currency ? this.currenciesStore.getChainsByTicker(this.currency) : [];
  }

  get amountDefaultCurrency() {
    if (this.defaultCurrency && this._currency && this.amount !== null) {
      return this.estimateStore.estimateToDefault(this._currency, this.amount);
    }
    return 0;
  }

  get estimatedAmount() {
    if (this.estimatedFee?.amount && this.amount !== null) {
      const estimatedAmount = this.amount;
      if (estimatedAmount < 0) {
        return 0;
      }
      return estimatedAmount;
    }
    return 0;
  }

  get valid() {
    return (
      this._isOwnAccount &&
      this.amount !== null &&
      this.amount > 0 &&
      this.address?.length &&
      this.systemLimitsStatus === SystemLimitsCheckResult.OK
    );
  }

  get estimatedAmountDefaultCurrency() {
    if (this.defaultCurrency && this._currency) {
      return this.estimateStore.estimateToDefault(this._currency, this.estimatedAmount);
    }
    return 0;
  }

  get fee() {
    return this.estimatedFee;
  }

  public async checkIfOperationAvailable() {
    if (this.currency && this.chain) {
      return checkIfOperationAllowed({
        txType: "deposit",
        tickerTo: this.currency,
        network: this.chain,
        flowType: TFlowType.crypto,
      });
    }
    return false;
  }
}
